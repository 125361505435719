import { ISharedEntityFields } from "./shared/SharedEntityFields";

export enum ScriptEventTypeEnum {
  BEFORE_BILLING_ATTEMPT = "BEFORE_BILLING_ATTEMPT",
  AFTER_ORDER_CREATED = "AFTER_ORDER_CREATED",
}
export type ScriptEventType = `${ScriptEventTypeEnum}` | ScriptEventTypeEnum;

export interface ScriptCondition {
  eventType: ScriptEventType;

  // If populated, indicates that script should be executed when event is related to specific order number
  orderNumber?: number;
  rotatingSequential?: boolean;
}

export enum ScriptActionTypeEnum {
  REPLACE_PRODUCT_VARIANT = "REPLACE_PRODUCT_VARIANT",
  REMOVE_PRODUCT_VARIANT = "REMOVE_PRODUCT_VARIANT",
  TERMINATE_SUBSCRIPTION = "TERMINATE_SUBSCRIPTION",
}

/**
 * Action that allows to replace one product variant with a different one.
 * @property originalProductVariantId - smartrr ID of variant that should be replaced.
 * @property newProductVariantId - smartrr ID of variant that should be used as replacement.
 * @property sellingPlanGroupId - Shopify ID of group id. Script will be applied to all selling plan within this group
 * @property newSellingPlanShopifyId - selling plan to use for the new variant.
 */
export interface ReplaceProductVariantAction {
  actionType: ScriptActionTypeEnum.REPLACE_PRODUCT_VARIANT;
  originalProductVariantId: string;
  newProductVariantId: string;
  sequentialProducts?: string[];
  newSellingPlanShopifyId?: string;
  sellingPlanGroupId?: string;
  sequentialEndBehavior: string;
}

/**
 * Action that allows to remove product variant from an order.
 * @property productVariantId - smartrr ID of variant that should be removed.
 */
export interface RemoveProductVariantAction {
  actionType: ScriptActionTypeEnum.REMOVE_PRODUCT_VARIANT;
  productVariantId: string;
}

/**
 * Action that allows to terminate subscription.
 */
export interface TerminateSubscriptionAction {
  actionType: ScriptActionTypeEnum.TERMINATE_SUBSCRIPTION;
}

export type ScriptAction = ReplaceProductVariantAction | RemoveProductVariantAction | TerminateSubscriptionAction;

/**
 * Script is executed on certain trigger/event and performs certain action.
 * @property priority - controls execution order (scripts with smaller priority are executed first).
 * @property condition - determines whether script should be triggered. If condition is met it merely means that
 *           the script will be triggered. It does not necessarily mean that script will do any data operations,
 *           because script might have its own internal logic to decide whether data operations are required.
 * @property action - contains parameters for action that should be executed.
 */
export interface IScript extends ISharedEntityFields {
  priority: number;
  condition: ScriptCondition;
  action: ScriptAction;
}

export interface IScriptCreate {
  priority: number;
  condition: ScriptCondition;
  action: ScriptAction;
}
