import styled from "styled-components";

export const SequentialFlowContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 6px;
  }

  & > .Polaris-LegacyStack {
    margin-bottom: 20px;
  }
`;
