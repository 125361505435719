import { create } from "zustand";
import { CombinedSequential } from "@smartrr/shared/interfaces/sequential/misconfigured";

interface DeleteStage_Uninitialized {
  stage: "Uninitialized";
}

interface DeleteStage_NoActiveCPS {
  stage: "NoActiveCPS";
  sequential: CombinedSequential;
}

interface DeleteStage_ActiveCPS {
  stage: "ActiveCPS";
  sequential: CombinedSequential;
}

export type FlowDecision = "cancel" | "send_perpetual";

interface DeleteStage_ActiveCPS_FlowDecision {
  stage: "ActiveCPS_FlowDecision";
  sequential: CombinedSequential;
  choice: FlowDecision;
}

type Stage =
  | DeleteStage_Uninitialized
  | DeleteStage_NoActiveCPS
  | DeleteStage_ActiveCPS
  | DeleteStage_ActiveCPS_FlowDecision;

interface SequentialDeleteFlowStore {
  currentStage: Stage;

  actions: {
    initiateFlow(sequential: CombinedSequential): boolean;
    chooseCancelType(choice: FlowDecision): void;
    goBack(): void;
    canCancel(): boolean;
    cancelType(): FlowDecision;
  };
}

const useSequentialDeleteFlowStore = create<SequentialDeleteFlowStore>()((set, get) => ({
  currentStage: {
    stage: "Uninitialized",
  },
  actions: {
    initiateFlow(sequential: CombinedSequential): boolean {
      if (sequential.vendorInfo === null || sequential.vendorInfo === undefined) {
        set({
          currentStage: {
            stage: "NoActiveCPS",
            sequential,
          },
        });
        return true;
      }

      if (sequential.vendorInfo.cpsCount === 0) {
        set({
          currentStage: {
            stage: "NoActiveCPS",
            sequential,
          },
        });
        return true;
      }

      set({
        currentStage: {
          stage: "ActiveCPS",
          sequential,
        },
      });
      return true;
    },
    chooseCancelType(choice: FlowDecision): void {
      const currentStage = get().currentStage;

      if (currentStage.stage === "ActiveCPS") {
        set({
          currentStage: {
            stage: "ActiveCPS_FlowDecision",
            sequential: currentStage.sequential,
            choice,
          },
        });
      }
    },
    goBack(): void {
      const currentStage = get().currentStage;

      if (currentStage.stage === "Uninitialized") {
        return;
      }

      if (currentStage.stage === "ActiveCPS_FlowDecision") {
        get().actions.initiateFlow(currentStage.sequential);
        return;
      }

      set({
        currentStage: {
          stage: "Uninitialized",
        },
      });
    },
    canCancel(): boolean {
      const currentStage = get().currentStage;

      if (currentStage.stage === "Uninitialized" || currentStage.stage === "ActiveCPS") {
        return false;
      }

      return true;
    },
    cancelType(): FlowDecision {
      const currentStage = get().currentStage;

      if (currentStage.stage === "Uninitialized" || currentStage.stage === "ActiveCPS") {
        return "cancel";
      }

      if (currentStage.stage === "NoActiveCPS") {
        return "cancel";
      }

      return currentStage.choice;
    },
  },
}));

const initialState = useSequentialDeleteFlowStore.getState();

export const DeleteFlowAccess = {
  useStage() {
    return useSequentialDeleteFlowStore(state => state.currentStage);
  },
  useActions() {
    return useSequentialDeleteFlowStore(state => state.actions);
  },

  testing: {
    state: useSequentialDeleteFlowStore.getState,
    actions: useSequentialDeleteFlowStore.getState().actions,
    reset: () => useSequentialDeleteFlowStore.setState(initialState),
  },
};
