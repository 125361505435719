import { useApolloClient } from "@apollo/client";
import { EmptyState } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { ReplaceProductVariantAction, ScriptActionTypeEnum } from "@smartrr/shared/entities/Script";
import { SellingPlanGroupInput, SellingPlanInput } from "@smartrr/shared/shopifyGraphQL/api";
import { queryShopifySellingPlanGroup } from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { AdminSellingPlanGroupRouteWithData } from "./AdminSellingPlanGroupRouteWithData";
import { Spinner } from "../../components/elements/Spinner";
import { emptySellingPlan, emptySellingPlanGroup } from "../constants";
import { ProductOfferingSettings } from "../models";
import { formatSellingPlanGroupQueryResponse } from "../utils";

const defaultOffering: ProductOfferingSettings = {
  isActive: false,
  exists: false,
  newProductVariantId: "",
  originalProductVariantId: "",
  orderNumber: 1,
  sequentialEndBehavior: "",
  sequentialProducts: [],
};

export function AdminSellingPlanGroupRoute({ planId }: { planId: string }): JSX.Element {
  const { addToast } = useToast();
  const apolloClient = useApolloClient();
  const isCreatingNew = planId === "create";

  const sellingPlanGroups = SellingPlanGroupStoreAccess.useGroups();
  const areGroupsLoading = SellingPlanGroupStoreAccess.useAreGroupsLoading();
  const groupActions = SellingPlanGroupStoreAccess.useActions();

  const [sellingPlanGroup, setSellingPlanGroup] = useState<SellingPlanGroupInput | undefined>(
    isCreatingNew ? emptySellingPlanGroup : undefined
  );
  const [productIds, setProductIds] = useState<string[]>([]);
  const [variantIds, setVariantIds] = useState<string[]>([]);
  const [sellingPlans, setSellingPlans] = useState<SellingPlanInput[]>(isCreatingNew ? [emptySellingPlan] : []);
  const [savedProductOfferingSettings, setProductOfferingSettings] =
    useState<ProductOfferingSettings>(defaultOffering);

  const { purchasables, isLoading: isPurchasablesLoading } = useSmartrrVendorSelector(
    state => state.purchasables
  );

  const [isLoading, setIsLoading] = useState(isCreatingNew ? false : areGroupsLoading);
  const productOfferingSettingsRef = useRef<ProductOfferingSettings>({ ...defaultOffering });

  const [hasOldSequential, setHasOldSequential] = useState(false);

  const fetchSellingPlanGroup = async (id: string) => {
    setIsLoading(true);
    if (!sellingPlanGroups) {
      groupActions.fetchSellingPlanGroups(false);
    }
    const group = sellingPlanGroups.find(plan => plan.shopifyId === id);
    if (group) {
      setVariantIds(group.variantIds.map(id => ensureShopifyGid("ProductVariant", id)));
      setProductIds(group.productIds.map(id => ensureShopifyGid("Product", id)));
    }

    const res = await queryShopifySellingPlanGroup(apolloClient, id);

    const { sellingPlanGroup } = res;
    if (!sellingPlanGroup) {
      addToast("There was an error fetching the subscription program");
      setIsLoading(false);
      return;
    }
    const scriptsResponse = await typedFrontendVendorApi.getReq("/smartrr-script");

    if (scriptsResponse.type === "success") {
      const existingScript = scriptsResponse.body.find(
        script =>
          script.action &&
          script.action.actionType === ScriptActionTypeEnum.REPLACE_PRODUCT_VARIANT &&
          script.action.sellingPlanGroupId === id
      );

      if (existingScript) {
        setHasOldSequential(true);
        const productOfferingSettings = {
          id: existingScript.id,
          exists: true,
          isActive: true,
          newProductVariantId: (existingScript.action as ReplaceProductVariantAction).newProductVariantId,
          originalProductVariantId: (existingScript.action as ReplaceProductVariantAction)
            .originalProductVariantId,
          orderNumber: existingScript.condition!.orderNumber!,
          sequentialEndBehavior: (existingScript.action as ReplaceProductVariantAction).sequentialEndBehavior,
          sequentialProducts: (existingScript.action as ReplaceProductVariantAction).sequentialProducts,
        };
        //assigning cloneDeep to variable breaks the clone and updates
        // productOfferingSettings every time productOfferingSettingsRef is updated
        setProductOfferingSettings(cloneDeep(productOfferingSettings));
        productOfferingSettingsRef.current = cloneDeep(productOfferingSettings);
      } else {
        setProductOfferingSettings(defaultOffering);
        productOfferingSettingsRef.current = { ...defaultOffering };
      }
    }

    const { sellingPlanGroupInput, sellingPlanInputs } = formatSellingPlanGroupQueryResponse(sellingPlanGroup);

    setSellingPlanGroup(sellingPlanGroupInput);
    setSellingPlans(sellingPlanInputs);

    setIsLoading(false);
  };

  useEffect(() => {
    if (planId && !isCreatingNew) {
      fetchSellingPlanGroup(ensureShopifyGid("SellingPlanGroup", planId));
    }
  }, [isCreatingNew]);

  const onSave = useCallback(
    (newGroup: SellingPlanGroupInput, newPlans: SellingPlanInput[]) => {
      setSellingPlanGroup(newGroup);
      setSellingPlans(newPlans.map(p => cloneDeep(p)));
      groupActions.fetchSellingPlanGroups(true);
    },
    [setSellingPlanGroup, setSellingPlans]
  );

  const onDiscard = useCallback(() => {
    if (isCreatingNew) {
      navigateWithShopInQuery(`${adminRoutePrefix}/configure/plans`);
    }
  }, [isCreatingNew]);

  if (isLoading || isPurchasablesLoading) {
    return <Spinner />;
  }

  if (!isLoading && !sellingPlanGroup && !isCreatingNew && !isPurchasablesLoading) {
    return (
      <EmptyState
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
        action={{
          content: "Manage plans",
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/configure/plans`),
        }}
      >
        <p>Subscription program not found</p>
      </EmptyState>
    );
  }

  return (
    <AdminSellingPlanGroupRouteWithData
      purchasables={purchasables}
      sellingPlanGroup={sellingPlanGroup!}
      sellingPlans={sellingPlans}
      hasOldSequential={hasOldSequential}
      productOfferingSettings={savedProductOfferingSettings}
      productOfferingSettingsRef={productOfferingSettingsRef}
      setProductOfferingSettings={setProductOfferingSettings}
      onSave={onSave}
      onDiscard={onDiscard}
      variantIds={variantIds}
      productIds={productIds}
      sellingPlanGroups={sellingPlanGroups}
    />
  );
}
