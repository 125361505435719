import { LegacyStack } from "@shopify/polaris";

import { SequenceContext } from "./SequenceContext";
import { OrderActionComponent } from "./SequentialOrderAction";
import { UpdateHoverActionContainer } from "./UpdateHoverContainer";
import { UpdateSequencePreamble } from "./UpdateSequencePreamble";
import { SequentialForm } from "../../SequentialStore/form";

export const UpdateSequence = ({ sequence }: { sequence: SequentialForm.Sequential.SequenceType }) => {
  return (
    <SequenceContext.Provider value={sequence}>
      <UpdateHoverActionContainer>
        <LegacyStack vertical>
          <UpdateSequencePreamble />
          <OrderActionComponent />
        </LegacyStack>
      </UpdateHoverActionContainer>
    </SequenceContext.Provider>
  );
};
