import { Icon } from "@shopify/polaris";
import { AddMajor, DeleteMajor, DuplicateMinor } from "@shopify/polaris-icons";
import { prefixClassName } from "@smartrr/shared/components/primitives";
import React, { useContext } from "react";
import styled from "styled-components";

import { EditSequentialAccess } from "../../../SequentialStore";
import { SequenceContext } from "../SequenceContext";

const UpdateHoverActionsContainer = styled.div`
  height: 52px;
  left: -3px;
  padding-bottom: 6px;
  padding-right: 6px;
  padding-top: 6px;
  position: absolute;
  top: calc(100% - 3px);
  z-index: 100;

  & div.smartrr-portal-sequence-update-actions {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #0000001a;
    display: flex;
    height: 40px;
  }

  & span.Polaris-Icon {
    border: 1px solid #babec3;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
    padding: 10px;
    width: 40px;
  }
`;

export const UpdateHoverActions = () => {
  const sequential = EditSequentialAccess.useSequence();
  const sequence = useContext(SequenceContext);
  const actions = EditSequentialAccess.useActions();

  const addAction = () => {
    actions.addNewSequentialActionAfter(sequence);
  };

  const duplicateAction = () => {
    actions.duplicateNewSequentialActionAfter(sequence);
  };

  const deleteAction = () => {
    actions.deleteSequentialAction(sequence);
  };

  return (
    <UpdateHoverActionsContainer className={prefixClassName("sequence-update-action-container")}>
      <div className={prefixClassName("sequence-update-actions")}>
        <div onClick={addAction}>
          <Icon source={AddMajor} accessibilityLabel="Add Sequence" />
        </div>
        <div onClick={duplicateAction}>
          <Icon source={DuplicateMinor} accessibilityLabel="Duplicate this sequence" />
        </div>
        {sequential.length > 1 ? (
          <div onClick={deleteAction}>
            <Icon source={DeleteMajor} accessibilityLabel="Delete this sequence" />
          </div>
        ) : null}
      </div>
    </UpdateHoverActionsContainer>
  );
};
