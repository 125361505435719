import { Button, LegacyStack, Text } from "@shopify/polaris";
import React from "react";

import { useSequentialBrowseModalStore } from "@vendor-app/app/_sharedComponents/Sequential/BrowseModal/useSequentialBrowseModalStore";

import { DisplayVariant } from "../../../../DisplayVariant";
import { EditSequentialAccess } from "../../../../SequentialStore";
import { SequentialForm } from "@vendor-app/app/_sharedComponents/Sequential/SequentialStore/form";
import { useSubscriptionToEvent } from "@vendor-app/utils/customEvent";

export const SwapActionComponent = ({ sequence }: { sequence: SequentialForm.SwapAction.Type }) => {
  const openBrowseModal = useSequentialBrowseModalStore(state => state.openModal);
  const groupId = EditSequentialAccess.useGroup();
  const sequentialId = EditSequentialAccess.useSequentialId();

  const actions = EditSequentialAccess.useActions();

  useSubscriptionToEvent(
    "smartrr.selling_plan_group.sequential.select_variant",
    ({ vnt, id: sequentialIndex }) => {
      if (sequentialIndex === sequence.id) {
        actions.setSequentialSwap(sequence.id, sequence.orderNumber, vnt);
      }
    },
    [sequence]
  );

  const BrowseButton = () => (
    <Button
      onClick={() =>
        openBrowseModal({
          groupId,
          selectedVariant: sequence.swapVariant,
          sequenceId: sequence.id,
          sequentialId,
        })
      }
      size="slim"
    >
      Browse
    </Button>
  );

  return (
    <div
      style={{
        paddingLeft: "20px",
      }}
    >
      <LegacyStack vertical spacing="extraTight">
        <LegacyStack distribution="equalSpacing">
          <Text as="p" variant="bodyMd" fontWeight="bold">
            To
          </Text>
        </LegacyStack>
        <DisplayVariant selectedVariant={sequence.swapVariant}>
          <BrowseButton />
        </DisplayVariant>
      </LegacyStack>
    </div>
  );
};
