import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSequentialBrowseModalStore } from "../../../BrowseModal/useSequentialBrowseModalStore";
import { UpdateHoverActions } from "../UpdateHoverActions";

const UpdateHoverContainer = styled.div<{ isFocused: boolean }>`
  border: 2px solid;
  border-color: ${props => (props.isFocused ? "#458FFF" : "#FFFFFF")};
  border-radius: 4px;
  padding: 3px;

  position: relative;
`;

interface IUpdateHoverActionContainerProps {
  children: React.ReactNode;
}

export const UpdateHoverActionContainer = ({ children }: IUpdateHoverActionContainerProps) => {
  const [hover, setHover] = useState(false);
  const isBrowseModalOpen = useSequentialBrowseModalStore(state => state.isModalOpen);

  useEffect(() => {
    setHover(false);
  }, [isBrowseModalOpen]);

  return (
    <UpdateHoverContainer
      isFocused={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {hover ? <UpdateHoverActions /> : null}
    </UpdateHoverContainer>
  );
};
