import { ChoiceList, LegacyStack, Text } from "@shopify/polaris";
import React, { useContext, useState } from "react";

import { SequentialSection } from "../../../utils/SequentialSection";
import { SequentialDeleteActionButtons } from "../DeleteActionButtons";
import { SequentialDeleteFlowBanner } from "../DeleteFlowBanner";
import { DeleteFlowStageContext } from "../DeleteStages/DeleteFlowStageContext";
import { DeleteFlowAccess, FlowDecision } from "../FlowDeleteStore";
import { useSequentialDeleteFlowModal } from "../useSequentialDeleteFlowModal";

export const SequentialDeleteFlowStage_NoActiveCPS = () => {
  const closeModal = useSequentialDeleteFlowModal(state => state.closeModal);
  const { submitting, deleteFlow } = useContext(DeleteFlowStageContext);

  return (
    <React.Fragment>
      <SequentialSection>
        <Text variant="bodyMd" as="p">
          Are you sure you want to delete this entire flow? This action can&apos;t be undone.
        </Text>
      </SequentialSection>
      <SequentialSection border>
        <SequentialDeleteActionButtons
          secondary={{
            text: "Back",
            onAction() {
              closeModal();
            },
          }}
          primary={{
            text: "Delete",
            onAction() {
              deleteFlow({ cancelSubscriptions: false });
            },
            disabled: false,
            loading: submitting,
            destructive: true,
          }}
        />
      </SequentialSection>
    </React.Fragment>
  );
};

interface ActiveCPSChoice {
  label: string;
  value: FlowDecision;
}

const activeCPSChoice: ActiveCPSChoice[] = [
  { label: "Cancel all active subscriptions with this sequence", value: "cancel" },
  {
    label: "Send the current item in the customers' active subscriptions in perpetuity",
    value: "send_perpetual",
  },
];

export const SequentialDeleteFlowStage_ActiveCPS = () => {
  const closeModal = useSequentialDeleteFlowModal(state => state.closeModal);
  const deleteActions = DeleteFlowAccess.useActions();

  const [cancelChoice, setCancelChoice] = useState<FlowDecision[]>(["cancel"]);
  const [userChosen, setUserChosen] = useState(false);

  return (
    <React.Fragment>
      <SequentialSection>
        <LegacyStack vertical>
          <SequentialDeleteFlowBanner />
          <Text as="p" variant="bodyMd">
            Before you can delete this flow, you&apos;ll need to let us know what we should do with these
            subscriptions:
          </Text>
          <ChoiceList
            title="Choose delete type"
            titleHidden
            selected={userChosen ? cancelChoice : []}
            onChange={(selected: FlowDecision[]) => {
              setUserChosen(true);
              setCancelChoice(selected);
            }}
            choices={activeCPSChoice}
          ></ChoiceList>
        </LegacyStack>
      </SequentialSection>
      <SequentialSection border>
        <SequentialDeleteActionButtons
          secondary={{
            text: "Back",
            onAction() {
              closeModal();
            },
          }}
          primary={{
            text: "Continue",
            onAction() {
              deleteActions.chooseCancelType(cancelChoice[0]);
            },
            disabled: !userChosen,
            loading: false,
            destructive: false,
          }}
        />
      </SequentialSection>
    </React.Fragment>
  );
};

export const SequentialDeleteFlowStage_ActiveCPS_FlowDecision = () => {
  const stage = DeleteFlowAccess.useStage();
  const deleteActions = DeleteFlowAccess.useActions();
  const cancelType = deleteActions.cancelType();
  const { submitting, deleteFlow } = useContext(DeleteFlowStageContext);

  const cpsCount = stage.stage === "ActiveCPS_FlowDecision" ? stage.sequential.vendorInfo?.cpsCount : 0;

  return (
    <React.Fragment>
      <SequentialSection>
        <LegacyStack vertical>
          <Text as="p" variant="bodyMd">
            Are you sure you want to delete this entire flow? This action can&apos;t be undone.
          </Text>
          {cancelType === "cancel" ? (
            <Text as="p" variant="bodyMd">
              <Text as="span" variant="bodyMd">
                We will cancel all
              </Text>
              <Text as="span" variant="bodyMd" fontWeight="bold">
                &nbsp;({cpsCount ?? 0})&nbsp;
              </Text>
              <Text as="span" variant="bodyMd">
                active subscriptions with this sequence.
              </Text>
            </Text>
          ) : undefined}
          {cancelType === "send_perpetual" ? (
            <Text as="p" variant="bodyMd">
              <Text as="span" variant="bodyMd">
                We will send the last item in the customers&apos; active subscriptions in perpetuity for
              </Text>
              <Text as="span" variant="bodyMd" fontWeight="bold">
                &nbsp;({cpsCount ?? 0})&nbsp;
              </Text>
              <Text as="span" variant="bodyMd">
                subscriptions.
              </Text>
            </Text>
          ) : undefined}
        </LegacyStack>
      </SequentialSection>
      <SequentialSection border>
        <SequentialDeleteActionButtons
          secondary={{
            text: "Back",
            onAction() {
              deleteActions.goBack();
            },
          }}
          primary={{
            text: "Delete",
            onAction() {
              deleteFlow({ cancelSubscriptions: cancelType === "cancel" });
            },
            disabled: false,
            loading: submitting,
            destructive: true,
          }}
        />
      </SequentialSection>
    </React.Fragment>
  );
};
