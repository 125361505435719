import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSellingPlanGroupRoute } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRoute/AdminSellingPlanGroupRoute";
import { AdminSellingPlanGroupRoute as AdminSellingPlanGroupRouteFeatureFlagged } from "@vendor-app/app/AdminRoute/AdminSellingPlanGroupsRouteFeatureFlagged/AdminSellingPlanGroupRoute";
import { AuthorizedRoute } from "@vendor-app/app/AdminRoute/components/authorization/AuthorizedRoute";

export const Route = createLazyFileRoute("/admin/configure/plans/$planId")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const { subscriptionProgramsImprovement } = useSmartrrFlags();
  const { planId } = Route.useParams();

  return AuthorizedRoute(
    FeatureEnum.CORE_CONFIGURATION,
    subscriptionProgramsImprovement ? (
      <AdminSellingPlanGroupRouteFeatureFlagged planId={planId} />
    ) : (
      <AdminSellingPlanGroupRoute planId={planId} />
    )
  );
}
