import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import { captureException } from "@smartrr/shared/utils/captureException";
import { create } from "zustand";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

interface BundleConfigStore {
  config: ISmartrrBundleConfig[];
  isLoading: boolean;

  actions: {
    fetchBundleConfig: () => Promise<void>;
  };

  internal: {
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type>;
  };
}

const useBundleConfigStore = create<BundleConfigStore>()((set, get) => ({
  config: [],
  isLoading: false,

  actions: {
    async fetchBundleConfig() {
      return get().internal.whileLoading(async () => {
        try {
          const data = await typedFrontendVendorApi.getReq("/bundle-config");
          if (data.type === "success") {
            set(() => ({
              config: data.body,
            }));
          }
        } catch (error) {
          captureException("Failed to fetch bundle config", error);
        }
      });
    },
  },

  internal: {
    async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type> {
      set({
        isLoading: true,
      });

      const result = await fn();

      set({
        isLoading: false,
      });

      return result;
    },
  },
}));

export const BundleConfigAccess = {
  useActions: () => useBundleConfigStore(state => state.actions),
  useLoading: () => useBundleConfigStore(state => state.isLoading),
  useConfig: () => useBundleConfigStore(state => state.config),

  testing: {
    initialState: useBundleConfigStore.getState(),
    reset() {
      useBundleConfigStore.setState(this.initialState);
    },
    actions: useBundleConfigStore.getState().actions,
    state: useBundleConfigStore.getState,
  },
};
