import { LegacyStack, Text, Thumbnail } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

import { getDisplayVariantHelper } from "./helper";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

interface IEmptyProductDisplayProps {
  children?: React.ReactNode;
}

const EmptyProductDisplay = ({ children }: IEmptyProductDisplayProps) => {
  return (
    <DisplayVariantContainer>
      <LegacyStack alignment="center" distribution="equalSpacing">
        <LegacyStack alignment="center">
          <Thumbnail source={() => <svg />} alt={"Select item"} size="small" />
          <Text as="p" variant="bodyMd" color="subdued">
            Select item
          </Text>
        </LegacyStack>
        {children}
      </LegacyStack>
    </DisplayVariantContainer>
  );
};

const DisplayVariantContainer = styled.div`
  background: #fafbfb;
  border: 1px solid #d2d5d8;
  border-radius: 4px;
  padding: 4px 6px;

  & .Polaris-Thumbnail--sizeSmall {
    height: 32px;
    width: 32px;
  }

  & img {
    border-radius: 6px;
    height: 30px;
    width: 30px;
  }

  & > .Polaris-LegacyStack {
    flex-wrap: nowrap;
    max-width: 100%;
    width: 100%;
  }

  & .Polaris-LegacyStack__Item:not(:first-of-type) {
    margin-left: 10px;
  }
`;

interface IDisplayVariantProps {
  selectedVariant: SequentialApi.SequentialVariantWithParent.Type | null;
  children?: React.ReactNode;
}

export const DisplayVariant = ({ selectedVariant, children }: IDisplayVariantProps) => {
  if (selectedVariant === null) {
    return <EmptyProductDisplay>{children}</EmptyProductDisplay>;
  }

  const helper = getDisplayVariantHelper(selectedVariant);

  return (
    <DisplayVariantContainer>
      <LegacyStack alignment="center" distribution="equalSpacing" spacing="tight">
        <helper.Thumbnail />
        <LegacyStack.Item>
          <div
            style={{
              maxWidth: "200px",
            }}
          >
            <Text as="p" variant="bodyMd" truncate>
              {helper.productName}
            </Text>
          </div>
        </LegacyStack.Item>
        <Text as="p" variant="bodyMd">
          {helper.variantName}
        </Text>
        <Text as="p" variant="bodyMd">
          {helper.variantSKU}
        </Text>
        <LegacyStack.Item fill />
        <LegacyStack.Item>{children}</LegacyStack.Item>
      </LegacyStack>
    </DisplayVariantContainer>
  );
};
