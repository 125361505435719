import { Icon, Text } from "@shopify/polaris";
import React from "react";
import { PackageMajor } from "@shopify/polaris-icons";
import { PurchaseStateAndDelivery } from "@smartrr/shared/entities/PurchaseState";
import { useActiveOrganizationCurrencySelector } from "@vendor-app/app/_state/reducers/shopify";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";
import styled from "styled-components";
import { DiscountValueType } from "@smartrr/shared/interfaces/Discount";
import { SequentialPreviewOrderProduct } from "../SequentialPreviewOrderProduct";
import { useOrderText } from "../utils/useOrderText";

const SequentialPreviewOrderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & .sequential-preview-order-number-container {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px;
    padding-right: 3px;

    width: 93px;
    height: 32px;
    border-radius: 8px;
    background-color: #f1f1f1;

    .Polaris-Icon {
      margin: 0;
    }

    &.empty {
      visibility: hidden;
    }
  }

  & .sequential-preview-order-divider {
    position: relative;
    flex-grow: 1;

    ::after {
      content: "";
      position: absolute;
      width: 100%;
      border-top: 1px solid #e3e3e3;
      translate: translateY(-50%);
    }
  }

  & .sequential-preview-order-price {
    width: 96px;
    text-align: right;
    padding-right: 4px;
  }

  & .sequential-preview-order-products {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .sequential-preview-order-product {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

    > span:nth-of-type(1) {
      width: 150px;
      overflow: hidden;
    }

    > span:nth-of-type(2) {
      width: 96px;
      overflow: hidden;
    }

    span span {
      line-height: 20px;
    }
  }
`;
interface SequentialPreviewOrderProps {
  delivery: PurchaseStateAndDelivery;
}
export const SequentialPreviewOrder = ({ delivery }: SequentialPreviewOrderProps) => {
  const shopCurrency = useActiveOrganizationCurrencySelector() ?? "USD";
  const orderNumber = delivery.indexFromNext + 1;
  const cost = delivery.purchaseState.stLineItems.reduce((costAcc, line) => {
    const variant = line.vnt;
    const variantCost = variant.presentmentPrices.find(p => p.priceCurrency === shopCurrency)?.price ?? 100;

    const discountValue = line.discounts.reduce((costAcc, discount) => {
      if (discount.valueType === DiscountValueType.FIXED) {
        costAcc -= discount.value;
      } else {
        costAcc -= (variantCost * discount.value) / 100;
      }
      return costAcc;
    }, variantCost);

    return costAcc + discountValue;
  }, 0);
  const formattedCost = formatMoney(cost, shopCurrency);
  const orderNumberText = useOrderText(orderNumber);

  return (
    <SequentialPreviewOrderContainer className="sequential-preview-order" data-testid="sequential-preview-order">
      <div className={`sequential-preview-order-number-container ${orderNumberText === "" ? "empty" : ""}`}>
        <Icon source={PackageMajor} />
        <Text as="span" variant="bodySm">
          {orderNumberText}
        </Text>
      </div>
      <div className="sequential-preview-order-divider"></div>
      <div className="sequential-preview-order-products">
        {delivery.purchaseState.stLineItems.map((line, index) => (
          <SequentialPreviewOrderProduct line={line} key={index} />
        ))}
      </div>
      <div className="sequential-preview-order-price">{formattedCost}</div>
    </SequentialPreviewOrderContainer>
  );
};
