import { useMemo } from "react";
import { EditSequentialAccess } from "../../../SequentialStore";

export const useOrderText = (orderNumber: number) => {
  const endAction = EditSequentialAccess.useEndAction();

  return useMemo(() => {
    switch (endAction.actionType) {
      case "REPEAT": {
        if (endAction.orderNumber === orderNumber) {
          return "Order ∞";
        }
        break;
      }
      case "RESTART": {
        if (endAction.orderNumber === orderNumber - 1) {
          return "Loop ∞";
        }
        if (endAction.orderNumber < orderNumber) {
          return "";
        }
      }
    }

    return `Order ${orderNumber}`;
  }, [endAction, orderNumber]);
};
