import { FormLayout, LegacyCard, TextField } from "@shopify/polaris";
import { SHORT_DEBOUNCE_TIME } from "@vendor-app/constants/table";
import {
  ISellingPlanFromZodWithId,
  ISellingPlanGroupInputForZod,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { delay } from "@smartrr/shared/utils/delay";
import { last } from "lodash";
import React, { useEffect, useRef, useState } from "react";

import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";

import { SellingPlan } from "./SellingPlan";
import { createEmptySmartrrSellingPlan } from "../utils";
import { useIsAddingNewPlanDisabled } from "./hooks/useIsAddingNewPlanDisabled";

export interface SellingPlanGroupComponentProps {
  sellingPlanGroup: ISellingPlanGroupInputForZod;
  sellingPlans: ISellingPlanFromZodWithId[];

  onSellingPlanGroupUpdate(key: string, value: any): void;

  onDeleteSellingPlan(index: number): void;

  onUpdateSellingPlan(index: number, properties: { [key: string]: any }): void;

  setSellingPlanInputs: React.Dispatch<React.SetStateAction<ISellingPlanFromZodWithId[]>>;
}

export function SellingPlanGroup({
  sellingPlanGroup,
  sellingPlans,
  onSellingPlanGroupUpdate,
  onDeleteSellingPlan,
  onUpdateSellingPlan,
  setSellingPlanInputs,
}: SellingPlanGroupComponentProps): JSX.Element {
  const [sellingPlanCount, setSellingPlanCount] = useState(sellingPlans.length);

  const sellingPlansRef = useRef<HTMLDivElement[]>([]);
  const validationErrors = SellingPlanGroupStoreAccess.useValidationErrors();

  const hideAddAnotherPlan = useIsAddingNewPlanDisabled();

  useEffect(() => {
    setTimeout(() => {
      if (sellingPlans.length > sellingPlanCount) {
        last(sellingPlansRef.current)?.scrollIntoView({ behavior: "smooth" });
      }
      setSellingPlanCount(sellingPlans.length);
    }, SHORT_DEBOUNCE_TIME);
  }, [sellingPlans]);

  return (
    <React.Fragment>
      <LegacyCard sectioned>
        <FormLayout>
          <TextField
            id="selling-plan-group__storefront-label"
            autoComplete="off"
            label="Storefront Label"
            placeholder="Example: Subscribe & Save 10%"
            helpText="Title of the subscription offering on the product page"
            requiredIndicator={true}
            value={sellingPlanGroup.storefrontLabel || ""}
            error={validationErrors?.storefrontLabel?._errors.join(" ")}
            onChange={storefrontLabel => onSellingPlanGroupUpdate("storefrontLabel", storefrontLabel)}
          />
          <TextField
            id="selling-plan-group__storefront-plan-option-label"
            autoComplete="off"
            label="Storefront Plan Option Label"
            placeholder="Example: Delivery Every"
            helpText="Text to display next to the plan option selector"
            requiredIndicator={true}
            value={sellingPlanGroup.optionLabel || ""}
            error={validationErrors?.optionLabel?._errors.join(" ")}
            onChange={optionLabel => onSellingPlanGroupUpdate("optionLabel", optionLabel)}
          />
          <TextField
            id="selling-plan-group__admin-label"
            autoComplete="off"
            label="Admin Label"
            helpText="For internal use only"
            value={sellingPlanGroup.adminLabel || ""}
            error={validationErrors?.adminLabel?._errors.join(" ")}
            onChange={adminLabel => onSellingPlanGroupUpdate("adminLabel", adminLabel)}
          />
        </FormLayout>
      </LegacyCard>

      {sellingPlans.map((sellingPlan, i) => (
        <LegacyCard
          key={i}
          primaryFooterAction={{
            id: `selling-plan-group__selling-plan_${i + 1}__add-another-plan`,
            content: "Add another plan",
            disabled: hideAddAnotherPlan,
            onAction: () =>
              delay(0).then(() => {
                setSellingPlanInputs(plans => plans.concat(createEmptySmartrrSellingPlan()));
              }),
            plain: true,
          }}
          secondaryFooterActions={
            sellingPlanCount > 1
              ? [
                  {
                    id: `selling-plan-group__selling-plan_${i + 1}__delete-plan`,
                    content: "Delete plan",
                    accessibilityLabel: `Delete the '${sellingPlan.displayLabel}' selling plan`,
                    onAction: () => onDeleteSellingPlan(i),
                    plain: true,
                    destructive: true,
                  },
                ]
              : []
          }
        >
          <div
            data-testid={`selling-plan__card`}
            data-selling-plan-id={`selling-plan__${sellingPlan.id ?? i}`}
            style={{ minHeight: "39.5rem" }}
            ref={el => {
              if (el) {
                sellingPlansRef.current[i] = el;
              }
            }}
          >
            <SellingPlan
              key={`${sellingPlanGroup.id}_${sellingPlan.id || i}`}
              index={i}
              groupId={sellingPlanGroup.id}
              sellingPlan={sellingPlan}
              onUpdateSellingPlan={onUpdateSellingPlan}
              setSellingPlanInputs={setSellingPlanInputs}
            />
          </div>
        </LegacyCard>
      ))}
    </React.Fragment>
  );
}
