import React, { useEffect } from "react";

import { SequentialDeleteFlowShowStage } from "./DeleteStages";
import { DeleteFlowAccess } from "./FlowDeleteStore";
import { useSequentialDeleteFlowModal } from "./useSequentialDeleteFlowModal";
import { SequentialsAccess } from "../../Information/ShowSequentials/SequentialsStore";
import { EditSequentialAccess } from "../../SequentialStore";

export const SequentialDeleteFlow = () => {
  const closeModal = useSequentialDeleteFlowModal(state => state.closeModal);

  const sequenceId = EditSequentialAccess.useSequentialId();

  const deleteActions = DeleteFlowAccess.useActions();

  const sequential = SequentialsAccess.useGetSequentialById(sequenceId ?? "");

  useEffect(() => {
    if (sequential) {
      deleteActions.initiateFlow(sequential);
    } else {
      closeModal();
    }
  }, []);

  return <SequentialDeleteFlowShowStage />;
};
