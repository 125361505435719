import { FormLayout, LegacyCard, TextField } from "@shopify/polaris";
import { SHORT_DEBOUNCE_TIME } from "@vendor-app/constants/table";
import { SellingPlanInput } from "@smartrr/shared/shopifyGraphQL/api";
import { delay } from "@smartrr/shared/utils/delay";
import { cloneDeep, last } from "lodash";
import React, { useEffect, useRef, useState } from "react";

import { SellingPlan } from "./SellingPlan";
import { emptySellingPlan } from "../constants";
import { SellingPlanGroupInputWithId } from "../utils";
import { useIsAddingNewPlanDisabled } from "../../AdminSellingPlanGroupsRouteFeatureFlagged/components/hooks/useIsAddingNewPlanDisabled";

interface Props {
  sellingPlanGroup: SellingPlanGroupInputWithId;
  sellingPlans: SellingPlanInput[];

  onSellingPlanGroupUpdate(key: string, value: any): void;

  onDeleteSellingPlan(index: number): void;

  onUpdateSellingPlan(index: number, properties: { [key: string]: any }): void;

  setSellingPlanInputs: React.Dispatch<React.SetStateAction<SellingPlanInput[]>>;

  setPrepaidPlans: React.Dispatch<React.SetStateAction<string[]>>;

  setTerminalPlans: React.Dispatch<React.SetStateAction<string[]>>;
}

export function SellingPlanGroup({
  sellingPlanGroup,
  sellingPlans,
  onSellingPlanGroupUpdate,
  onDeleteSellingPlan,
  onUpdateSellingPlan,
  setSellingPlanInputs,
  setPrepaidPlans,
  setTerminalPlans,
}: Props): JSX.Element {
  const [sellingPlanCount, setSellingPlanCount] = useState(sellingPlans.length);

  const sellingPlansRef = useRef<HTMLDivElement[]>([]);

  const hideAddAnotherPlan = useIsAddingNewPlanDisabled();

  useEffect(() => {
    setTimeout(() => {
      if (sellingPlans.length > sellingPlanCount) {
        last(sellingPlansRef.current)?.scrollIntoView({ behavior: "smooth" });
      }
      setSellingPlanCount(sellingPlans.length);
    }, SHORT_DEBOUNCE_TIME);
  }, [sellingPlans]);

  return (
    <React.Fragment>
      <LegacyCard sectioned>
        <FormLayout>
          <TextField
            id="selling-plan-group__storefront-label"
            autoComplete="off"
            label="Storefront label"
            placeholder="Example: Subscribe & Save 10%"
            helpText="Title of the subscription offering on the product page"
            value={sellingPlanGroup.name || ""}
            onChange={name => onSellingPlanGroupUpdate("name", name)}
          />
          <TextField
            id="selling-plan-group__storefront-plan-option-label"
            autoComplete="off"
            label="Storefront plan option label"
            placeholder="Example: Delivery Every"
            helpText="Text to display next to the plan option selector"
            value={sellingPlanGroup.options?.[0] || ""}
            onChange={option => onSellingPlanGroupUpdate("options", [option])}
          />
          <TextField
            id="selling-plan-group__admin-label"
            autoComplete="off"
            label="Admin label"
            helpText="For internal use only"
            value={sellingPlanGroup.merchantCode || ""}
            onChange={merchantCode => onSellingPlanGroupUpdate("merchantCode", merchantCode)}
          />
        </FormLayout>
      </LegacyCard>

      {sellingPlans.map((sellingPlan, i) => (
        <LegacyCard
          key={i}
          primaryFooterAction={{
            id: `selling-plan-group__selling-plan_${i + 1}__add-another-plan`,
            content: "Add another plan",
            disabled: hideAddAnotherPlan,
            onAction: () =>
              delay(0).then(() => {
                setSellingPlanInputs(plans => plans.concat(cloneDeep(emptySellingPlan)));
              }),
            plain: true,
          }}
          secondaryFooterActions={
            sellingPlanCount > 1
              ? [
                  {
                    id: `selling-plan-group__selling-plan_${i + 1}__delete-plan`,
                    content: "Delete plan",
                    onAction: () => onDeleteSellingPlan(i),
                    plain: true,
                    destructive: true,
                  },
                ]
              : []
          }
        >
          <div
            style={{ minHeight: "39.5rem" }}
            ref={el => {
              if (el) {
                sellingPlansRef.current[i] = el;
              }
            }}
          >
            <SellingPlan
              key={`${sellingPlanGroup.id}_${sellingPlan.id || i}`}
              index={i}
              groupId={sellingPlanGroup.id}
              sellingPlan={sellingPlan}
              onUpdateSellingPlan={onUpdateSellingPlan}
              setPrepaidPlans={setPrepaidPlans}
              setTerminalPlans={setTerminalPlans}
              setSellingPlanInputs={setSellingPlanInputs}
            />
          </div>
        </LegacyCard>
      ))}
    </React.Fragment>
  );
}
