import { Thumbnail } from "@shopify/polaris";
import { DEFAULT_VARIANT_TITLE } from "@smartrr/shared/constants";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import React from "react";

export const getDisplayVariantHelper = (selectedVariant: SequentialApi.SequentialVariantWithParent.Type) => {
  const VariantThumbnail = () => {
    return (
      <Thumbnail
        size="small"
        source={selectedVariant.images[0]}
        alt={`Image for ${selectedVariant.parentProduct.name}-${selectedVariant.name}`}
      />
    );
  };

  return {
    Thumbnail: VariantThumbnail,
    productName: selectedVariant.parentProduct.name,
    variantName:
      selectedVariant.name === DEFAULT_VARIANT_TITLE
        ? selectedVariant.parentProduct.name
        : selectedVariant.name ?? "",
    variantSKU: selectedVariant.sku ?? "",
  };
};
