import { Text, Tooltip } from "@shopify/polaris";
import React from "react";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { DEFAULT_VARIANT_TITLE } from "@smartrr/shared/constants";
import { truncate } from "lodash";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

interface SequentialPreviewOrderProductProps {
  line: IPurchaseStateLineItem;
}

export const SequentialPreviewOrderProduct = ({ line }: SequentialPreviewOrderProductProps) => {
  const variant: SequentialApi.Sequential.Type["initialVariant"] =
    line.vnt as SequentialApi.Sequential.Type["initialVariant"];

  const productName = variant.parentProduct.name;
  const variantName = variant.name === DEFAULT_VARIANT_TITLE ? "" : variant.name;

  return (
    <div className="sequential-preview-order-product">
      <img src={variant.images[0]} height="20px" width="20px" />
      <Tooltip active={productName.length > 20 ? undefined : false} content={productName}>
        <Text as="span" variant="bodyMd">
          {truncate(productName, { length: 20 })}
        </Text>
      </Tooltip>
      <Tooltip active={variantName.length > 13 ? undefined : false} content={variantName}>
        <Text as="span" variant="bodyMd">
          {truncate(variantName, { length: 13 })}
        </Text>
      </Tooltip>
    </div>
  );
};
