import { Button, Card, HorizontalStack, LegacyStack, Modal, Text, VerticalStack } from "@shopify/polaris";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React, { useContext } from "react";
import styled from "styled-components";

import { useIsFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess";

import { ShowSequentials } from "./ShowSequentials";
import { UpgradeCard } from "../../../AdminRoute/AdminSellingPlanGroupsRoute/components/UpgradeCard";
import { SequentialBrowseModal } from "../BrowseModal";
import { useSequentialBrowseModalStore } from "../BrowseModal/useSequentialBrowseModalStore";
import { SequentialFlowModal } from "../FlowModal";
import { SequentialDeleteFlow } from "../FlowModal/FlowDelete";
import { useSequentialDeleteFlowModal } from "../FlowModal/FlowDelete/useSequentialDeleteFlowModal";
import { useSequentialFlowModalStore } from "../FlowModal/useSequentialFlowModalStore";
import { SequentialGroupContext } from "../SequentialGroupContext";
import { useSequentialPreviewModal } from "../PreviewModal/useSequentialPreviewModal";
import { SequentialPreviewModal } from "../PreviewModal";

const HeaderWrapper = styled.div`
  .Polaris-Text--headingMd {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
`;

export const SequentialModal = () => {
  const isBrowseModalOpen = useSequentialBrowseModalStore(state => state.isModalOpen);
  const isFlowModalOpen = useSequentialFlowModalStore(state => state.isModalOpen);
  const isDeleteFlowModalOpen = useSequentialDeleteFlowModal(state => state.isModalOpen);
  const isPreviewModalOpen = useSequentialPreviewModal(state => state.isModalOpen);

  const closeBrowseModal = useSequentialBrowseModalStore(state => state.closeModal);
  const closeFlowModal = useSequentialFlowModalStore(state => state.closeModal);
  const closeDeleteFlowModal = useSequentialDeleteFlowModal(state => state.closeModal);
  const closePreviewModal = useSequentialPreviewModal(state => state.closeModal);

  const isModalOpen = isBrowseModalOpen || isFlowModalOpen || isDeleteFlowModalOpen || isPreviewModalOpen;

  const getTitle = () => {
    if (isDeleteFlowModalOpen) {
      return "Delete this flow";
    }
    if (isBrowseModalOpen) {
      return "Select item";
    }
    if (isPreviewModalOpen) {
      return "Previewing Flow";
    }
    return "Adding flow";
  };

  return (
    <Modal
      title={getTitle()}
      open={isModalOpen}
      onClose={() => {
        closeBrowseModal();
        closeFlowModal();
        closeDeleteFlowModal();
        closePreviewModal();
      }}
    >
      {isDeleteFlowModalOpen ? (
        <SequentialDeleteFlow />
      ) : (
        <React.Fragment>
          <SequentialFlowModal />
          <SequentialBrowseModal />
          <SequentialPreviewModal />
        </React.Fragment>
      )}
    </Modal>
  );
};

const SequentialContent = () => {
  const openModal = useSequentialFlowModalStore(state => state.openModal);
  const groupContext = useContext(SequentialGroupContext);

  return (
    <React.Fragment>
      <VerticalStack gap="2">
        <ShowSequentials />
        <HorizontalStack align="end">
          <Button
            id="admin-selling-plan-group__sequential-open-modal"
            primary
            onClick={() =>
              openModal({
                groupId: groupContext.numericShopifyId,
              })
            }
          >
            Add flow
          </Button>
        </HorizontalStack>
      </VerticalStack>
      <SequentialModal />
    </React.Fragment>
  );
};

export const SequentialInformation = () => {
  const isSequentialAvailable = useIsFeatureAvailable(FeatureEnum.SEQUENTIAL_SELLING_PLAN);

  if (!isSequentialAvailable) {
    return <UpgradeCard upgradeToGrow />;
  }

  return (
    <Card>
      <VerticalStack gap="2">
        <div
          style={{
            paddingRight: "1.25rem",
          }}
        >
          <LegacyStack alignment="center" distribution="equalSpacing">
            <HeaderWrapper>
              <Text variant="headingMd" as="h2" fontWeight="regular">
                Sequential Flow Builder
              </Text>
            </HeaderWrapper>
          </LegacyStack>
          <Text variant="bodyMd" as="span" color="subdued">
            Determine how your products are converted throughout a subscription lifecycle by enabling a sequence
            of products after a certain number of deliveries.
          </Text>
        </div>
        <SequentialContent />
      </VerticalStack>
    </Card>
  );
};
