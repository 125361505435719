import { Box } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const StyledBox = styled.div<{ addBorder: boolean }>`
  ${props =>
    props.addBorder
      ? `
      & > .Polaris-Box {
        box-shadow: 0px 1px 0px 0px #E4E5E7 inset;
      }
    `
      : ""}
`;

interface ISequentialSectionProps {
  border?: boolean;
  children: React.ReactNode;
}

export const SequentialSection = ({ border, children }: ISequentialSectionProps) => {
  return (
    <StyledBox addBorder={!!border}>
      <Box as="section" padding="5">
        {children}
      </Box>
    </StyledBox>
  );
};
