import { Banner, Link, Text } from "@shopify/polaris";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { shopifyGidToNumber, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import React from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { createAndDownloadFile } from "@vendor-app/utils/createAndDownloadCsv";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { DeleteFlowAccess } from "../FlowDeleteStore";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

export const SequentialDeleteFlowBanner = () => {
  const stage = DeleteFlowAccess.useStage();
  const { addToast } = useToast();

  if (
    stage.stage !== "ActiveCPS" ||
    stage.sequential.vendorInfo === null ||
    stage.sequential.vendorInfo === undefined
  ) {
    return <React.Fragment />;
  }

  // based on vendorInfo.firstTenCps
  const numCpsShown = 10;

  const cpsInfo = stage.sequential.vendorInfo;

  const more = Math.max(0, cpsInfo.cpsCount - numCpsShown);

  const openCps = (cps: SequentialApi.ActiveSequentialCps.Type) => {
    frontEndTabOpen(`${adminRoutePrefix}/subscriptions/${viewShopifyId(cps.cpsShopifyId)}`);
  };

  const downloadCsv = async () => {
    const csv = await typedFrontendVendorApi.getReq("/sequentials/:id/download/csv", {
      params: {
        id: stage.sequential.id,
      },
    });

    if (csv.type === "success") {
      try {
        createAndDownloadFile({
          text: csv.body,
          fileType: "text/csv;charset=utf-8",
          filename: `subscriptions-${stage.sequential.id}.csv`,
        });
        addToast("CSV successfully created");
      } catch {
        addToast("Error exporting to CSV");
      }
    } else {
      addToast("Error exporting to CSV");
    }
  };

  return (
    <Banner status="warning">
      <Text as="p" variant="bodyMd">
        <Text as="span" variant="bodyMd">
          You currently have
        </Text>
        <Text as="span" variant="bodyMd" fontWeight="bold">
          &nbsp;({cpsInfo.cpsCount ?? 0})&nbsp;
        </Text>
        <Text as="span" variant="bodyMd">
          subscriptions using this flow.
        </Text>
      </Text>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {cpsInfo.firstTenCps.map(cps => {
          return (
            <li style={{ color: "#2C6ECB" }} key={cps.cpsShopifyId}>
              <Link onClick={() => openCps(cps)}>
                {cps.customerName} - #{shopifyGidToNumber(cps.cpsShopifyId)}
              </Link>
            </li>
          );
        })}
        {more > 0 ? (
          <li style={{ color: "#2C6ECB" }} key={"more-link"}>
            <Link onClick={() => downloadCsv()}>+{more} more</Link>
          </li>
        ) : undefined}
      </ul>
    </Banner>
  );
};
