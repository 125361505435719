import { Icon, LegacyStack, Text } from "@shopify/polaris";
import { PlayCircleMajor } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";

const FlowTextContainer = styled.div`
  & p.Polaris-Text--root {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const SequentialFlowStart = () => {
  return (
    <LegacyStack spacing="extraTight">
      <Icon source={PlayCircleMajor} color="base" />
      <FlowTextContainer>
        <Text as="p" variant="bodyMd">
          FLOW START
        </Text>
      </FlowTextContainer>
    </LegacyStack>
  );
};
