import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";

import { getCustomerPurchaseStates } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";

export const subscriptionsMatchingFilter = async (filterIn: IDeserializedPaginatedQuery["filterIn"]) => {
  const { body: affectedSubscriptions }: any = await getCustomerPurchaseStates({
    queryParams: {
      pageNumber: 0,
      pageSize: 0,
      filterIn: {
        ...filterIn,
        status: [SubscriptionContractSubscriptionStatus.Active, SubscriptionContractSubscriptionStatus.Paused],
      },
    },
  });
  return affectedSubscriptions.data;
};
