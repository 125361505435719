import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import UnreachableCaseError from "@vendor-app/utils/unreachableCaseError";
import { useReducer } from "react";

interface IConfirmationModalState {
  subscriptions: IPurchaseStateWithCustomerRelationship[];
  method: () => void;
  title: string;
  message: string;
  confirmActionMessage: string;
  isModalOpen: boolean;
}

type ConfirmationModalReducerAction =
  | {
      type: "OPEN_MODAL";
      payload: {
        subscriptions: IPurchaseStateWithCustomerRelationship[];
        title: string;
        message: string;
        confirmActionMessage: string;
        method: () => void;
      };
    }
  | {
      type: "CLOSE_MODAL";
    };

const initState: IConfirmationModalState = {
  subscriptions: [],
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  method() {},
  title: "",
  message: "",
  confirmActionMessage: "",
  isModalOpen: false,
};

const reducer = (
  state: IConfirmationModalState,
  action: ConfirmationModalReducerAction
): IConfirmationModalState => {
  const { type } = action;
  switch (type) {
    case "OPEN_MODAL": {
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        confirmActionMessage: action.payload.confirmActionMessage,
        method: action.payload.method,
        subscriptions: action.payload.subscriptions,
        isModalOpen: true,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        isModalOpen: false,
        title: "",
        message: "",
        confirmActionMessage: "",
        method() {}, // eslint-disable-line  @typescript-eslint/no-empty-function
        subscriptions: [],
      };
    }
    default: {
      throw new UnreachableCaseError(type);
    }
  }
};

export const useConfirmationModalReducer = () => {
  const [modalState, dispatch] = useReducer(reducer, initState);

  const closeModal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  const openModal = (
    title: string,
    message: string,
    confirmActionMessage: string,
    method: () => void,
    subscriptions: []
  ) => {
    dispatch({ type: "OPEN_MODAL", payload: { title, message, confirmActionMessage, method, subscriptions } });
  };

  return { modalState, openModal, closeModal };
};
