import React from "react";

export const Filler = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div
      style={{
        flex: "1 1 auto",
      }}
    >
      {children ?? null}
    </div>
  );
};
