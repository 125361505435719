import {
  Button,
  HorizontalStack,
  Pagination,
  ResourceItem,
  ResourceList,
  Spinner,
  Text,
  TextField,
} from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { publishEvent } from "@vendor-app/utils/customEvent";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { ProductDisplay } from "./ProductDisplay";
import { PRODUCTS_PER_PAGE, SequentialProductSearch } from "./store";
import { useSequentialBrowseModalStore } from "./useSequentialBrowseModalStore";
import { ActionButtons } from "../utils/ActionButtons";
import { SequentialSection } from "../utils/SequentialSection";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

export interface ISequentialBrowseModalProps {
  groupId: string;
  selectedVariant: SequentialApi.SequentialVariantWithParent.Type | null;
  sequenceId: string;
  sequentialId: string | null;
}

const SequentialBrowse = ({ info }: { info: ISequentialBrowseModalProps }) => {
  const closeModal = useSequentialBrowseModalStore(state => state.closeModal);

  const isLoading = SequentialProductSearch.access.useLoading();
  const products = SequentialProductSearch.access.useData();
  const page = SequentialProductSearch.access.usePage();
  const actions = SequentialProductSearch.access.useActions();
  const hasLoadedBefore = SequentialProductSearch.access.useHasLoadedBefore();
  const hasMorePages = SequentialProductSearch.access.useHasMorePages();
  const maxPages = SequentialProductSearch.access.useMaxPage();

  const [chosenVariants, setChosenVariants] = useState<SequentialApi.SequentialVariantWithParent.Type[]>([]);

  useEffect(() => {
    if (info.selectedVariant) {
      setChosenVariants([info.selectedVariant]);
    }
  }, [info.selectedVariant]);

  const [searchTerm, setSearchTerm] = useState("");
  const changeSearch = useCallback(
    debounce((newSearch: string) => {
      actions.search.update(newSearch);
    }, 300),
    []
  );
  const updateSearch = (search: string) => {
    setSearchTerm(search);
    changeSearch(search);
  };

  return (
    <React.Fragment>
      <SequentialSection>
        <TextField
          type="text"
          label="Search"
          labelHidden
          placeholder="Search products"
          autoComplete="off"
          value={searchTerm}
          onChange={s => updateSearch(s)}
        ></TextField>
        {isLoading || !hasLoadedBefore ? (
          <div
            style={{
              minHeight: "436px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <HorizontalStack align="center">
              <Spinner />
            </HorizontalStack>
          </div>
        ) : (
          <React.Fragment>
            <div
              style={{
                minHeight: "400px",
                maxHeight: "400px",
                overflowY: "scroll",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {products.length !== 0 && hasLoadedBefore ? null : (
                <HorizontalStack>
                  <Text as="p" variant="bodyMd">
                    Reached end of search.
                  </Text>
                </HorizontalStack>
              )}
              {products.length > 0 ? (
                <ResourceList
                  resourceName={{
                    singular: "Product",
                    plural: "Products",
                  }}
                  items={products}
                  renderItem={(product, id, index) => {
                    return (
                      <ResourceItem id={id} key={index} onClick={NO_OP_CALLBACK}>
                        <ProductDisplay
                          product={product}
                          info={info}
                          variantsChosen={chosenVariants}
                          onVariantChosen={v => setChosenVariants([v])}
                          onVariantUnchosen={() => setChosenVariants([])}
                        />
                      </ResourceItem>
                    );
                  }}
                />
              ) : null}
            </div>
            <HorizontalStack align="center">
              <Pagination
                hasPrevious={page > 1}
                onPrevious={() => {
                  actions.page.update(page - 1);
                }}
                hasNext={hasMorePages}
                onNext={() => {
                  actions.page.update(page + 1);
                }}
                label={`Page ${page} of ${maxPages}`}
              />
            </HorizontalStack>
          </React.Fragment>
        )}
      </SequentialSection>
      <SequentialSection border>
        <ActionButtons>
          <HorizontalStack blockAlign="center" align="end" gap="150">
            <Button onClick={() => closeModal()}>Back</Button>
            <Button
              onClick={() => {
                publishEvent("smartrr.selling_plan_group.sequential.select_variant", {
                  vnt: chosenVariants[0],
                  id: info.sequenceId,
                });
                closeModal();
              }}
              primary
              disabled={chosenVariants.length === 0}
            >
              Select
            </Button>
          </HorizontalStack>
        </ActionButtons>
      </SequentialSection>
    </React.Fragment>
  );
};

export const SequentialBrowseModal = () => {
  const isModalOpen = useSequentialBrowseModalStore(state => state.isModalOpen);
  const modalInfo = useSequentialBrowseModalStore(state => state.modalPayload);
  const actions = SequentialProductSearch.access.useActions();

  useEffect(() => {
    if (isModalOpen) {
      if (modalInfo) {
        actions.dynamic.update({
          groupId: modalInfo.groupId,
          perPage: PRODUCTS_PER_PAGE,
        });
      }
    } else {
      actions.reset();
    }
  }, [isModalOpen, modalInfo]);

  return (
    <React.Fragment>{isModalOpen && modalInfo ? <SequentialBrowse info={modalInfo} /> : null}</React.Fragment>
  );
};
