import { createContext } from "react";

export const DeleteFlowStageContext = createContext<{
  submitting: boolean;
  deleteFlow: (config: { cancelSubscriptions: boolean }) => Promise<void>;
}>({
  submitting: false,
  async deleteFlow() {
    /* NO_OP */
  },
});
