import {
  MIN_SEQUENTIAL_ORDER_NUMBER,
  SequenceActionSchemaType,
  SequentialSchemaType,
} from "@smartrr/shared/entities/Sequential";
import { compact, uniqueId } from "lodash";
import { create, StateCreator } from "zustand";

import { immer } from "zustand/middleware/immer";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { SequentialForm } from "./form";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { CombinedSequential } from "@smartrr/shared/interfaces/sequential/misconfigured";

interface WithSuccess<Type> {
  result: "success";
  data: Type;
}

interface WithFailure {
  result: "failure";
  message: string;
}

export type WithResult<Type> = WithSuccess<Type> | WithFailure;

interface SequentialStore extends SequentialForm.Sequential.Type {
  sequentialId: string | null;
  isValidated: boolean;
  actions: {
    editExistingSequential(sequential: CombinedSequential): void;
    createNewSequential(groupId: string): void;

    setInitialVariant(variant: SequentialApi.SequentialVariantWithParent.Type): void;

    addNewSequentialAction(): void;
    addNewSequentialActionAfter(action: SequentialForm.Sequential.SequenceType): void;
    duplicateNewSequentialActionAfter(action: SequentialForm.Sequential.SequenceType): void;
    deleteSequentialAction(action: SequentialForm.Sequential.SequenceType): void;

    setSequentialSwap(
      id: string,
      orderNumber: number,
      swapVariant: SequentialApi.SequentialVariantWithParent.Type | null
    ): void;

    discount: {
      set(id: string, orderNumber: number): void;
      setDiscount(id: string, type: SequentialApi.Actions.Discount.DiscountType, amount: number);
      setOrderType(id: string, type: SequentialApi.Actions.Discount.OrderType);
    };

    setEndSequentialAsRepeat(): void;
    setEndSequentialAsEnd(orderNumber: number): void;
    setEndSequentialAsRestart(orderNumber: number, restartFromSequenceNumber: number): void;

    setOrderNumber(id: string, orderNumber: number): void;

    validate: () => void;
    submitFlow: () => Promise<WithResult<{ sequential: CombinedSequential }>>;
    deleteFlow: (cancelSubs: boolean) => Promise<WithResult<{}>>;
  };
  internal: {
    prevalidate: () => void;
  };
}

const sequentialStoreSlice: StateCreator<SequentialStore, [["zustand/immer", never]]> = (set, get) => ({
  groupId: "",
  initialVariant: null,
  sequence: [],
  endAction: {
    id: uniqueId(),
    actionType: "UNDECIDED",
    orderNumber: MIN_SEQUENTIAL_ORDER_NUMBER,
  },
  errors: [],
  sequentialId: null,
  isValidated: false,
  actions: {
    editExistingSequential(sequential: CombinedSequential) {
      set(draft => {
        draft.groupId = sequential.groupId;
        draft.initialVariant = sequential.initialVariant;
        draft.sequence = sequential.sequence.map(seq => ({
          ...seq,
          id: uniqueId(),
        }));
        draft.endAction = sequential.endAction;
        draft.sequentialId = sequential.id;
        draft.errors = [];
        draft.isValidated = false;
      });
    },

    createNewSequential(groupId: string) {
      if (get().sequentialId === null && groupId === get().groupId) {
        return;
      }
      set(draft => {
        draft.groupId = groupId;
        draft.initialVariant = null;
        draft.sequence = [];
        draft.endAction = {
          id: uniqueId(),
          actionType: "UNDECIDED",
          orderNumber: MIN_SEQUENTIAL_ORDER_NUMBER,
        };
        draft.sequentialId = null;
        draft.errors = [];
        draft.isValidated = false;
      });
    },

    setInitialVariant(variant: SequentialApi.SequentialVariantWithParent.Type) {
      set(draft => {
        draft.initialVariant = variant;
      });
    },

    addNewSequentialAction() {
      set(draft => {
        draft.sequence.push({
          id: uniqueId(),
          actionType: "UNDECIDED",
          orderNumber: MIN_SEQUENTIAL_ORDER_NUMBER,
        });
      });
    },

    addNewSequentialActionAfter(action: SequentialForm.Sequential.SequenceType) {
      set(draft => {
        for (let index = 0; index < draft.sequence.length; index++) {
          if (draft.sequence[index].id === action.id) {
            draft.sequence.splice(index + 1, 0, {
              id: uniqueId(),
              actionType: "UNDECIDED",
              orderNumber: MIN_SEQUENTIAL_ORDER_NUMBER,
            });
            return;
          }
        }
      });
    },

    duplicateNewSequentialActionAfter(action: SequentialForm.Sequential.SequenceType) {
      set(draft => {
        for (let index = 0; index < draft.sequence.length; index++) {
          if (draft.sequence[index].id === action.id) {
            draft.sequence.splice(index + 1, 0, {
              ...action,
              id: uniqueId(),
            });
            return;
          }
        }
      });
    },

    deleteSequentialAction(action: SequentialForm.Sequential.SequenceType) {
      set(draft => {
        draft.sequence = draft.sequence.filter(sequence => sequence.id !== action.id);
      });
    },

    setSequentialSwap(
      id: string,
      orderNumber = MIN_SEQUENTIAL_ORDER_NUMBER,
      swapVariant: SequentialApi.SequentialVariantWithParent.Type | null = null
    ) {
      set(draft => {
        for (let index = 0; index < draft.sequence.length; index++) {
          if (draft.sequence[index].id === id) {
            draft.sequence[index] = {
              id,
              actionType: "SWAP",
              orderNumber,
              swapVariant,
            };
          }
        }
      });
    },

    discount: {
      set(id: string, orderNumber: number): void {
        set(draft => {
          for (let index = 0; index < draft.sequence.length; index++) {
            if (draft.sequence[index].id === id) {
              draft.sequence[index] = {
                id,
                actionType: "DISCOUNT",
                orderNumber,
                discount: {
                  type: "FIXED",
                  amount: 0,
                },
                orderType: "ALL",
              };
            }
          }
        });
      },

      setDiscount(id: string, type: SequentialApi.Actions.Discount.DiscountType, amount: number) {
        set(draft => {
          for (let index = 0; index < draft.sequence.length; index++) {
            const action = draft.sequence[index];
            if (action.id === id && action.actionType === "DISCOUNT") {
              action.discount = {
                type,
                amount,
              };
            }
          }
        });
      },

      setOrderType(id: string, type: SequentialApi.Actions.Discount.OrderType) {
        set(draft => {
          for (let index = 0; index < draft.sequence.length; index++) {
            const action = draft.sequence[index];
            if (action.id === id && action.actionType === "DISCOUNT") {
              action.orderType = type;
            }
          }
        });
      },
    },

    setEndSequentialAsRepeat() {
      set(draft => {
        const orderNumber =
          draft.sequence.length > 0
            ? draft.sequence[draft.sequence.length - 1].orderNumber + 1
            : MIN_SEQUENTIAL_ORDER_NUMBER;
        draft.endAction = {
          actionType: "REPEAT",
          orderNumber,
        };
      });
    },

    setEndSequentialAsEnd(orderNumber: number) {
      set(draft => {
        draft.endAction = {
          actionType: "END",
          orderNumber,
        };
      });
    },

    setEndSequentialAsRestart(orderNumber: number, restartFromSequenceNumber: number) {
      set(draft => {
        draft.endAction = {
          actionType: "RESTART",
          orderNumber,
          restartFromSequenceNumber,
        };
      });
    },

    setOrderNumber(id: string, orderNumber: number) {
      set(draft => {
        for (let index = 0; index < draft.sequence.length; index++) {
          if (draft.sequence[index].id === id) {
            draft.sequence[index].orderNumber = orderNumber;
          }
        }
      });
    },

    validate() {
      get().internal.prevalidate();

      const errors: SequentialForm.Errors.Type[] = [];

      const sequential = get();

      if (sequential.initialVariant === null) {
        errors.push({
          location: "initial",
          id: null,
          errorType: "missingVariant",
        });
      }

      for (let index = 0; index < sequential.sequence.length; index++) {
        const prevSeq = sequential.sequence[index - 1];
        const currSeq = sequential.sequence[index];

        const smallerThanPrevious = prevSeq && currSeq.orderNumber < prevSeq.orderNumber;
        const currentTooLow = currSeq.orderNumber < MIN_SEQUENTIAL_ORDER_NUMBER;

        if (smallerThanPrevious || currentTooLow) {
          errors.push({
            location: "sequence",
            id: currSeq.id,
            errorType: "orderNumberTooSmall",
          });
        }

        switch (currSeq.actionType) {
          case "UNDECIDED": {
            errors.push({
              location: "sequence",
              id: currSeq.id,
              errorType: "selectType",
            });
            break;
          }
          case "SWAP": {
            if (currSeq.swapVariant === null) {
              errors.push({
                location: "sequence",
                id: currSeq.id,
                errorType: "missingVariant",
              });
            }
            break;
          }
          case "DISCOUNT": {
            if (currSeq.discount.amount <= 0) {
              errors.push({
                location: "sequence",
                id: currSeq.id,
                errorType: "discountTooLow",
              });
            }
            if (currSeq.discount.type === "PERCENTAGE" && currSeq.discount.amount > 100) {
              errors.push({
                location: "sequence",
                id: currSeq.id,
                errorType: "discountTooHigh",
              });
            }
          }
        }
      }

      const allOrderDiscounts = sequential.sequence.filter(
        seq => seq.actionType === "DISCOUNT" && seq.orderType === "ALL"
      );
      if (allOrderDiscounts.length > 1) {
        errors.push({
          location: "sequence",
          id: null,
          errorType: "tooManyDiscounts",
        });
      }

      if (sequential.endAction.actionType === "UNDECIDED") {
        errors.push({
          location: "end",
          id: null,
          errorType: "selectType",
        });
      }

      if (sequential.endAction.actionType === "RESTART") {
        const end = sequential.endAction;
        if (end.restartFromSequenceNumber < 1) {
          errors.push({
            location: "end",
            id: null,
            errorType: "restartSequenceInvalid",
          });
        }
        if (end.restartFromSequenceNumber >= end.orderNumber) {
          errors.push({
            location: "end",
            id: null,
            errorType: "restartSequenceInvalid",
          });
        }
      }

      const lastSeq = sequential.sequence[sequential.sequence.length - 1];

      if (lastSeq && sequential.endAction.orderNumber <= lastSeq.orderNumber) {
        errors.push({
          location: "end",
          id: null,
          errorType: "orderNumberTooSmall",
        });
      }

      set(({}) => ({
        errors,
        isValidated: true,
      }));
    },

    async submitFlow(): Promise<WithResult<{ sequential: CombinedSequential }>> {
      const sequential = get();
      get().actions.validate();

      if (sequential.errors.length > 0) {
        return {
          result: "failure",
          message: "There are still unresolved errors.",
        };
      }

      const apiSequential: SequentialSchemaType = {
        initialVntId: sequential.initialVariant?.id ?? "",
        sellingPlanGroupId: sequential.groupId,
        sequence: compact(
          [...sequential.sequence, sequential.endAction].map((action): SequenceActionSchemaType | null => {
            switch (action.actionType) {
              case "SWAP": {
                return {
                  _actionType: "SWAP",
                  orderNumber: action.orderNumber,
                  vntToSwapTo: action.swapVariant?.id ?? "",
                };
              }
              case "DISCOUNT": {
                return {
                  _actionType: "ADD_DISCOUNT",
                  orderNumber: action.orderNumber,
                  discountType: action.discount.type === "FIXED" ? "fixed_amount" : "percentage",
                  discountValue: action.discount.amount,
                  forAllSubsequentOrders: action.orderType === "ALL",
                };
              }
              case "REPEAT": {
                return {
                  _actionType: "REPEAT",
                  orderNumber: action.orderNumber,
                };
              }
              case "END": {
                return {
                  _actionType: "END",
                  orderNumber: action.orderNumber,
                };
              }
              case "RESTART": {
                return {
                  _actionType: "RESTART",
                  orderNumber: action.orderNumber,
                  restartFromSequenceNumber: action.restartFromSequenceNumber,
                };
              }
              default: {
                return null;
              }
            }
          })
        ),
      };

      const sequentialId = get().sequentialId;

      const sendRequest = async () => {
        if (sequentialId) {
          return await typedFrontendVendorApi.putReq(`/sequentials/:id`, {
            reqBody: apiSequential,
            params: {
              id: sequentialId,
            },
          });
        }
        return await typedFrontendVendorApi.postReq(`/sequentials`, {
          reqBody: apiSequential,
        });
      };

      const result = await sendRequest();

      if (result.type === "success") {
        if (!sequentialId) {
          sequential.actions.createNewSequential("");
        }
        return {
          result: "success",
          data: {
            sequential: result.body,
          },
        };
      }
      return {
        result: "failure",
        message: result.message,
      };
    },

    async deleteFlow(cancelSubs: boolean): Promise<WithResult<{}>> {
      const sequentialId = get().sequentialId;

      if (sequentialId === null) {
        return {
          result: "failure",
          message: "Cannot delete a sequential that has not been created yet.",
        };
      }

      const result = await typedFrontendVendorApi.deleteReq("/sequentials/:id", {
        params: {
          id: sequentialId,
        },
        query: {
          cancelSubs: cancelSubs ? "true" : "false",
        },
      });

      if (result.type === "success") {
        return {
          result: "success",
          data: {},
        };
      }
      return {
        result: "failure",
        message: "Unable to delete Sequential.",
      };
    },
  },

  internal: {
    prevalidate(): void {
      const store = get();
      const endAction = store.endAction;

      const orderNumber =
        store.sequence.length > 0
          ? store.sequence[store.sequence.length - 1].orderNumber + 1
          : MIN_SEQUENTIAL_ORDER_NUMBER;

      if (endAction.actionType === "REPEAT" && endAction.orderNumber !== orderNumber) {
        store.actions.setEndSequentialAsRepeat();
      }
    },
  },
});

const useSequentialStore = create<SequentialStore>()(immer(sequentialStoreSlice));

const initialState = useSequentialStore.getState();

export const EditSequentialAccess = {
  useGroup() {
    return useSequentialStore(state => state.groupId);
  },

  useVariant() {
    return useSequentialStore(state => state.initialVariant);
  },

  useActions() {
    return useSequentialStore(state => state.actions);
  },

  useSequence() {
    return useSequentialStore(state => state.sequence);
  },

  useEndAction() {
    return useSequentialStore(state => state.endAction);
  },

  useErrors() {
    return useSequentialStore(state => state.errors);
  },

  useSequentialId() {
    return useSequentialStore(state => state.sequentialId);
  },

  useIsValidated() {
    return useSequentialStore(state => state.isValidated);
  },

  testing: {
    state: useSequentialStore.getState,
    actions: useSequentialStore.getState().actions,
    sequential: useSequentialStore.getState().sequence,
    reset: () => useSequentialStore.setState(initialState),
    initialize(sequential: SequentialApi.Sequential.Type) {
      EditSequentialAccess.testing.reset();
      EditSequentialAccess.testing.state().actions.editExistingSequential({ ...sequential, type: "TRANSFORMED" });
    },
  },
};
