import { LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { flatten } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";

import { SettingToggleSwitch } from "@vendor-app/app/_sharedComponents/SettingToggleSwitch/SettingToggleSwitch";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { ProductOffering } from "./ProductOffering";
import { UpgradeCard } from "./UpgradeCard";
import { useIsFeatureAvailable } from "../../components/authorization/featureAccess";
import { ProductOfferingSettings } from "../models";

const StackWrapper = styled.div.attrs((props: { isPrepaid?: boolean }) => props)`
  .Polaris-LegacyStack {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    .Polaris-LegacyStack__Item:first-child {
      width: 81%;
    }
    .Polaris-LegacyStack__Item:last-child {
      margin-top: 10px;
      .Polaris-Button {
        margin-top: 5px;
      }
      .Polaris-Button:focus {
        text-decoration: none;
        color: white;
      }
    }
    .Polaris-LegacyStack__Item {
      .Polaris-Text--headingMd {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: ${props => (props.isPrepaid ? "#8C9196" : "black")};
      }
      .Polaris-Text--subdued {
        display: block;
      }
    }
  }
`;

interface Props {
  productIds: string[];
  variantIds: string[];
  productOfferingRef: React.MutableRefObject<ProductOfferingSettings>;
  productOfferingSettings: ProductOfferingSettings;
  productOfferingRefresher: number;
  setHasChanges: (newCursor: boolean) => void;
  setSequentialPlan: React.Dispatch<React.SetStateAction<boolean>>;
  linkedProductIds: string[];
  isPrepaidPlan: boolean;
  setIsAdvancedSequential: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SellingPlanSqnce({
  productOfferingRef,
  productOfferingSettings,
  productOfferingRefresher,
  setHasChanges,
  productIds,
  variantIds,
  setSequentialPlan,
  linkedProductIds,
  isPrepaidPlan,
  setIsAdvancedSequential,
}: Props) {
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const isSuperUser = user?.isSuperUser;
  const isSequentialAvailable = useIsFeatureAvailable(FeatureEnum.SEQUENTIAL_SELLING_PLAN);
  // const linkedProducts = filterNil(
  //   linkedProductIds.map(productId => purchasables.find(purch => purch.shopifyId === productId))
  // );

  // const sequentialProducts = productOfferingRef.current.sequentialProducts || [];

  // const allAvailableVariantsFromProducts = flatten(linkedProducts.map(p => p.vnts || [])).filter(
  //   el => el.isActiveInShopify && !el.isDraftOrArchived
  // );
  // const initialAvailableVariants = filterNil(
  //   sequentialProducts.map(productId => allAvailableVariantsFromProducts.find(purch => purch.id === productId))
  // );

  const allProductsOffering = useMemo<IPurchasable[]>(() => {
    return filterNil(productIds.map(productId => purchasables.find(purch => purch.shopifyId === productId)));
  }, [productIds, purchasables]);

  const allVariantsOffering = useMemo(() => {
    const variants = flatten(purchasables.map(p => p.vnts || []));

    return filterNil(variantIds.map(variantId => variants.find(purch => purch.shopifyId === variantId)));
  }, [variantIds, purchasables]);

  const getProductOffering = () => {
    return (
      <ProductOffering
        key={`product-offering-${productOfferingRefresher}`}
        productOfferingRef={productOfferingRef}
        setHasChanges={setHasChanges}
        setSequentialPlan={setSequentialPlan}
        initialSettings={productOfferingSettings}
        allProducts={allProductsOffering}
        allVariants={allVariantsOffering}
      />
    );
  };

  if (isPrepaidPlan) {
    return (
      <React.Fragment>
        {!!isSuperUser && (
          <LegacyCard
            sectioned
            title={
              <StackWrapper isPrepaid={true}>
                <LegacyStack alignment="center" distribution="equalSpacing">
                  <div>
                    <Text variant="headingMd" as="h2">
                      Sequential Products
                    </Text>
                    <Text variant="bodyMd" as="span" color="subdued">
                      Sequential products are not currently available with prepaid plans. Please remove prepaid
                      plans to use sequential functionality.
                    </Text>
                  </div>
                  <SettingToggleSwitch toggled={false} handleClick={NO_OP_CALLBACK} disabled={true} />
                </LegacyStack>
              </StackWrapper>
            }
          />
        )}
        {activePlan?.planName === "Launch" ? <UpgradeCard upgradeToGrow /> : getProductOffering()}
      </React.Fragment>
    );
  }

  if (!isSequentialAvailable) {
    return <UpgradeCard upgradeToGrow />;
  }

  return getProductOffering();
}
