import { useContext } from "react";
import { SequentialInformation } from "./Information";
import { PrepaidInformation } from "./PrepaidInformation";
import { SequentialGroupContext } from "./SequentialGroupContext";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

export const SequentialOffering = () => {
  const { isPrepaid, numberOfPlans } = useContext(SequentialGroupContext);
  const { prepaidSequential } = useSmartrrFlags();

  if (isPrepaid) {
    if (prepaidSequential && numberOfPlans !== 1) {
      return <PrepaidInformation />;
    }
    if (!prepaidSequential) {
      return <PrepaidInformation />;
    }
  }

  return <SequentialInformation />;
};
