import { Button, LegacyStack } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { SequentialsAccess } from "../../Information/ShowSequentials/SequentialsStore";
import { EditSequentialAccess } from "../../SequentialStore";
import { ActionButtons } from "../../utils/ActionButtons";
import { useSequentialDeleteFlowModal } from "../FlowDelete/useSequentialDeleteFlowModal";
import { useSequentialFlowModalStore } from "../useSequentialFlowModalStore";
import { ViewMajor } from "@shopify/polaris-icons";
import { useSequentialPreviewModal } from "../../PreviewModal/useSequentialPreviewModal";

export const SequentialFlowSubmit = () => {
  const editActions = EditSequentialAccess.useActions();
  const errors = EditSequentialAccess.useErrors();
  const closeModal = useSequentialFlowModalStore(state => state.closeModal);
  const initialVariant = EditSequentialAccess.useVariant();
  const sequence = EditSequentialAccess.useSequence();
  const endSequence = EditSequentialAccess.useEndAction();
  const sequenceId = EditSequentialAccess.useSequentialId();
  const actions = SequentialsAccess.useActions();
  const openDeleteConfirmationModal = useSequentialDeleteFlowModal(state => state.openModal);
  const openPreviewModal = useSequentialPreviewModal(state => state.openModal);

  const { addToast } = useToast();

  const [submitting, setSubmitting] = useState(false);

  const submitFlow = async () => {
    setSubmitting(true);
    const id = sequenceId;
    const result = await editActions.submitFlow();
    if (result.result === "success") {
      actions.update([result.data.sequential]);
      addToast(id ? "Sequence updated." : "Sequence created.");
    } else {
      const message = id ? "Error updating sequence." : "Error creating sequence.";
      addToast(`${message} ${result.message}`);
    }
    setSubmitting(false);
    closeModal();
  };

  const deleteFlow = async () => {
    openDeleteConfirmationModal({});
  };

  useEffect(() => {
    editActions.validate();
  }, [initialVariant, sequence, endSequence]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {sequenceId ? (
          <Button plain destructive onClick={() => deleteFlow()}>
            Delete this flow
          </Button>
        ) : null}
      </div>
      <ActionButtons>
        <LegacyStack distribution="trailing">
          <Button icon={ViewMajor} disabled={errors.length > 0} onClick={() => openPreviewModal({})}>
            Preview
          </Button>
          <Button onClick={() => closeModal()}>Cancel</Button>
          <Button primary loading={submitting} disabled={errors.length > 0} onClick={() => submitFlow()}>
            Confirm
          </Button>
        </LegacyStack>
      </ActionButtons>
    </div>
  );
};
