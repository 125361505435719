import React, { useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { DeleteFlowStageContext } from "./DeleteFlowStageContext";
import { SequentialsAccess } from "../../../Information/ShowSequentials/SequentialsStore";
import { EditSequentialAccess } from "../../../SequentialStore";
import { useSequentialFlowModalStore } from "../../useSequentialFlowModalStore";
import {
  SequentialDeleteFlowStage_ActiveCPS,
  SequentialDeleteFlowStage_ActiveCPS_FlowDecision,
  SequentialDeleteFlowStage_NoActiveCPS,
} from "../DeleteFlowStages";
import { DeleteFlowAccess } from "../FlowDeleteStore";
import { useSequentialDeleteFlowModal } from "../useSequentialDeleteFlowModal";

export const SequentialDeleteFlowShowStage = () => {
  const stage = DeleteFlowAccess.useStage();
  const closeModal = useSequentialDeleteFlowModal(state => state.closeModal);
  const closeFlowModal = useSequentialFlowModalStore(state => state.closeModal);

  const editActions = EditSequentialAccess.useActions();
  const sequenceId = EditSequentialAccess.useSequentialId();
  const actions = SequentialsAccess.useActions();

  const { addToast } = useToast();

  const [submitting, setSubmitting] = useState(false);

  const deleteFlow = async (config: { cancelSubscriptions: boolean }) => {
    const id = sequenceId;
    if (!id) {
      return;
    }
    setSubmitting(true);
    const result = await editActions.deleteFlow(config.cancelSubscriptions);
    if (result.result === "success") {
      actions.remove(id);
      addToast("Flow deleted");
    } else {
      addToast(`Error deleting sequence. ${result.message}`);
    }
    closeFlowModal();
    setSubmitting(false);
    closeModal();
  };

  const StageToShow = () => {
    switch (stage.stage) {
      case "Uninitialized": {
        return <React.Fragment />;
      }
      case "NoActiveCPS": {
        return <SequentialDeleteFlowStage_NoActiveCPS />;
      }
      case "ActiveCPS": {
        return <SequentialDeleteFlowStage_ActiveCPS />;
      }
      case "ActiveCPS_FlowDecision": {
        return <SequentialDeleteFlowStage_ActiveCPS_FlowDecision />;
      }
    }
  };

  return (
    <DeleteFlowStageContext.Provider
      value={{
        submitting,
        deleteFlow,
      }}
    >
      <StageToShow />
    </DeleteFlowStageContext.Provider>
  );
};
