import { useApolloClient } from "@apollo/client";
import {
  Banner,
  Button,
  ContextualSaveBar,
  Layout,
  LegacyStack,
  Link,
  Modal,
  Page,
  Pagination,
  Text,
} from "@shopify/polaris";
import {
  NO_OP_CALLBACK,
  SMARTRR_SCRIPT_DEFAULT_USER_DEFINED_PRIORITY,
  adminConfigRoutePrefix,
  adminRoutePrefix,
} from "@smartrr/shared/constants";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { ScriptActionTypeEnum, ScriptEventTypeEnum } from "@smartrr/shared/entities/Script";
import { ISmartrrBundleConfig, ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { paginatedRequestStreamShopifyGraphQL } from "@smartrr/shared/shopifyGraphQL/_utils";
import { DisplayableError, SellingPlanInput, UserError } from "@smartrr/shared/shopifyGraphQL/api";
import {
  mutationShopifyDeliveryProfileUpdate,
  paginatedQueryShopifyDeliveryProfiles,
} from "@smartrr/shared/shopifyGraphQL/deliveryProfile";
import {
  EMPTY_CONNECTION,
  mutationShopifyCreateSellingPlanGroup,
  mutationShopifyDeleteSellingPlanGroup,
  mutationShopifyUpdateSellingPlanGroup,
  querySellingPlanGroupProducts,
  querySellingPlanGroupVariants,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { ensureShopifyGid, shopifyGidToNumber, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import config from "@vendor-app/config";
import { capitalize, cloneDeep, flatten, isEmpty, isEqual, last, omit, set, uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { RemovalConfirmationModal } from "@vendor-app/app/_sharedComponents/RemovalConfirmationModal";
import { SequentialOffering } from "@vendor-app/app/_sharedComponents/Sequential";
import {
  SequentialGroupContext,
  SequentialGroupContextType,
} from "@vendor-app/app/_sharedComponents/Sequential/SequentialGroupContext";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { getCustomerPurchaseStates } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { updateSetup } from "@vendor-app/app/_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { BundleConfigAccess } from "@vendor-app/app/_state/zustand/BundleConfigStore";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import {
  addProductsToGroup,
  addVariantsToGroup,
  removeProductsFromGroup,
  removeVariantsFromGroup,
  syncSellingPlanGroup,
} from "@vendor-app/utils/sellingPlanGroupProductManagement";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useLocation } from "@vendor-app/utils/useLocation";

import { ISetupFieldsForUpdate } from "../../AdminSetupRoute/libs/types";
import { PaginationContainer } from "../../AdminSubscriptionDetailsRoute/libs";
import { SellingPlanBundle } from "../components/SellingPlanBundle";
import { SellingPlanGroup } from "../components/SellingPlanGroup";
import { SellingPlanProducts } from "../components/SellingPlanProducts";
import { SellingPlanSqnce } from "../components/SellingPlanSeq";
import { defaultOffering, emptySellingPlanGroup } from "../constants";
import { ProductOfferingSettings } from "../models";
import {
  SellingPlanGroupInputWithId,
  determineSellingPlanOperations,
  didProductOfferingChange,
  hasAnyChanges,
  sellingPlanToSellingPlanInput,
} from "../utils";

interface Props {
  productIds: string[];
  variantIds: string[];
  purchasables: IPurchasable[];
  sellingPlanGroup: SellingPlanGroupInputWithId;
  sellingPlans: SellingPlanInput[];
  productOfferingSettings: ProductOfferingSettings;
  productOfferingSettingsRef: React.MutableRefObject<ProductOfferingSettings>;
  hasOldSequential: boolean;

  setProductOfferingSettings: (newSettings: ProductOfferingSettings) => void;
  onSave(sellingPlanGroup: SellingPlanGroupInputWithId, sellingPlans: SellingPlanInput[]): void;
  onDiscard(): void;
  sellingPlanGroups: ISmartrrSellingPlanGroup[];
}

const BottomButtonGroup = styled.div.attrs((props: { isDeleteButton: boolean }) => props)`
  box-shadow: inset 0 1px 0 #e4e5e7;
  display: flex;
  justify-content: ${props => (props.isDeleteButton ? "space-between" : "flex-end")};
  padding: 20px 0;
`;

const MAX_PAGE_SIZE = 250;

export function AdminSellingPlanGroupRouteWithData({
  purchasables,
  sellingPlanGroup: initialSellingPlanGroup,
  sellingPlans: initialSellingPlans,
  productOfferingSettings,
  setProductOfferingSettings,
  productOfferingSettingsRef,
  onSave: onSaveCallback,
  onDiscard: onDiscardCallback,
  hasOldSequential,
  productIds: initialProductIds,
  variantIds: initialVariantIds,
  sellingPlanGroups,
}: Props): JSX.Element {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);

  const isBundleConfigsLoading = BundleConfigAccess.useLoading();
  const allBundleConfigs = BundleConfigAccess.useConfig();
  const bundleConfigActions = BundleConfigAccess.useActions();

  const groupActions = SellingPlanGroupStoreAccess.useActions();

  const dispatch = useSmartrrVendorDispatch();

  const [pageNumber, setPageNumber] = useState<number>(0);

  const areProductsLoading = useSmartrrVendorSelector(state => state.purchasables.isLoadingAdditionaly);
  const location = useLocation();
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const isGrowActivePlan = activePlan?.planName === "Grow";

  const [sellingPlanGroupInput, setSellingPlanGroupInput] = useState<SellingPlanGroupInputWithId>({
    ...cloneDeep(initialSellingPlanGroup),
    appId: config.shopify.appId,
  });

  const [sellingPlanInputs, setSellingPlanInputs] = useState<SellingPlanInput[]>(
    initialSellingPlans.map(p => cloneDeep(p))
  );
  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");

  const [linkedProductIds, setLinkedProductIds] = useState<string[]>([]);
  const [linkedVariantIds, setLinkedVariantIds] = useState<string[]>([]);

  const [productIds, setProductIds] = useState(initialProductIds);
  const [variantIds, setVariantIds] = useState<string[]>(initialVariantIds);
  const [hasChanges, setHasChanges] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [productOfferingRefresher, setProductOfferingRefresher] = useState(0);
  const [errors, setErrors] = useState<DisplayableError[]>([]);
  const clearErrors = useCallback(() => setErrors([]), [setErrors]);
  // link products/variants state items
  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [isProductOrVariantLinkedLoading, setIsProductOrVariantLinkedLoading] = useState(false);

  // Removal confirmation modal
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>("");
  const [confirmationModalFunction, setConfirmationModalFunction] = useState<() => void>(() => undefined);
  const [confirmationModalSubscriptions, setConfirmationModalSubscriptions] = useState<
    IPurchaseStateWithCustomerRelationship[]
  >([]);

  //Linked Bundle Modal
  const [showLinkedBundleModal, setShowLinkedBundleModal] = useState<boolean>(false);

  const [reloadProductsPagination, setReloadProductsPagination] = useState<boolean>(false);
  const [showRemoveProductWarningModal, setShowRemoveProductWarningModal] = useState<boolean>(false);

  // variables for custom plans
  const [prepaidPlanNumericIds, setPrepaidPlans] = useState<string[]>([]);
  const [isSequentialPlan, setSequentialPlan] = useState<boolean>(false);
  const [isAdvancedSequential, setIsAdvancedSequential] = useState(false);

  const [terminalPlanNumericIds, setTerminalPlans] = useState<string[]>([]);
  const isPrepaidPlan = Boolean(
    sellingPlanInputs.filter(
      item => item.deliveryPolicy?.recurring?.intervalCount! < item.billingPolicy?.recurring?.intervalCount!
    ).length
  );

  const bundleConfigs = useMemo(() => {
    return allBundleConfigs.filter(
      (b: ISmartrrBundleConfig) => b.sellingPlanGroupId === sellingPlanGroupInput.id
    );
  }, [sellingPlanGroupInput.id, allBundleConfigs]);

  const closeRemoveProductWarningModal = useCallback(() => setShowRemoveProductWarningModal(false), []);

  useEffect(() => {
    if (reloadProductsPagination) {
      setReloadProductsPagination(false);
    }
  }, [reloadProductsPagination]);

  const paginatedVariantsQuery = async (newCursor: string | null, bustCache?: boolean) => {
    const paginatedVariantIds: string[] = [];
    const paginatedProductIds = new Set<string>();

    setIsProductOrVariantLinkedLoading(true);
    const res = await querySellingPlanGroupVariants(
      apolloClient,
      ensureShopifyGid("SellingPlanGroup", sellingPlanGroupInput.id!),
      MAX_PAGE_SIZE,
      newCursor,
      bustCache
    );

    if (res.type === "error" || !res.body.data.sellingPlanGroup) {
      return {
        lastCursor: null,
        hasNextPage: false,
      };
    }

    const {
      edges,
      pageInfo: { hasNextPage },
    } = res.body.data.sellingPlanGroup.productVariants;

    const nodes = edges.map(({ node }: any) => node) || [];
    for (const node of nodes) {
      paginatedVariantIds.push(node.id);
      paginatedProductIds.add(node.product.id);
    }

    if (variantIds.length === 0) {
      setVariantIds(paginatedVariantIds);
    }

    if (productIds.length === 0) {
      setProductIds([...paginatedProductIds]);
    }

    setLinkedVariantIds(paginatedVariantIds);
    setLinkedProductIds([...paginatedProductIds]);
    setIsProductOrVariantLinkedLoading(false);

    return { lastCursor: last(edges)?.cursor, hasNextPage };
  };

  const handleUpdateSetup = (setupField: ISetupFieldsForUpdate) => {
    dispatch(updateSetup(setupField));
  };

  const loadProductsAndVariants = async (
    //iterating over all products and variants an
    sellingPlanGroupId: string
  ) => {
    const productIds: string[] = [];
    const variantIds: string[] = [];

    await paginatedRequestStreamShopifyGraphQL(
      productCursor =>
        querySellingPlanGroupProducts(apolloClient, sellingPlanGroupId, MAX_PAGE_SIZE, productCursor),
      res => res.data.sellingPlanGroup?.products || EMPTY_CONNECTION,
      async res => {
        const productNodes = res.data.sellingPlanGroup!.products.edges.map(({ node }) => node) || [];
        for (const node of productNodes) {
          productIds.push(node.id);
        }
      }
    );
    await paginatedRequestStreamShopifyGraphQL(
      variantCursor =>
        querySellingPlanGroupVariants(apolloClient, sellingPlanGroupId, MAX_PAGE_SIZE, variantCursor),
      res => res.data.sellingPlanGroup?.productVariants || EMPTY_CONNECTION,
      async res => {
        const variantNodes = res.data.sellingPlanGroup?.productVariants.edges.map(({ node }) => node) || [];
        for (const node of variantNodes) {
          variantIds.push(node.id);
        }
      }
    );

    return {
      productIds,
      variantIds,
    };
  };

  useEffect(() => {
    if (sellingPlanGroupInput?.id) {
      paginatedVariantsQuery(null, true);
    }

    const sellingPlansInGroup = sellingPlanGroups.filter(s => {
      return s.shopifyId === sellingPlanGroupInput.id;
    });
    setPrepaidPlans(() => {
      const prepaidPlanNumericIds = sellingPlansInGroup
        .filter((s: any) => s.isPrepaid)
        .map((s: any) => s.shopifyId?.replace(/^\D+/g, "")!);
      return prepaidPlanNumericIds || [];
    });
    setSequentialPlan(sellingPlansInGroup.some((s: any) => s.isSequential));
    setTerminalPlans(() => {
      const terminalPlanNumericIds = sellingPlansInGroup
        .filter((s: any) => s.isTerminal)
        .map((s: any) => s.shopifyId?.replace(/^\D+/g, "")!);
      return terminalPlanNumericIds || [];
    });
  }, []);

  useEffect(() => {
    bundleConfigActions.fetchBundleConfig();
  }, []);

  useEffect(() => {
    setHasChanges(
      hasAnyChanges(initialSellingPlanGroup, initialSellingPlans, sellingPlanGroupInput, sellingPlanInputs)
    );
  }, [initialSellingPlanGroup, initialSellingPlans, sellingPlanGroupInput, sellingPlanInputs]);

  useEffect(() => {
    if (productOfferingSettingsRef.current) {
      setHasChanges(didProductOfferingChange(productOfferingSettings, productOfferingSettingsRef.current));
    }
  }, [productOfferingSettingsRef.current]);

  const openConfirmationModal = (title: string, onConfirm: () => void, subscriptions: []) => {
    setConfirmationModalOpen(true);
    setConfirmationModalFunction(() => onConfirm());
    setConfirmationModalTitle(title);
    setConfirmationModalSubscriptions(subscriptions);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setShowProductOptionsModal(false);
  };

  const subscriptionsMatchingFilter = async (filterIn: IDeserializedPaginatedQuery["filterIn"]) => {
    const { body: affectedSubscriptions }: any = await getCustomerPurchaseStates({
      queryParams: {
        pageNumber: 0,
        pageSize: 0,
        filterIn,
      },
    });
    return affectedSubscriptions.data.filter(
      (subscription: any) => subscription.purchaseStateStatus !== "CANCELLED"
    );
  };

  const updateSellingPlan = useCallback(
    (index: number, properties: Record<string, any>) => {
      let sellingPlan = sellingPlanInputs[index];
      for (const [path, newValue] of Object.entries(properties)) {
        sellingPlan = set(sellingPlan, path, newValue);
      }

      setSellingPlanInputs([
        ...sellingPlanInputs.slice(0, index),
        sellingPlan,
        ...sellingPlanInputs.slice(index + 1),
      ]);
    },
    [sellingPlanInputs]
  );

  const deleteSellingPlan = useCallback((index: number) => {
    setSellingPlanInputs(sellingPlanInputs => [
      ...sellingPlanInputs.slice(0, index),
      ...sellingPlanInputs.slice(index + 1),
    ]);
  }, []);

  const validateAndSaveProductOfferingSelection = useCallback(
    async (sellingPlanGroupId: string, productOfferingSettingsId?: string) => {
      const updateProductOfferingState = (id: string) => {
        setProductOfferingSettings({
          isActive: true,
          exists: true,
          orderNumber: productOfferingSettingsRef.current.orderNumber,
          newProductVariantId: productOfferingSettingsRef.current.newProductVariantId,
          originalProductVariantId: productOfferingSettingsRef.current.originalProductVariantId,
          id,
          sequentialEndBehavior: productOfferingSettingsRef.current.sequentialEndBehavior,
          sequentialProducts: isGrowActivePlan ? [] : productOfferingSettingsRef.current.sequentialProducts,
        });
      };
      const reqBody = {
        action: {
          actionType: ScriptActionTypeEnum.REPLACE_PRODUCT_VARIANT,
          newProductVariantId: productOfferingSettingsRef.current.newProductVariantId,
          originalProductVariantId: productOfferingSettingsRef.current.originalProductVariantId,
          sellingPlanGroupId,
          sequentialEndBehavior: productOfferingSettingsRef.current.sequentialEndBehavior,
          sequentialProducts: isGrowActivePlan ? [] : productOfferingSettingsRef.current.sequentialProducts,
        },
        condition: {
          eventType: ScriptEventTypeEnum.AFTER_ORDER_CREATED,
          orderNumber: productOfferingSettingsRef.current.orderNumber,
        },
        priority: SMARTRR_SCRIPT_DEFAULT_USER_DEFINED_PRIORITY,
      };

      if (productOfferingSettingsId) {
        if (productOfferingSettingsRef.current.exists) {
          const updateScript = await typedFrontendVendorApi.putReq("/smartrr-script/:scriptId", {
            params: { scriptId: productOfferingSettingsId },
            reqBody,
          });

          // const numericSellingPlanGroupId = sellingPlanGroupId?.replace("gid://shopify/SellingPlanGroup/", "");
          // const updateScript = await typedFrontendVendorApi.putReq(
          //   "/smartrr-script/:scriptId",
          //   {
          //     params: { sellingPlanGroupId: numericSellingPlanGroupId },
          //     reqBody,
          //   }
          // );

          if (updateScript.type === "success") {
            updateProductOfferingState(updateScript.body.id!);
            addToast("Program updated");
          } else {
            addToast(`Error updating subscription program: Error during updating Product Offering`);
          }
        } else {
          const deleteScript = await typedFrontendVendorApi.deleteReq("/smartrr-script/:scriptId", {
            params: { scriptId: productOfferingSettingsId },
          });

          if (deleteScript.type === "success") {
            setProductOfferingSettings(defaultOffering);
            addToast("Program updated");
          } else {
            addToast(`Error saving products, please try again`);
          }
        }
      } else {
        const createScript = await typedFrontendVendorApi.postReq("/smartrr-script", { reqBody });

        if (createScript.type === "success") {
          updateProductOfferingState(createScript.body.id!);
          addToast("Program updated");
        } else {
          addToast(`Error updating subscription program: Error during updating Product Offering`);
        }
      }
    },
    []
  );

  const onSave = useCallback(async () => {
    if (!sellingPlanGroupInput) {
      return;
    }
    const currentProductOfferingSettings = productOfferingSettingsRef.current;
    if (currentProductOfferingSettings.exists && currentProductOfferingSettings.isActive) {
      if (
        !isGrowActivePlan &&
        currentProductOfferingSettings.sequentialProducts?.length! <= 1 &&
        isAdvancedSequential
      ) {
        addToast("Missing sequential products");
        return;
      }
      if (
        isGrowActivePlan &&
        (!currentProductOfferingSettings.newProductVariantId ||
          !currentProductOfferingSettings.originalProductVariantId)
      ) {
        addToast("Product offering require all fields to be fulfilled");
        return;
      }
      if (!isGrowActivePlan && !currentProductOfferingSettings.sequentialEndBehavior && isAdvancedSequential) {
        addToast("Missing end behavior");
        return;
      }
    }

    clearErrors();
    setIsSaving(true);

    const isExistingPlan = !!sellingPlanGroupInput.id;
    let sellingPlanGroupId = sellingPlanGroupInput.id;
    const numericSellingPlanGroupId = viewShopifyId(sellingPlanGroupId);
    const groupToSave = omit(sellingPlanGroupInput, ["id", "sellingPlans"]);

    try {
      if (isExistingPlan) {
        const { sellingPlansToCreate, sellingPlansToDelete, sellingPlansToUpdate } =
          determineSellingPlanOperations(sellingPlanInputs, initialSellingPlans);

        const res = await mutationShopifyUpdateSellingPlanGroup(
          sellingPlanGroupId!,
          {
            ...groupToSave,
            sellingPlansToCreate,
            sellingPlansToUpdate,
            sellingPlansToDelete: sellingPlansToDelete.map(p => p.id!),
          },
          apolloClient
        );

        if (res.type === "success") {
          if (res.body.data?.sellingPlanGroupUpdate?.userErrors.length) {
            setErrors(res.body.data?.sellingPlanGroupUpdate?.userErrors || []);
            setIsSaving(false);
            addToast(`Errors: \n${(res.body.data?.sellingPlanGroupUpdate?.userErrors || []).join("\n")}`);
            return;
          }

          const updatedSellingPlans =
            res.body.data?.sellingPlanGroupUpdate?.sellingPlanGroup?.sellingPlans.edges.map(({ node }) => node) ||
            [];
          const updatedSellingPlanInputs = updatedSellingPlans.map(sellingPlanToSellingPlanInput);
          syncSellingPlanGroup(addToast, numericSellingPlanGroupId, {
            reqBody: {
              prepaidPlanNumericIds,
              isSequentialPlan,
              terminalPlanNumericIds,
            },
          }).then(res => {
            if (res && res.type !== "success") {
              addToast("Database error, please try again");
            }
          });

          if (
            linkedProductIds &&
            setup.subscriptionSetup?.sellingPlan?.completed &&
            !setup.subscriptionSetup?.product?.completed &&
            setup.selectedSellingPlanId === sellingPlanGroupInput.id
          ) {
            handleUpdateSetup({
              ...setup,
              subscriptionSetup: {
                ...setup.subscriptionSetup,
                product: {
                  completed: true,
                  demo: false,
                  date: null,
                },
              },
            });
          }

          setSellingPlanInputs(updatedSellingPlanInputs);

          addToast("Program updated");

          onSaveCallback(sellingPlanGroupInput, updatedSellingPlanInputs);
        } else {
          addToast(
            `Errors updating subscription program: ${
              res.body.errors?.map(error => error.message).join(", ") || ""
            }`
          );
          setIsSaving(false);
        }
      } else {
        const res = await mutationShopifyCreateSellingPlanGroup(
          {
            ...groupToSave,
            sellingPlansToCreate: sellingPlanInputs.map(plan => ({
              ...plan,
              pricingPolicies: plan.pricingPolicies,
            })),
          },
          apolloClient
        );

        if (res.type === "success") {
          const { data } = res.body;
          if (data?.sellingPlanGroupCreate?.userErrors.length) {
            setErrors(data?.sellingPlanGroupCreate?.userErrors || []);
            setIsSaving(false);
            return;
          }

          sellingPlanGroupId = data?.sellingPlanGroupCreate?.sellingPlanGroup?.id!;

          const createdSellingPlans =
            res.body.data?.sellingPlanGroupCreate?.sellingPlanGroup?.sellingPlans.edges.map(({ node }) => node) ||
            [];
          const createdSellingPlansInputs = createdSellingPlans.map(sellingPlanToSellingPlanInput);

          onSaveCallback(
            {
              ...sellingPlanGroupInput,
              id: sellingPlanGroupId,
            },
            uniqBy([...createdSellingPlansInputs, ...sellingPlanInputs], el => el.name)
          );

          setSellingPlanInputs(sellingPlans =>
            uniqBy([...createdSellingPlansInputs, ...sellingPlans], el => el.name)
          );
          setSellingPlanGroupInput({ ...sellingPlanGroupInput, id: sellingPlanGroupId });

          if (!initialSellingPlanGroup?.id) {
            loadProductsAndVariants(sellingPlanGroupId).then(({ productIds, variantIds }) => {
              setProductIds(productIds);
              setVariantIds(variantIds);
            });
          }

          addToast("Program created");

          const sellingPlanIdNumber = sellingPlanGroupId.replaceAll(/^\D+/g, "");
          syncSellingPlanGroup(addToast, sellingPlanIdNumber, {
            reqBody: {
              prepaidPlanNumericIds,
              isSequentialPlan,
              terminalPlanNumericIds,
            },
          }).then(res => {
            if (res && res.type !== "success") {
              addToast("Database sync failed");
            }
          });
          if (isEmpty(sellingPlanGroups) && !setup?.subscriptionSetup?.sellingPlan.completed) {
            handleUpdateSetup({
              ...setup,
              selectedSellingPlanId: sellingPlanGroupId,
              subscriptionSetup: {
                ...setup.subscriptionSetup,
                sellingPlan: {
                  date: null,
                  completed: true,
                  demo: false,
                },
              },
            });
          }

          if (location.hash === "fromSetup") {
            handleUpdateSetup({
              selectedSellingPlanId: sellingPlanGroupId,
              subscriptionSetup: {
                sellingPlan: {
                  date: null,
                  completed: true,
                  demo: false,
                },
                ...setup.subscriptionSetup?.product,
              },
            });

            navigateWithShopInQuery(`${adminRoutePrefix}/setup`, {}, { replace: true });
          } else {
            navigateWithShopInQuery(
              `${adminConfigRoutePrefix}/plans/${sellingPlanIdNumber}`,
              {},
              { replace: true }
            );
          }
        }
      }

      if (
        didProductOfferingChange(productOfferingSettings, productOfferingSettingsRef.current) &&
        sellingPlanGroupId
      ) {
        await validateAndSaveProductOfferingSelection(sellingPlanGroupId, productOfferingSettings.id);
      }
    } catch (error) {
      let message;
      try {
        const userErrors: UserError[] = JSON.parse(error.message);
        message = userErrors.map(userError => userError.message).join(", ");
      } catch (error) {
        message = error.message;
      }
      addToast(`Error: ${message}`);
      setIsSaving(false);
    }

    setIsSaving(false);
  }, [
    sellingPlanInputs,
    sellingPlanGroupInput,
    initialSellingPlans,
    productOfferingSettings,
    onSaveCallback,
    validateAndSaveProductOfferingSelection,
    setup,
    sellingPlanGroups,
  ]);

  const onEditProducts = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroupInput) {
        return;
      }

      const groupId = sellingPlanGroupInput.id;
      if (!groupId) {
        addToast("Group does not have an id");
        return;
      }
      const toAdd = ids.filter(id => !productIds.includes(id));
      const toRemove = productIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addProductsToGroup(apolloClient, groupId, toAdd);
      }

      if (toRemove.length) {
        await removeProductsFromGroup(apolloClient, groupId, toRemove);
      }

      const productIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setProductIds(productIdsToSet);
      setLinkedProductIds(productIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroupInput, purchasables, productIds]
  );

  const onEditVariants = useCallback(
    async (ids: string[]) => {
      if (!sellingPlanGroupInput) {
        return;
      }

      const groupId = sellingPlanGroupInput.id;
      if (!groupId) {
        addToast("Group does not have an id");
        return;
      }
      const toAdd = ids.filter(id => !variantIds.includes(id));
      const toRemove = variantIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addVariantsToGroup(apolloClient, groupId, toAdd);
      }

      if (toRemove.length) {
        await removeVariantsFromGroup(apolloClient, groupId, toRemove);
      }

      const variantIdsToSet = (ids: string[]) => ids.filter(id => !toRemove.includes(id)).concat(toAdd);
      setVariantIds(variantIdsToSet);

      setShowProductOptionsModal(false);
    },
    [sellingPlanGroupInput, purchasables, variantIds]
  );

  const openInNewTab = async () => {
    for (const subscription of confirmationModalSubscriptions) {
      window.open(`${adminRoutePrefix}/subscriptions/${viewShopifyId(subscription.shopifyId)}`);
    }
  };

  const onDelete = useCallback(async () => {
    if (!isEmpty(bundleConfigs)) {
      setShowLinkedBundleModal(true);
      return;
    }
    addToast("Deleting program...");
    const res = await mutationShopifyDeleteSellingPlanGroup(sellingPlanGroupInput.id!, apolloClient);
    if (res.type === "success") {
      const numericGroupId = sellingPlanGroupInput.id!.replaceAll(/^\D+/g, "");
      await groupActions.fetchSellingPlanGroups(true);
      const dbSyncReq = await typedFrontendVendorApi.putReq(`/selling-plan-group/${numericGroupId}/delete`);

      if (dbSyncReq.type !== "success") {
        addToast("Database sync failed");
      }

      if (setup.selectedSellingPlanId === sellingPlanGroupInput.id) {
        handleUpdateSetup({
          ...setup,
          selectedSellingPlanId: "",
          subscriptionSetup: {
            sellingPlan: {
              date: null,
              completed: false,
              demo: false,
            },
            product: {
              completed: false,
              date: null,
              demo: false,
            },
          },
        });
      }

      navigateWithShopInQuery(`${adminConfigRoutePrefix}/plans`);

      addToast("Program deleted");
    }
  }, [sellingPlanGroupInput, bundleConfigs, setup]);

  const onDiscard = useCallback(async () => {
    setProductOfferingRefresher(state => state + 1);
    setSellingPlanGroupInput({ ...initialSellingPlanGroup });
    setSellingPlanInputs(initialSellingPlans.map(p => cloneDeep(p)));

    setIsProductOrVariantLinkedLoading(true);
    productOfferingSettingsRef.current.sequentialEndBehavior = productOfferingSettings.sequentialEndBehavior;
    productOfferingSettingsRef.current.sequentialProducts = productOfferingSettings.sequentialProducts;

    await onEditProducts(initialProductIds);
    await onEditVariants(initialVariantIds);

    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);
    await reloadDeliveryProfile();

    onDiscardCallback();
  }, [
    initialSellingPlanGroup,
    initialSellingPlans,
    onEditProducts,
    onEditVariants,
    initialProductIds,
    initialVariantIds,
    productOfferingSettings,
    sellingPlanGroupInput?.id,
    setup,
  ]);

  const onAddProductsAndVariantsClick = useCallback(async () => {
    if (sellingPlanGroupInput.id) {
      setShowProductOptionsModal(true);
      setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
    }
  }, [sellingPlanGroupInput, productIds, variantIds]);

  const findSequentialProduct = useCallback(
    (variantIds?: string[]) => {
      if (!variantIds) {
        return false;
      }

      return variantIds.some(id => {
        return productOfferingSettingsRef.current.sequentialProducts!.find(item => item === id);
      });
    },
    [productOfferingSettingsRef]
  );

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    const currentPoduct = purchasables.find(product => product.shopifyId === productId);
    const isSequentialProduct = findSequentialProduct(currentPoduct?.vnts?.map(vnt => vnt.id));
    if (isSequentialProduct) {
      setShowRemoveProductWarningModal(true);
      return;
    }

    setIsProductOrVariantLinkedLoading(true);
    setHasChanges(true);
    addToast("Please save to apply changes to products.");

    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    await onEditProducts(productIds.filter(id => id !== productId));
    await onEditVariants(variantIds.filter(id => !vntIds.includes(id)));

    const numericGroupId = sellingPlanGroupInput?.id?.replace(/^\D+/g, "");
    syncSellingPlanGroup(addToast, numericGroupId, {
      reqBody: {
        prepaidPlanNumericIds,
        isSequentialPlan,
        terminalPlanNumericIds,
      },
    });

    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);

    setReloadProductsPagination(true);

    await reloadDeliveryProfile();
  };

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setIsProductOrVariantLinkedLoading(true);

    await onEditProducts(modalSelectedProductOrVariantIds.filter(p => !p.includes("ProductVariant")));
    await onEditVariants(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    await paginatedVariantsQuery(null, true);
    setIsProductOrVariantLinkedLoading(false);

    const numericGroupId = sellingPlanGroupInput?.id?.replace(/^\D+/g, "");
    syncSellingPlanGroup(addToast, numericGroupId, {
      reqBody: {
        prepaidPlanNumericIds,
        isSequentialPlan,
        terminalPlanNumericIds,
      },
    });
    // Prompts user to save to make sure the changes are saved
    setHasChanges(true);
    addToast("Please save to apply changes to products.");
    setReloadProductsPagination(true);

    await reloadDeliveryProfile();
  }, [modalSelectedProductOrVariantIds, onEditProducts, onEditVariants]);

  const onSellingPlanGroupUpdate = useCallback(
    (key: string, value: any) => setSellingPlanGroupInput(group => ({ ...group, [key]: value })),
    []
  );

  const onDeleteSellingPlan = async index => {
    const subscriptionsForCurrentSellingPlan = await subscriptionsMatchingFilter({
      sellingPlanId: [sellingPlanInputs[index].id || ""],
    });
    if (subscriptionsForCurrentSellingPlan.length && sellingPlanInputs[index].id != undefined) {
      openConfirmationModal(
        "Are you sure you want to delete this plan?",
        () => () => deleteSellingPlan(index),
        subscriptionsForCurrentSellingPlan
      );
    } else {
      deleteSellingPlan(index);
    }
  };

  const onDeleteSellingPlanGroup = async () => {
    const subscriptionsForCurrentSellingPlanGroup = await subscriptionsMatchingFilter({
      sellingPlanId: sellingPlanInputs.map(item => item.id || ""), // All selling plans in the group
    });
    if (subscriptionsForCurrentSellingPlanGroup.length && sellingPlanGroupInput.id) {
      openConfirmationModal("Warning", () => onDelete, subscriptionsForCurrentSellingPlanGroup);
    } else {
      onDelete();
    }
  };

  const reloadDeliveryProfile = async () => {
    const res = await paginatedQueryShopifyDeliveryProfiles(apolloClient);

    const deliveryProfilesToUpdate = res.filter(profile =>
      profile.sellingPlanGroups.edges.some(spg => spg.node.id === sellingPlanGroupInput.id)
    );
    for (const deliveryProfile of deliveryProfilesToUpdate) {
      if (sellingPlanGroupInput.id) {
        await mutationShopifyDeliveryProfileUpdate(
          apolloClient,
          ensureShopifyGid("DeliveryProfile", deliveryProfile.id),
          { sellingPlanGroupsToDissociate: [sellingPlanGroupInput.id] }
        );
        await mutationShopifyDeliveryProfileUpdate(
          apolloClient,
          ensureShopifyGid("DeliveryProfile", deliveryProfile.id),
          { sellingPlanGroupsToAssociate: [sellingPlanGroupInput.id] }
        );
      }
    }
  };

  const closeModal = useCallback(() => setShowProductOptionsModal(false), []);
  const closeLinkedBundleModal = useCallback(() => setShowLinkedBundleModal(false), []);

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filteredPurchasablesSearchText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filteredPurchasablesSearchText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filteredPurchasablesSearchText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  const isCreatingNewSellingPlanGroup = isEqual(sellingPlanGroupInput, {
    ...emptySellingPlanGroup,
    appId: config.shopify.appId,
  });

  const DeleteBundleBanner: JSX.Element = (
    <Banner title="" status="critical">
      <p>
        {`You currently have bundles linked to this subscription program. This subscription program can’t be
            deleted until the following linked bundles are deleted:`}
      </p>
      &nbsp;
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {bundleConfigs.map(b => (
          <li style={{ color: "#2C6ECB" }} key={b.id}>
            <Link onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/${b.id}`)}>
              {b.name}
            </Link>
          </li>
        ))}
      </ul>
    </Banner>
  );

  const allProductsAndVariantIds = useMemo(() => {
    const allProductsAndVariants: string[] = [];
    const allVariantsFromProducts = flatten(purchasables.map(p => p.vnts || [])).filter(
      el => el.isActiveInShopify && !el.isDraftOrArchived
    );
    for (const prod of purchasables) {
      if (prod.isActiveInShopify && !prod.isDraftOrArchived) {
        allProductsAndVariants.push(prod.shopifyId!);
      }
    }
    for (const variant of allVariantsFromProducts) {
      allProductsAndVariants.push(variant.shopifyId!);
    }
    return allProductsAndVariants;
  }, [purchasables]);

  const selectAllProducts = useCallback(() => {
    if (modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length) {
      setModalSelectedProductOrVariantIds([]);
    } else {
      setModalSelectedProductOrVariantIds(allProductsAndVariantIds);
    }
  }, [allProductsAndVariantIds, modalSelectedProductOrVariantIds]);

  const sequentialGroupContext = useMemo((): SequentialGroupContextType => {
    return {
      isPrepaid: isPrepaidPlan,
      numericShopifyId: sellingPlanGroupInput.id ? shopifyGidToNumber(sellingPlanGroupInput.id) : 0,
      numberOfPlans: sellingPlanInputs.length,
      isCreatingNewProgram: isCreatingNewSellingPlanGroup,
    };
  }, [isPrepaidPlan, sellingPlanInputs, sellingPlanGroupInput.id, isCreatingNewSellingPlanGroup]);

  return (
    <SequentialGroupContext.Provider value={sequentialGroupContext}>
      <Page
        narrowWidth
        backAction={{
          content: "Return to all Subscription Programs",
          url: `${adminConfigRoutePrefix}/plans`,
        }}
        title={
          isCreatingNewSellingPlanGroup
            ? "Creating subscription program"
            : `Managing ${sellingPlanGroupInput.name}`
        }
      >
        {!!hasChanges && (
          <ContextualSaveBar
            message="Unsaved changes"
            discardAction={{
              content: "Discard",
              onAction: onDiscard,
            }}
            saveAction={{
              loading: isSaving,
              content: "Save",
              onAction: onSave,
            }}
          />
        )}
        <LegacyStack vertical>
          <SellingPlanGroup
            sellingPlanGroup={sellingPlanGroupInput!}
            sellingPlans={sellingPlanInputs}
            setPrepaidPlans={setPrepaidPlans}
            setTerminalPlans={setTerminalPlans}
            onSellingPlanGroupUpdate={onSellingPlanGroupUpdate}
            onDeleteSellingPlan={onDeleteSellingPlan}
            onUpdateSellingPlan={updateSellingPlan}
            setSellingPlanInputs={setSellingPlanInputs}
          />
          {!!sellingPlanGroupInput.id && (
            <LegacyStack vertical>
              <SellingPlanProducts
                purchasables={purchasables}
                sellingPlanGroupName={sellingPlanGroupInput?.name}
                linkedProductIds={linkedProductIds}
                linkedVariantIds={linkedVariantIds}
                productIds={productIds}
                variantIds={variantIds}
                isProductOrVariantLinkedLoading={isProductOrVariantLinkedLoading || areProductsLoading}
                onEditProductsOrVariantsClick={onAddProductsAndVariantsClick}
                handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
              />
              <SellingPlanBundle bundles={bundleConfigs} loading={isBundleConfigsLoading} />
              {!!linkedProductIds.length && !!hasOldSequential && (
                <SellingPlanSqnce
                  productOfferingRef={productOfferingSettingsRef}
                  productOfferingSettings={productOfferingSettings}
                  productOfferingRefresher={productOfferingRefresher}
                  productIds={productIds}
                  variantIds={variantIds}
                  linkedProductIds={linkedProductIds}
                  setSequentialPlan={setSequentialPlan}
                  setHasChanges={setHasChanges}
                  isPrepaidPlan={isPrepaidPlan}
                  setIsAdvancedSequential={setIsAdvancedSequential}
                />
              )}
              {hasOldSequential ? null : <SequentialOffering />}
            </LegacyStack>
          )}
          <Layout>
            {!!errors.length && (
              <Layout.Section>
                <Banner title="Errors occurred during submission" status="critical">
                  {errors.map(({ field, message }) => (
                    <p key={field?.join()}>{message}</p>
                  ))}
                </Banner>
              </Layout.Section>
            )}
          </Layout>
          <BottomButtonGroup isDeleteButton={Boolean(sellingPlanGroupInput.id)}>
            {!!sellingPlanGroupInput.id && (
              <Button outline destructive onClick={onDeleteSellingPlanGroup}>
                Delete program
              </Button>
            )}
            <Button id="admin-selling-plan-group__save" primary onClick={onSave}>
              Save
            </Button>
          </BottomButtonGroup>
        </LegacyStack>

        <BrowseProductsModal
          purchasableOptions={purchasableOptions.slice(
            pageNumber * MAX_PAGE_SIZE,
            pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
          )}
          selectedProductsAndVariants={modalSelectedProductOrVariantIds}
          searchText={filteredPurchasablesSearchText}
          open={showProductOptionsModal}
          onClose={isProductOrVariantLinkedLoading ? NO_OP_CALLBACK : closeModal}
          title={
            <LegacyStack vertical spacing="extraTight">
              <Text variant="headingLg" as="p">
                Browse products
              </Text>
              <p style={{ fontSize: "14px", lineHeight: "20px" }}>
                Select one or multiple products/variants to be included as part of this subscription program.
              </p>
            </LegacyStack>
          }
          primaryAction={useMemo(
            () => ({
              content: "Confirm",
              onAction: onEditProductsOrVariantsCb,
              loading: isProductOrVariantLinkedLoading,
            }),
            [onEditProductsOrVariantsCb, isProductOrVariantLinkedLoading]
          )}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: closeModal,
              disabled: isProductOrVariantLinkedLoading,
            },
          ]}
          footer={
            <PaginationContainer>
              <Pagination
                hasNext={totalPages > pageNumber + 1}
                hasPrevious={pageNumber != 0}
                label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
                onNext={() => setPageNumber(prev => prev + 1)}
                onPrevious={() => setPageNumber(prev => prev - 1)}
              />
            </PaginationContainer>
          }
          searchSuffix={
            !filteredPurchasablesSearchText && (
              <Button onClick={selectAllProducts} plain>
                {modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length
                  ? "deselect all"
                  : "or select all"}
              </Button>
            )
          }
          onSearchChange={text => {
            setFilteredPurchasablesSearchText(text);
            //reset page on filter change to avoid blank card
            setPageNumber(0);
          }}
          displayWarningOnSequentialProduct={variantIds => {
            const isSequentialProduct = findSequentialProduct(variantIds);
            if (isSequentialProduct) {
              setShowRemoveProductWarningModal(true);
            }
          }}
          setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
        />
        <Modal
          title="Warning"
          open={showLinkedBundleModal}
          onClose={closeLinkedBundleModal}
          primaryAction={{
            content: "Go Back",
            onAction: closeLinkedBundleModal,
          }}
          secondaryActions={[
            {
              content: "Delete Program",
              onAction: closeLinkedBundleModal,
              disabled: true,
            },
          ]}
        >
          <Modal.Section>{DeleteBundleBanner}</Modal.Section>
        </Modal>
        <Modal
          title="Warning"
          open={showRemoveProductWarningModal}
          onClose={closeRemoveProductWarningModal}
          primaryAction={{
            content: "Go Back",
            onAction: closeRemoveProductWarningModal,
          }}
        >
          <Modal.Section>
            <Banner title="" status="critical">
              <Text variant="bodyMd" as="p">
                You currently have an advanced sequential program linked to this product. This product can&apos;t
                be removed from the subscription program until it has been removed from the advanced sequential
                settings.
              </Text>
            </Banner>
          </Modal.Section>
        </Modal>
        <RemovalConfirmationModal
          program={true}
          hasBundles={!isEmpty(bundleConfigs)}
          title={confirmationModalTitle}
          onClose={closeConfirmationModal}
          onConfirm={() => {
            confirmationModalFunction();
            setConfirmationModalOpen(false);
          }}
          open={confirmationModalOpen}
        >
          <div>
            {!isEmpty(bundleConfigs) && DeleteBundleBanner}
            <Banner
              title=""
              action={{ content: "Open all in new tabs", onAction: openInNewTab }}
              status="warning"
            >
              <p>
                {`You currently have customers using this subscription program. They’ll still continue to receive
          deliveries, but won’t be able to make any changes to their subscription from their Account
          Portal.`}
              </p>
              &nbsp;
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                {confirmationModalSubscriptions.map(item => {
                  return (
                    <li style={{ color: "#2C6ECB" }} key={item.shopifyId}>
                      <Link
                        onClick={() =>
                          frontEndTabOpen(`${adminRoutePrefix}/subscriptions/${viewShopifyId(item.shopifyId)}`)
                        }
                      >
                        {capitalize(item.custRel?.firstName)} {capitalize(item.custRel?.lastName)} - #
                        {viewShopifyId(item.shopifyId)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Banner>
          </div>
        </RemovalConfirmationModal>
      </Page>
    </SequentialGroupContext.Provider>
  );
}
