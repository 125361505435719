import { EditSequentialAccess } from "../../../SequentialStore";
import { useMemo } from "react";
import { IPurchaseState, PurchaseStateAndDelivery } from "@smartrr/shared/entities/PurchaseState";
import { sequentialWalker } from "@smartrr/shared/utils/sequentialWalker";
import { SequentialForm } from "../../../SequentialStore/form";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

const getNumberOfOrdersToShow = (end: SequentialForm.Sequential.Type["endAction"]) => {
  switch (end.actionType) {
    case "END": {
      return end.orderNumber;
    }
    case "REPEAT": {
      return end.orderNumber;
    }
    case "RESTART": {
      const loopSize = end.orderNumber - end.restartFromSequenceNumber + 1;
      return end.orderNumber + loopSize;
    }
  }
  return 1;
};

/**
 * Generates a set of incomplete deliveries that can be used to preview a sequential
 */
export const useGeneratePreviewDeliveries = (): PurchaseStateAndDelivery[] => {
  const errors = EditSequentialAccess.useErrors();
  const initialVariant = EditSequentialAccess.useVariant();
  const endAction = EditSequentialAccess.useEndAction();
  const sequence = EditSequentialAccess.useSequence();

  return useMemo(() => {
    if (errors.length > 0) {
      return [];
    }
    const initialOrder: PurchaseStateAndDelivery = {
      indexFromNext: 0,
      purchaseState: {
        purchaseStateStatus: "ACTIVE",
        discounts: [],
        stLineItems: [
          {
            pricingPolicy: null,
            discounts: [],
            vnt: initialVariant!,
            currentSequenceNumber: 0,
            sequential: {
              id: "1234",
              groupId: "1234",
              endAction: endAction as SequentialApi.Sequential.Type["endAction"],
              sequence: sequence as SequentialApi.Sequential.Type["sequence"],
              initialVariant: initialVariant!,
              vendorInfo: null,
            },
          } satisfies Partial<IPurchaseStateLineItem> as unknown as IPurchaseStateLineItem,
        ],
      } satisfies Partial<IPurchaseState> as unknown as IPurchaseState,
    } satisfies Partial<PurchaseStateAndDelivery> as unknown as PurchaseStateAndDelivery;

    const deliveries: PurchaseStateAndDelivery[] = [];

    const numOrdersToShow = getNumberOfOrdersToShow(endAction);

    for (let index = 0; index < numOrdersToShow; index++) {
      deliveries.push({ ...initialOrder, indexFromNext: index });
    }

    return sequentialWalker(deliveries);
  }, [initialVariant, endAction, errors]);
};
