import { Modal } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import React from "react";
import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    padding: 4px;
  }
`;

interface RemovalConfirmationModalProps {
  program?: boolean;
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  hasBundles?: boolean;
  children?: React.ReactNode;
}

export const RemovalConfirmationModal: React.FC<RemovalConfirmationModalProps> = ({
  onClose,
  onConfirm,
  children,
  program,
  hasBundles,
  ...props
}) => {
  return (
    <Modal
      {...props}
      onClose={onClose}
      primaryAction={{
        content: program ? "Go Back" : "Yes",
        onAction: program ? onClose : onConfirm,
      }}
      secondaryActions={[
        {
          destructive: true,
          content: program ? "Delete Program" : "No",
          onAction: onConfirm,
          disabled: !!hasBundles,
        },
      ]}
    >
      <Box className="removal-confirmation" p={2}>
        <ModalContent>{children}</ModalContent>
      </Box>
    </Modal>
  );
};
