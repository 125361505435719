import React from "react";
import styled from "styled-components";

interface LinearGradientProps {
  children: React.ReactNode;
}

const LinearGradient = styled.div`
  background: linear-gradient(#babec3ff, #babec300);
  padding-left: 3px;
  min-height: 300px;
`;

const LinearGradientInner = styled.div`
  background: #ffffff;
  padding-left: 1rem;
  min-height: 300px;
`;

export const SequentialLinearGradient = ({ children }: LinearGradientProps) => {
  return (
    <LinearGradient>
      <LinearGradientInner>{children}</LinearGradientInner>
    </LinearGradient>
  );
};
