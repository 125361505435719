import { SearchFormStoreFactory } from "@smartrr/shared/components/SearchFormStoreFactory";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const PRODUCTS_PER_PAGE = 10;
export const SequentialProductSearch = SearchFormStoreFactory<
  {
    groupId: string;
    perPage: number;
  },
  SequentialApi.ProductWithSequentialId.Type
>(
  { groupId: "", perPage: PRODUCTS_PER_PAGE },
  async (dynamic, page, search) => {
    const response = await typedFrontendVendorApi.getReq("/sequentials/products", {
      query: {
        limit: PRODUCTS_PER_PAGE,
        offset: PRODUCTS_PER_PAGE * (page - 1),
        groupId: dynamic.groupId,
        purchasableName: search,
      },
    });
    if (response.type === "success") {
      return {
        count: response.body.products.count,
        data: response.body.products.data.map(product => {
          return {
            ...product,
            variants: product.variants.map(variant => {
              return {
                ...variant,
                sequentialId: response.body.variantIdToSequentialIdMap[variant.id] ?? null,
              };
            }),
          } satisfies SequentialApi.ProductWithSequentialId.Type;
        }),
      };
    }
    return {
      count: 0,
      data: [],
    };
  },
  {
    scrollType: "pagination",
  }
);
