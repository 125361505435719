import { Button, FormLayout, LegacyStack, Select, Text, TextField } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
import {
  SellingPlanAnchorInput,
  SellingPlanAnchorType,
  SellingPlanInterval,
} from "@smartrr/shared/shopifyGraphQL/api";
import { daysInMonthNoLeapYear } from "@smartrr/shared/utils/dateUtils";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const MonthOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const WeekdayOptions = [
  { label: "Sunday", value: "7" },
  { label: "Monday", value: "1" },
  { label: "Tuesday", value: "2" },
  { label: "Wednesday", value: "3" },
  { label: "Thursday", value: "4" },
  { label: "Friday", value: "5" },
  { label: "Saturday", value: "6" },
];

const AnchorTypeNone = "NONE";

interface Props {
  sellingPlanNumber?: number;
  anchors: Array<SellingPlanAnchorInput>;
  intervalType: SellingPlanInterval;

  onAnchorsUpdate(anchors: Array<SellingPlanAnchorInput>): void;
}

const DayInputContainer = styled.div`
  & .Polaris-LegacyStack__Item:first-child {
    flex-grow: 1;
  }
`;

function YearDayPicker({
  sellingPlanNumber,
  pickerNumber,
  anchor,
  deleteDisabled, // Disables delete when there's only single anchor
  onAnchorUpdate,
  onAnchorDelete,
}: {
  sellingPlanNumber: number;
  pickerNumber: number;
  anchor: SellingPlanAnchorInput;
  deleteDisabled: boolean;
  onAnchorUpdate(newAnchor: SellingPlanAnchorInput): void;
  onAnchorDelete(): void;
}): JSX.Element {
  const [maxDays, setMaxDays] = useState(31);

  // Update max days when month changes
  useEffect(() => {
    const month = anchor.month ?? 1;
    const days = daysInMonthNoLeapYear(month);
    setMaxDays(days);

    if ((anchor.day ?? 1) > days) {
      onAnchorUpdate({ ...anchor, day: days });
    }
  }, [anchor.day, anchor.month, onAnchorUpdate]);

  return (
    <LegacyStack distribution="fillEvenly">
      <Select
        id={`anchor-dates-month__${pickerNumber}__selling-plan-number_${sellingPlanNumber}`}
        label="Month of the year"
        labelHidden={true}
        options={MonthOptions}
        value={anchor.month?.toString() ?? "1"}
        onChange={monthVal => {
          const month = parseInt(monthVal, 10);
          if (month >= 1 && month <= 12) {
            onAnchorUpdate({ ...anchor, month });
          }
        }}
      />
      <DayInputContainer>
        <LegacyStack vertical={false}>
          <TextField
            id={`anchor-dates-day__${pickerNumber}__selling-plan-number_${sellingPlanNumber}`}
            autoComplete="off"
            label="Day of the month"
            labelHidden={true}
            type="number"
            min={1}
            max={maxDays}
            value={anchor.day?.toString() ?? "1"}
            onChange={dayVal => {
              const day = parseInt(dayVal, 10);
              if (day >= 1 && day <= maxDays) {
                onAnchorUpdate({ ...anchor, day });
              }
            }}
          />
          <Button
            id={`anchor-dates__delete-button__${pickerNumber}__selling-plan-number_${sellingPlanNumber}`}
            disabled={deleteDisabled}
            onClick={onAnchorDelete}
            icon={DeleteMinor}
          />
        </LegacyStack>
      </DayInputContainer>
    </LegacyStack>
  );
}

export function AnchorDatesForm({
  sellingPlanNumber = 0,
  anchors,
  intervalType,
  onAnchorsUpdate,
}: Props): JSX.Element {
  // All anchors have the same type, so we can take type of the first one
  const anchorType: SellingPlanAnchorType | "NONE" = anchors[0]?.type ?? AnchorTypeNone;

  const onAnchorTypeChanged = useCallback(
    (anchorType: string) => {
      switch (anchorType) {
        case AnchorTypeNone: {
          onAnchorsUpdate([]);

          break;
        }
        case SellingPlanAnchorType.Weekday: {
          onAnchorsUpdate([{ type: SellingPlanAnchorType.Weekday, day: 7 }]);

          break;
        }
        case SellingPlanAnchorType.Monthday: {
          onAnchorsUpdate([{ type: SellingPlanAnchorType.Monthday, day: 1 }]);

          break;
        }
        case SellingPlanAnchorType.Yearday: {
          onAnchorsUpdate([{ type: SellingPlanAnchorType.Yearday, day: 1, month: 1 }]);

          break;
        }
        // No default
      }
    },
    [intervalType]
  );

  const getAnchorOptionsBasedOnBilling = (intervalType: SellingPlanInterval) => {
    switch (intervalType) {
      case SellingPlanInterval.Week: {
        return [
          {
            label: "Week Day",
            value: SellingPlanAnchorType.Weekday,
          },
          {
            label: "Month Day",
            value: SellingPlanAnchorType.Monthday,
          },
          {
            label: "Year Day",
            value: SellingPlanAnchorType.Yearday,
          },
        ];
      }
      case SellingPlanInterval.Month: {
        return [
          {
            label: "Month Day",
            value: SellingPlanAnchorType.Monthday,
          },
          {
            label: "Year Day",
            value: SellingPlanAnchorType.Yearday,
          },
        ];
      }
      case SellingPlanInterval.Year: {
        return [
          {
            label: "Year Day",
            value: SellingPlanAnchorType.Yearday,
          },
        ];
      }
      default: {
        return [];
      }
    }
  };

  return (
    <FormLayout>
      <FormLayout.Group condensed>
        {intervalType !== SellingPlanInterval.Day && (
          <LegacyStack vertical spacing="baseTight">
            <Text variant="headingXs" as="h3">
              Anchor date
            </Text>
            <Select
              id={`anchor-dates-form__interval_${sellingPlanNumber}`}
              label="Interval"
              options={[
                {
                  label: "None",
                  value: AnchorTypeNone,
                },
                ...getAnchorOptionsBasedOnBilling(intervalType),
              ]}
              value={anchorType}
              onChange={anchorType => onAnchorTypeChanged(anchorType)}
            />
          </LegacyStack>
        )}

        {anchorType === SellingPlanAnchorType.Weekday && (
          <Select
            id={`anchor-dates-form__day-of-the-week_${sellingPlanNumber}`}
            label="Day of the week"
            options={WeekdayOptions}
            value={anchors[0].day?.toString() ?? "7"}
            onChange={weekday => {
              const day = parseInt(weekday, 10);
              onAnchorsUpdate([{ type: SellingPlanAnchorType.Weekday, day }]);
            }}
          />
        )}

        {anchorType === SellingPlanAnchorType.Monthday && (
          <TextField
            id={`anchor-dates-form__day-of-the-month_${sellingPlanNumber}`}
            autoComplete="off"
            label="Day of the month"
            type="number"
            min={1}
            max={31}
            value={anchors[0].day?.toString() ?? "1"}
            onChange={dayVal => {
              const day = parseInt(dayVal, 10);
              if (day >= 1 && day <= 31) {
                onAnchorsUpdate([{ type: SellingPlanAnchorType.Monthday, day }]);
              }
            }}
          />
        )}
      </FormLayout.Group>

      {anchorType === SellingPlanAnchorType.Yearday && (
        <LegacyStack vertical spacing="tight">
          {anchors.map((anchor, index) => (
            <YearDayPicker
              sellingPlanNumber={sellingPlanNumber}
              key={index}
              pickerNumber={index + 1}
              anchor={anchor}
              deleteDisabled={anchors.length <= 1}
              onAnchorUpdate={updatedAnchor =>
                onAnchorsUpdate([...anchors.slice(0, index), updatedAnchor, ...anchors.slice(index + 1)])
              }
              onAnchorDelete={() => onAnchorsUpdate([...anchors.slice(0, index), ...anchors.slice(index + 1)])}
            />
          ))}
          <Button
            id={`anchor-dates-form__add-date-button_${sellingPlanNumber}`}
            primary
            onClick={() =>
              onAnchorsUpdate([...anchors, { type: SellingPlanAnchorType.Yearday, day: 1, month: 1 }])
            }
          >
            Add date
          </Button>
        </LegacyStack>
      )}
    </FormLayout>
  );
}
