import { LegacyStack, Select, Text, TextField } from "@shopify/polaris";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { EditSequentialAccess } from "@vendor-app/app/_sharedComponents/Sequential/SequentialStore";
import { SequentialForm } from "@vendor-app/app/_sharedComponents/Sequential/SequentialStore/form";
import styled from "styled-components";

const DiscountType = styled.div`
  color: var(--p-color-text-emphasis);
  cursor: pointer;
`;

export const DiscountActionComponent = ({ sequence }: { sequence: SequentialForm.DiscountAction.Type }) => {
  const actions = EditSequentialAccess.useActions();

  const updateDiscountType = () => {
    if (sequence.discount.type === "FIXED") {
      actions.discount.setDiscount(sequence.id, "PERCENTAGE", sequence.discount.amount);
    } else {
      actions.discount.setDiscount(sequence.id, "FIXED", sequence.discount.amount);
    }
  };

  return (
    <div
      style={{
        paddingLeft: "20px",
      }}
    >
      <LegacyStack distribution="fillEvenly">
        <LegacyStack vertical spacing="extraTight">
          <LegacyStack distribution="equalSpacing" alignment="center">
            <Text as="p" fontWeight="bold" variant="bodyMd">
              Of
            </Text>
            <DiscountType onClick={updateDiscountType}>
              <span>{sequence.discount.type === "FIXED" ? "Fixed amount" : "Percentage"}</span>
            </DiscountType>
          </LegacyStack>
          <TextField
            suffix={sequence.discount.type === "FIXED" ? "$" : "%"}
            autoComplete="off"
            label="Set discount amount"
            labelHidden
            type="integer"
            min={0}
            max={sequence.discount.type === "FIXED" ? undefined : 100}
            value={`${sequence.discount.amount}`}
            onChange={val => {
              const amount = Number(val);
              actions.discount.setDiscount(sequence.id, sequence.discount.type, amount);
            }}
          />
        </LegacyStack>
        <LegacyStack vertical spacing="extraTight">
          <Text as="p" fontWeight="bold" variant="bodyMd">
            For
          </Text>
          <Select
            label="Choose discount Type"
            labelHidden
            value={sequence.orderType}
            options={
              [
                {
                  label: "All following orders",
                  value: "ALL",
                },
                {
                  label: "This order only",
                  value: "CURRENT",
                },
              ] satisfies {
                label: string;
                value: SequentialApi.Actions.Discount.OrderType;
              }[]
            }
            onChange={(val: SequentialApi.Actions.Discount.OrderType) => {
              switch (val) {
                case "ALL": {
                  actions.discount.setOrderType(sequence.id, "ALL");
                  break;
                }
                case "CURRENT": {
                  actions.discount.setOrderType(sequence.id, "CURRENT");
                  break;
                }
              }
            }}
          />
        </LegacyStack>
      </LegacyStack>
    </div>
  );
};
