import { Icon, LegacyStack, Select, Text, TextField, Tooltip } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { MIN_SEQUENTIAL_ORDER_NUMBER } from "@smartrr/shared/entities/Sequential";
import { clamp, omit } from "lodash";
import React, { useContext, useMemo } from "react";

import { EditSequentialAccess } from "../../../SequentialStore";
import { SequenceContext } from "../SequenceContext";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { CamelCaseSmartrrFlags, useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

const SequentialActionOptions: Record<
  SequentialApi.Sequential.SequenceType["actionType"],
  {
    label: string;
    value: SequentialApi.Sequential.SequenceType["actionType"];
    enableKey: keyof CamelCaseSmartrrFlags | null;
  }
> = {
  SWAP: {
    label: "Swap item",
    value: "SWAP",
    enableKey: null,
  },
  DISCOUNT: {
    label: "Add discount",
    value: "DISCOUNT",
    enableKey: null,
  },
};

const UpdateInfoTooltipContent = () => {
  return (
    <Text as="p" variant="bodyMd">
      <Text as="span" fontWeight="bold" variant="bodyMd">
        Tip:&nbsp;
      </Text>
      <span>
        Order #1 is considered the initial order the customer places in Shopify. Order #2 is considered the first
        recurring subscription order.
      </span>
    </Text>
  );
};

export const UpdateInfoTooltip = () => {
  return (
    <Tooltip content={<UpdateInfoTooltipContent />} dismissOnMouseOut>
      <Icon source={InfoMinor} color="subdued" />
    </Tooltip>
  );
};

export const UpdateSequencePreamble = () => {
  const sequence = useContext(SequenceContext);
  const actions = EditSequentialAccess.useActions();

  const enableFlags = useSmartrrFlags();

  const filteredSequentialOptions = useMemo(() => {
    return Object.values(SequentialActionOptions)
      .filter(opt => (opt.enableKey ? enableFlags[opt.enableKey] : true))
      .map(opt => omit(opt, "enableKey"));
  }, [enableFlags]);

  return (
    <LegacyStack distribution="fillEvenly">
      <LegacyStack vertical spacing="extraTight">
        <LegacyStack alignment="center" distribution="leading">
          <Text as="p" variant="bodyMd">
            <Text as="span" fontWeight="bold" variant="bodyMd">
              Wait&nbsp;
            </Text>
            <span>until after order #:</span>
          </Text>
          <div
            style={{
              marginLeft: "-15px",
            }}
          >
            <UpdateInfoTooltip />
          </div>
        </LegacyStack>
        <TextField
          label="Order No#"
          labelHidden
          type="integer"
          min={1}
          value={String(sequence.orderNumber)}
          onChange={val => {
            const newOrderNumber = clamp(Number(val), MIN_SEQUENTIAL_ORDER_NUMBER, 1000);
            actions.setOrderNumber(sequence.id, newOrderNumber);
          }}
          autoComplete="off"
        />
      </LegacyStack>
      <LegacyStack vertical spacing="extraTight">
        <Text as="p" variant="bodyMd">
          <Text as="span" fontWeight="bold" variant="bodyMd">
            Then&nbsp;
          </Text>
          <span>perform the following action: </span>
        </Text>
        <Select
          label="Choose Action Type: "
          labelHidden
          options={Object.values(filteredSequentialOptions)}
          value={sequence.actionType === "UNDECIDED" ? undefined : sequence.actionType}
          onChange={(val: SequentialApi.Sequential.SequenceType["actionType"]) => {
            switch (val) {
              case "SWAP": {
                actions.setSequentialSwap(sequence.id, sequence.orderNumber, null);
                break;
              }
              case "DISCOUNT": {
                actions.discount.set(sequence.id, sequence.orderNumber);
                break;
              }
              default: {
                break;
              }
            }
          }}
          placeholder="Select action"
        />
      </LegacyStack>
    </LegacyStack>
  );
};
