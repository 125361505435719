import { Button, HorizontalStack } from "@shopify/polaris";
import { EditSequentialAccess } from "../SequentialStore";
import { SequentialSection } from "../utils/SequentialSection";
import { useSequentialPreviewModal } from "./useSequentialPreviewModal";
import React from "react";
import { ActionButtons } from "../utils/ActionButtons";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import { SequentialFlowContainer } from "../FlowModal/SequentialFlowContainer";
import { SequentialFlowStart } from "../FlowModal/FlowStart";
import { SequentialLinearGradient } from "../FlowModal/SequentialLinearGradient";
import { SequentialFlowEndHeading } from "../FlowModal/FlowEnd";
import styled from "styled-components";
import { SequentialPreviewOrder } from "./SequentialPreviewOrder";
import { useGeneratePreviewDeliveries } from "./utils/useGeneratePreviewDeliveries";

const SequentialOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const SequentialPreviewContent = () => {
  const closePreviewModal = useSequentialPreviewModal(state => state.closeModal);
  const orders = useGeneratePreviewDeliveries();
  const endAction = EditSequentialAccess.useEndAction();

  return (
    <React.Fragment>
      <SequentialSection>
        <SequentialFlowContainer>
          <SequentialFlowStart />
          <SequentialLinearGradient>
            <SequentialOrdersContainer>
              {orders.map((order, index) => (
                <SequentialPreviewOrder delivery={order} key={index} />
              ))}
            </SequentialOrdersContainer>
          </SequentialLinearGradient>
          {endAction.actionType === "END" ? <SequentialFlowEndHeading /> : null}
        </SequentialFlowContainer>
      </SequentialSection>
      <SequentialSection border>
        <ActionButtons>
          <HorizontalStack blockAlign="center" align="start" gap="150">
            <Button icon={ArrowLeftMinor} onClick={() => closePreviewModal()} size="slim">
              Back
            </Button>
          </HorizontalStack>
        </ActionButtons>
      </SequentialSection>
    </React.Fragment>
  );
};

export const SequentialPreviewModal = () => {
  const errors = EditSequentialAccess.useErrors();
  const isSequentialPreviewModalOpen = useSequentialPreviewModal(state => state.isModalOpen);

  if (errors.length > 0 || !isSequentialPreviewModalOpen) {
    return null;
  }

  return <SequentialPreviewContent />;
};
