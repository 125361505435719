import { Icon, LegacyStack, Select, Text, TextField } from "@shopify/polaris";
import { StopMajor } from "@shopify/polaris-icons";
import { MIN_SEQUENTIAL_ORDER_NUMBER } from "@smartrr/shared/entities/Sequential";
import { CamelCaseSmartrrFlags, useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { clamp, omit } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { EditSequentialAccess } from "../../SequentialStore";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { UpdateInfoTooltip } from "../UpdateSequence/UpdateSequencePreamble";

const EndConditionContainer = styled.div`
  padding-left: 20px;

  .Polaris-LegacyStack__Item {
    min-width: 0;
  }

  > .Polaris-LegacyStack {
    > .Polaris-LegacyStack__Item:nth-child(1) {
      width: 40%;
      max-width: 40%;
      min-width: 40%;
    }
  }
`;

const FlowTextContainer = styled.div`
  & p.Polaris-Text--root {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const SequentialFlowEndHeading = () => {
  return (
    <LegacyStack spacing="extraTight">
      <Icon source={StopMajor} color="base" />
      <FlowTextContainer>
        <Text as="p" variant="bodyMd">
          FLOW END
        </Text>
      </FlowTextContainer>
    </LegacyStack>
  );
};

const SequentialEndOptions: Record<
  SequentialApi.Sequential.EndActionType["actionType"],
  {
    label: string;
    value: SequentialApi.Sequential.EndActionType["actionType"];
    enableKey: keyof CamelCaseSmartrrFlags | null;
  }
> = {
  REPEAT: {
    label: "Send last item in perpetuity",
    value: "REPEAT",
    enableKey: null,
  },
  END: {
    label: "End sequence flow",
    value: "END",
    enableKey: null,
  },
  RESTART: {
    label: "Loop sequence",
    value: "RESTART",
    enableKey: null,
  },
};

const SequentialFlowEndPreamble = () => {
  const actions = EditSequentialAccess.useActions();
  const endSequence = EditSequentialAccess.useEndAction();

  const enableFlags = useSmartrrFlags();

  const enabledEndOptions = useMemo(() => {
    return Object.values(SequentialEndOptions)
      .filter(opt => (opt.enableKey ? enableFlags[opt.enableKey] : true))
      .map(opt => omit(opt, "enableKey"));
  }, [enableFlags]);

  const handleEndBehaviorChange = (selected: SequentialApi.Sequential.EndActionType["actionType"]) => {
    switch (selected) {
      case "REPEAT": {
        actions.setEndSequentialAsRepeat();
        break;
      }
      case "END": {
        actions.setEndSequentialAsEnd(endSequence.orderNumber);
        break;
      }
      case "RESTART": {
        if (endSequence.actionType === "RESTART") {
          actions.setEndSequentialAsRestart(endSequence.orderNumber, endSequence.restartFromSequenceNumber);
        } else {
          actions.setEndSequentialAsRestart(endSequence.orderNumber, MIN_SEQUENTIAL_ORDER_NUMBER);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <LegacyStack vertical spacing="extraTight">
      <Text as="p" variant="bodyMd" fontWeight="bold">
        End behavior:
      </Text>
      <Select
        label="Select End Condition"
        labelHidden
        options={enabledEndOptions}
        value={endSequence.actionType === "UNDECIDED" ? undefined : endSequence.actionType}
        placeholder="Select end behavior"
        onChange={handleEndBehaviorChange}
      />
    </LegacyStack>
  );
};

export const SequentialFlowEnd = () => {
  const errors = EditSequentialAccess.useErrors();
  const isValidated = EditSequentialAccess.useIsValidated();

  const [showEndFlow, setShowEndFlow] = useState(false);

  useEffect(() => {
    const hasNonEndErrors = errors.some(error => error.location !== "end");
    setShowEndFlow(!hasNonEndErrors && isValidated);
  }, [errors, isValidated]);

  if (!showEndFlow) {
    return (
      <LegacyStack vertical>
        <SequentialFlowEndHeading />
      </LegacyStack>
    );
  }

  return <SequentialFlowEndContent />;
};

const SequentialEndAction_End = ({ endSequence }: { endSequence: SequentialApi.EndActions.End.Type }) => {
  const actions = EditSequentialAccess.useActions();

  const handleOrderNumberChange = (val: string) => {
    const newOrderNumber = clamp(Number(val), MIN_SEQUENTIAL_ORDER_NUMBER, 1000);
    actions.setEndSequentialAsEnd(newOrderNumber);
  };

  return (
    <LegacyStack vertical spacing="extraTight">
      <Text as="p" variant="bodyMd">
        <Text as="span" fontWeight="bold" variant="bodyMd">
          On&nbsp;
        </Text>
        <span>the following order #</span>
      </Text>
      <TextField
        label="Order No#"
        labelHidden
        min={1}
        type="integer"
        value={String(endSequence.orderNumber)}
        onChange={handleOrderNumberChange}
        autoComplete="off"
      />
    </LegacyStack>
  );
};

const SequentialEndAction_Repeat = ({}: { endSequence: SequentialApi.EndActions.Repeat.Type }) => {
  return <React.Fragment />;
};

const SequentialEndAction_Restart = ({ endSequence }: { endSequence: SequentialApi.EndActions.Restart.Type }) => {
  const actions = EditSequentialAccess.useActions();

  const handleOrderNumberChange = (val: string) => {
    const newOrderNumber = clamp(Number(val), MIN_SEQUENTIAL_ORDER_NUMBER, 1000);
    actions.setEndSequentialAsRestart(newOrderNumber, endSequence.restartFromSequenceNumber);
  };

  const handleRestartNumberChange = (val: string) => {
    const restartFromNumber = clamp(Number(val), MIN_SEQUENTIAL_ORDER_NUMBER, endSequence.orderNumber - 1);
    actions.setEndSequentialAsRestart(endSequence.orderNumber, restartFromNumber);
  };

  return (
    <LegacyStack distribution="fillEvenly" alignment="center">
      <LegacyStack vertical spacing="extraTight">
        <LegacyStack alignment="center" distribution="leading">
          <Text as="p" variant="bodyMd">
            <Text as="span" fontWeight="bold" variant="bodyMd">
              Between&nbsp;
            </Text>
            <span>order #:</span>
          </Text>
          <div
            style={{
              marginLeft: "-15px",
            }}
          >
            <UpdateInfoTooltip />
          </div>
        </LegacyStack>
        <TextField
          label="Order No#"
          labelHidden
          type="integer"
          min={1}
          value={String(endSequence.restartFromSequenceNumber ?? 1)}
          onChange={handleRestartNumberChange}
          autoComplete="off"
        />
      </LegacyStack>
      <LegacyStack vertical spacing="extraTight">
        <LegacyStack alignment="center" distribution="leading">
          <Text as="p" variant="bodyMd">
            <Text as="span" fontWeight="bold" variant="bodyMd">
              And&nbsp;
            </Text>
            <span>order #:</span>
          </Text>
          <div
            style={{
              marginLeft: "-15px",
            }}
          >
            <UpdateInfoTooltip />
          </div>
        </LegacyStack>
        <TextField
          label="Order No#"
          labelHidden
          min={1}
          type="integer"
          value={String(endSequence.orderNumber)}
          onChange={handleOrderNumberChange}
          autoComplete="off"
        />
      </LegacyStack>
    </LegacyStack>
  );
};

const SequenceEndActionSelector = () => {
  const endSequence = EditSequentialAccess.useEndAction();

  switch (endSequence.actionType) {
    case "END": {
      return <SequentialEndAction_End endSequence={endSequence} />;
    }
    case "REPEAT": {
      return <SequentialEndAction_Repeat endSequence={endSequence} />;
    }
    case "RESTART": {
      return <SequentialEndAction_Restart endSequence={endSequence} />;
    }
    case "UNDECIDED": {
      return <React.Fragment></React.Fragment>;
    }
  }
};

const SequentialFlowEndContent = () => {
  return (
    <LegacyStack vertical>
      <SequentialFlowEndHeading />
      <EndConditionContainer>
        <LegacyStack distribution="fillEvenly" alignment="center">
          <SequentialFlowEndPreamble />
          <SequenceEndActionSelector />
        </LegacyStack>
      </EndConditionContainer>
    </LegacyStack>
  );
};
