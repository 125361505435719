import { Button, LegacyStack } from "@shopify/polaris";
import React from "react";

import { ActionButtons } from "../../../utils/ActionButtons";

interface SequentialDeleteActionButtonsProps {
  secondary: {
    text: string;
    onAction: () => void;
  };
  primary: {
    text: string;
    onAction: () => void;
    disabled: boolean;
    loading: boolean;
    destructive: boolean;
  };
}
export const SequentialDeleteActionButtons = (info: SequentialDeleteActionButtonsProps) => {
  return (
    <ActionButtons>
      <LegacyStack distribution="trailing">
        <Button onClick={() => info.secondary.onAction()}>{info.secondary.text}</Button>
        <Button
          onClick={() => info.primary.onAction()}
          loading={info.primary.loading}
          destructive={info.primary.destructive}
          disabled={info.primary.disabled}
          primary={!info.primary.destructive}
        >
          {info.primary.text}
        </Button>
      </LegacyStack>
    </ActionButtons>
  );
};
