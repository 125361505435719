import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { DependencyList, useEffect } from "react";

interface SmartrrEvents {
  "smartrr.selling_plan_group.sequential.select_variant": {
    vnt: SequentialApi.SequentialVariantWithParent.Type;
    /**
     * For which sequential action the Browse Modal is open. "initial" = Initial Variant. 0+: Sequence Number
     */
    id: string;
  };
}

export const publishEvent = function <T extends keyof SmartrrEvents>(eventType: T, eventData: SmartrrEvents[T]) {
  const customEvent = new CustomEvent(eventType, { detail: eventData });

  window.dispatchEvent(customEvent);
};

const subscribeToEvent = function <T extends keyof SmartrrEvents>(
  eventType: T,
  eventHandle: (data: SmartrrEvents[T]) => void
) {
  const handleEvent = (event: Event) => {
    event.stopPropagation();
    if (event instanceof CustomEvent) {
      eventHandle(event.detail as SmartrrEvents[T]);
    }
  };

  window.addEventListener(eventType, handleEvent);

  return () => window.removeEventListener(eventType, handleEvent);
};

export const useSubscriptionToEvent = function <T extends keyof SmartrrEvents>(
  eventType: T,
  eventHandle: (data: SmartrrEvents[T]) => void,
  dependencyList: DependencyList
) {
  useEffect(() => {
    return subscribeToEvent(eventType, eventHandle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);
};
