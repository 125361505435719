import React, { useContext } from "react";

import { SwapActionComponent } from "../actions/SwapAction";
import { SequenceContext } from "../SequenceContext";
import { DiscountActionComponent } from "../actions/DiscountAction";

export const OrderActionComponent = () => {
  const sequence = useContext(SequenceContext);

  if (sequence.actionType === undefined) {
    return <React.Fragment />;
  }

  switch (sequence.actionType) {
    case "SWAP": {
      return <SwapActionComponent sequence={sequence} />;
    }
    case "DISCOUNT": {
      return <DiscountActionComponent sequence={sequence} />;
    }
    default: {
      return <React.Fragment />;
    }
  }
};
