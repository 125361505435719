import { Badge, Button, Icon, LegacyCard, LegacyStack, Link, Spinner, Text } from "@shopify/polaris";
import { ExternalSmallMinor, InfoMinor, LinkMinor } from "@shopify/polaris-icons";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import React from "react";
import styled from "styled-components";
import { v4 } from "uuid";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";

import { isFeatureAvailable } from "../../components/authorization/featureAccess";
import {
  LearnMoreAboutLink,
  LearnMoreIconWrapper,
  LearnMoreLinkContainer,
} from "../../components/elements/styles";

const TextWrapper = styled.div`
  .Polaris-Text--headingMd {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

interface Props {
  bundles: ISmartrrBundleConfig[];
  loading: boolean;
}

export const SellingPlanBundle = ({ bundles, loading }: Props) => {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const uniqueId = v4();

  const learnMoreLink = (
    <LegacyStack>
      <Icon source={InfoMinor} color="highlight" />
      <LearnMoreLinkContainer>
        Learn more about&nbsp;
        <LearnMoreAboutLink
          href="https://help.smartrr.com/docs/support/admin-portal/what-are-bundles"
          rel="noreferrer"
          target="_blank"
        >
          bundles
          <LearnMoreIconWrapper>
            <Icon source={ExternalSmallMinor} color="base" />
          </LearnMoreIconWrapper>
        </LearnMoreAboutLink>
      </LearnMoreLinkContainer>
    </LegacyStack>
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      {isFeatureAvailable(FeatureEnum.BUNDLES, user, activePlan) ? (
        <LegacyCard
          title={
            <LegacyStack distribution="equalSpacing">
              <TextWrapper>
                <Text variant="headingMd" as="h2">
                  Bundles
                </Text>
              </TextWrapper>
              <Badge status={bundles.length > 0 ? "success" : undefined} progress="complete">
                {`${bundles.length > 0 ? bundles.length : 0} bundle${bundles.length === 1 ? "" : "s"} linked`}
              </Badge>
            </LegacyStack>
          }
          sectioned
        >
          <LegacyStack vertical>
            {bundles.map((b, i) => (
              <div key={uniqueId}>
                <LegacyStack distribution="equalSpacing">
                  <LegacyStack spacing="extraTight">
                    <Icon source={LinkMinor} color="subdued" />
                    <Text variant="bodyMd" as="span" color="subdued">
                      {b.name}
                    </Text>
                  </LegacyStack>
                  <Link onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/${b.id}`)}>
                    View Bundle
                  </Link>
                </LegacyStack>
              </div>
            ))}
            {learnMoreLink}
          </LegacyStack>
        </LegacyCard>
      ) : (
        <LegacyCard sectioned>
          <LegacyStack alignment="center" distribution="equalSpacing">
            <TextWrapper>
              <Text variant="headingMd" as="h2">
                Bundles
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                Upgrade to 💎 Excel to access bundles.
              </Text>
            </TextWrapper>
            <Button primary url="/admin/smartrr-account">
              Upgrade
            </Button>
            {learnMoreLink}
          </LegacyStack>
        </LegacyCard>
      )}
    </div>
  );
};
