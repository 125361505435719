import { Button, LegacyCard, LegacyStack, ResourceList, Scrollable, Spinner, Text } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { useVariantToPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { differenceWith, flatten } from "lodash";
import React from "react";
import styled from "styled-components";

import { AddProductMajorSVG } from "@vendor-app/app/_sharedComponents/SVGs/AddProductMajorSVG";

import {
  SellingPlanGroupProductsListItem,
  SellingPlanGroupVariantToPurchaseableListItem,
} from "./SellingPlanGroupProductsListItem";
import { ILinkedVariantWithPurchaseableName } from "../constants";

interface Props {
  purchasables: IPurchasable[];
  sellingPlanGroupName: string | null | undefined;
  productIds: string[];
  variantIds: string[];
  linkedProductIds: string[];
  linkedVariantIds: string[];
  isProductOrVariantLinkedLoading: boolean;

  onEditProductsOrVariantsClick(): void;

  handleRemoveProductsAndVariants: (variantIds: string[], productId?: string) => void;
}

const CardWapper = styled.div`
  @media screen and (min-width: 490px) {
    .scrollable-wrapper {
      border-radius: 0 0 8px 8px;
      overflow: hidden;
    }
  }
  // Updating Thumbnail here instead of creating a new and unneeded wrapper
  .Polaris-Thumbnail {
    border-radius: 0.6rem;
    height: 45px;
    width: 45px;
  }
`;

const NoProductsContainer = styled.div`
  padding: 4rem 1rem;
  text-align: center;
`;

export function SellingPlanProducts({
  purchasables,
  linkedProductIds,
  linkedVariantIds,
  isProductOrVariantLinkedLoading,
  onEditProductsOrVariantsClick,
  handleRemoveProductsAndVariants,
  productIds,
  variantIds,
}: Props) {
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const linkedProducts = filterNil(
    linkedProductIds
      .filter(productId => productIds.includes(productId))
      .map(productId => purchasables.find(purch => purch.shopifyId === productId))
  );

  const linkedVariantsToShow = filterNil(
    differenceWith(
      linkedVariantIds,
      flatten(purchasables.filter(purch => productIds.includes(purch.shopifyId!)).map(purch => purch.vnts)),
      (variantId, purch) => purch?.shopifyId === variantId
    )
  );

  const linkedVariantsToPurchasable: ILinkedVariantWithPurchaseableName[] = filterNil(
    flatten(
      purchasables.map(purch =>
        purch.vnts?.map(vnt => ({
          ...vnt,
          purchasableName: purch.purchasableName,
        }))
      )
    ).filter(purch => linkedVariantsToShow.includes(purch?.shopifyId!))
  );

  return (
    <CardWapper>
      <LegacyCard>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="equalSpacing">
            <Text id="selling-plan-group__products-list-header" variant="headingMd" as="h2">
              Products
            </Text>
            <Button onClick={onEditProductsOrVariantsClick}>Browse</Button>
          </LegacyStack>
        </LegacyCard.Section>
        <div className="scrollable-wrapper">
          <Scrollable
            vertical
            hint
            shadow
            style={{
              minHeight: "350px",
              maxHeight: "350px",
              position: "relative",
            }}
          >
            {isProductOrVariantLinkedLoading ? (
              <LegacyStack distribution="center" alignment="center">
                <Spinner />
              </LegacyStack>
            ) : (
              <React.Fragment>
                {!linkedProducts.length && !linkedVariantsToPurchasable.length ? (
                  <NoProductsContainer>
                    <LegacyStack vertical spacing="baseTight" alignment="center" distribution="center">
                      <AddProductMajorSVG />
                      <Text variant="headingLg" as="p">
                        There are no products in this subscription program
                      </Text>
                      <Text variant="bodyMd" as="span" color="subdued">
                        Browse to add products and variants
                      </Text>
                    </LegacyStack>
                  </NoProductsContainer>
                ) : (
                  <React.Fragment>
                    <ResourceList
                      items={linkedProducts}
                      renderItem={purchasable => (
                        <SellingPlanGroupProductsListItem
                          purchasable={purchasable}
                          variantIds={variantIds}
                          variantToPurchasableMap={variantToPurchasableMap}
                          handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                          isProductOrVariantLinkedLoading={isProductOrVariantLinkedLoading}
                        />
                      )}
                    />
                    <ResourceList
                      items={linkedVariantsToPurchasable}
                      renderItem={(variant, _, index) => (
                        <SellingPlanGroupVariantToPurchaseableListItem
                          index={index}
                          variant={variant}
                          variantToPurchasableMap={variantToPurchasableMap}
                          linkedProducts={linkedProducts}
                          handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                          isProductOrVariantLinkedLoading={isProductOrVariantLinkedLoading}
                        />
                      )}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Scrollable>
        </div>
      </LegacyCard>
    </CardWapper>
  );
}
