import {
  Button,
  FormLayout,
  Icon,
  LegacyCard,
  LegacyStack,
  Modal,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  Thumbnail,
} from "@shopify/polaris";
import { LockMinor } from "@shopify/polaris-icons";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { flatten } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
  usePurchasableVariants,
  useVendorPortalVariantToPurchasableMap,
} from "@vendor-app/app/_state/reducers/purchasables";

import { ToggleSwitch } from "../../../_sharedComponents/ToggleSwitch/ToggleSwitch";
import { useSmartrrVendorSelector } from "../../../_state/typedVendorReduxHooks";
import { isFeatureAvailable } from "../../components/authorization/featureAccess";
import { ProductOfferingSettings } from "../models";
import { changeNumber, didProductOfferingChange, getSelectedVariantName, getVariantName } from "../utils";

interface Props {
  productOfferingRef: React.MutableRefObject<ProductOfferingSettings>;
  setHasChanges: (newCursor: boolean) => void;
  initialSettings: ProductOfferingSettings;
  allProducts: IPurchasable[];
  allVariants: IPurchasableVariant[];
  setSequentialPlan: React.Dispatch<React.SetStateAction<boolean>>;
}

type ModalState = "closed" | "initial" | "subsequent";
type StringifiedBoolean = "true" | "false";

const TextFieldWithButtonContainer = styled.div`
  & .Polaris-LegacyStack__Item:first-child {
    flex-grow: 1;
  }

  & .Polaris-TextField__Input:disabled {
    -webkit-text-fill-color: #6d7175;
    color: #6d7175;
  }

  & .Polaris-TextField--hasValue {
    -webkit-text-fill-color: #202223;
    color: #202223;
  }
`;

const HeaderWrapper = styled.div`
  .Polaris-Text--headingMd {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export function ProductOffering({
  productOfferingRef,
  setHasChanges,
  initialSettings,
  allProducts,
  allVariants,
  setSequentialPlan,
}: Props): JSX.Element {
  const [isConvertable, setConvertable] = useState<StringifiedBoolean>(
    initialSettings.exists.toString() as StringifiedBoolean
  );
  const [isVisible, setVisible] = useState(initialSettings.exists);
  const [isActive, setActive] = useState(initialSettings.isActive);
  const [orderNumber, setOrderNumber] = useState(initialSettings.orderNumber);
  const [initialProductId, setInitialProductId] = useState(initialSettings.originalProductVariantId);
  const [subsequentProductId, setSubsequentProductId] = useState(initialSettings.newProductVariantId);

  const [modalState, setModalState] = useState<ModalState>("closed");
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const allPurchasableVariants = usePurchasableVariants();

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  const isSequentialSellingPlanAvailable = isFeatureAvailable(
    FeatureEnum.SEQUENTIAL_SELLING_PLAN,
    user,
    activePlan
  );

  const onNumberChange = (newValue: string) => {
    changeNumber(newValue, setOrderNumber, productOfferingRef);
    setHasChanges(didProductOfferingChange(initialSettings, productOfferingRef.current));
  };

  const onItemSelect = (selectedItems: string[]) => {
    const val = selectedItems[1];
    if (!val) {
      return;
    }

    if (modalState === "initial") {
      setInitialProductId(val);
      productOfferingRef.current.originalProductVariantId = val;

      if (val === subsequentProductId) {
        setSubsequentProductId("");
        productOfferingRef.current.newProductVariantId = "";
      }
    } else {
      setSubsequentProductId(val);
      productOfferingRef.current.newProductVariantId = val;
    }

    setHasChanges(didProductOfferingChange(initialSettings, productOfferingRef.current));
  };

  const onProductOfferingSelectChange = (value: string) => {
    const isVisible = value === "true";
    setConvertable(value as StringifiedBoolean);
    setVisible(isVisible);
    productOfferingRef.current.exists = isVisible;

    if (isVisible) {
      setActive(true);
      productOfferingRef.current.isActive = true;
    }
    setHasChanges(didProductOfferingChange(initialSettings, productOfferingRef.current));
  };
  const allAvailableVariantsFromProducts = flatten(allProducts.map(p => p.vnts || [])).filter(
    el => el.isActiveInShopify && !el.isDraftOrArchived
  );

  const allAvailableVariants = [...new Set([...allAvailableVariantsFromProducts, ...allVariants])];
  const availableVariants =
    modalState === "subsequent"
      ? allAvailableVariants.filter(variant => variant.id !== initialProductId)
      : allAvailableVariants;

  const initialProductName = getSelectedVariantName(
    allPurchasableVariants,
    variantToPurchasableMap,
    initialProductId
  );
  const newProductName = getSelectedVariantName(
    allPurchasableVariants,
    variantToPurchasableMap,
    subsequentProductId
  );

  return (
    <LegacyCard
      sectioned
      title={
        <LegacyStack alignment="center" distribution="equalSpacing">
          <HeaderWrapper>
            <Text variant="headingMd" as="h2">
              Sequential Products
            </Text>
          </HeaderWrapper>
          {isSequentialSellingPlanAvailable ? (
            <React.Fragment>
              {!!allAvailableVariants.length && (
                <ToggleSwitch
                  toggled={isConvertable === "true"}
                  onClick={toggled => {
                    onProductOfferingSelectChange(toggled ? "true" : "false");
                    setSequentialPlan(isSequential => (isSequential = toggled));
                  }}
                />
              )}
            </React.Fragment>
          ) : (
            <Icon source={LockMinor} color="subdued" />
          )}
        </LegacyStack>
      }
    >
      <LegacyStack>
        {allAvailableVariants.length ? (
          <FormLayout>
            <Text variant="bodyMd" as="span" color="subdued">
              Determine how your products are converted throughout a subscription lifecycle by enabling a sequence
              of products after a certain number of deliveries.
            </Text>
            {!!isVisible && (
              <FormLayout.Group condensed>
                <TextField
                  autoComplete="off"
                  disabled={!isActive}
                  type="number"
                  value={orderNumber.toString()}
                  onChange={onNumberChange}
                  min={1}
                  pattern="[1-9][0-9]*$"
                  label="Change product after order #"
                />
                <div />
              </FormLayout.Group>
            )}
            {!!isVisible && (
              <TextFieldWithButtonContainer>
                <LegacyStack distribution="equalSpacing" alignment="trailing">
                  <TextField
                    autoComplete="off"
                    label="Initial product"
                    placeholder="Select one"
                    value={initialProductName}
                    disabled
                  />
                  <Button onClick={() => setModalState("initial")} disabled={!isActive}>
                    Browse
                  </Button>
                </LegacyStack>
              </TextFieldWithButtonContainer>
            )}
            {!!isVisible && (
              <TextFieldWithButtonContainer>
                <LegacyStack distribution="equalSpacing" alignment="trailing">
                  <TextField
                    autoComplete="off"
                    label="Subsequent product"
                    placeholder="Select one"
                    value={newProductName}
                    disabled
                  />
                  <Button onClick={() => setModalState("subsequent")} disabled={!isActive || !initialProductId}>
                    Browse
                  </Button>
                </LegacyStack>
              </TextFieldWithButtonContainer>
            )}
          </FormLayout>
        ) : (
          <Text variant="bodyMd" as="span" color="subdued">
            Add products to this subscription program to manage sequential settings.
          </Text>
        )}
        {/*

        Document is currently still pending release of advanced sequential

        <Stack>
          <Icon source={InfoMinor} color="highlight" />
          <Text variant="bodyMd" as="span">
            Learn more about{" "}
            <Link external url="https://help.smartrr.com/docs">
              sequential products
            </Link>
          </Text>
        </Stack> */}
      </LegacyStack>
      <Modal
        title={modalState === "initial" ? "Select product for replacement" : "Select subsequent product"}
        open={modalState !== "closed"}
        onClose={() => setModalState("closed")}
        secondaryActions={[
          useMemo(
            () => ({
              content: "Select Product",
              onAction: () => setModalState("closed"),
              disabled: modalState === "initial" ? !initialProductId : !subsequentProductId,
            }),
            [modalState, initialProductId, subsequentProductId]
          ),
        ]}
      >
        <Modal.Section>
          <ResourceList
            items={availableVariants}
            selectedItems={[modalState === "initial" ? initialProductId : subsequentProductId]}
            onSelectionChange={onItemSelect}
            renderItem={variant => {
              const purchasable = variantToPurchasableMap[variant.id];
              const imageUrl =
                variant.purchasableVariantSmallImages?.[0] || purchasable.purchasableSmallImages?.[0];
              const name = getVariantName(purchasable, variant);

              return (
                <ResourceItem
                  id={variant.id}
                  key={variant.id}
                  verticalAlignment="center"
                  onClick={NO_OP_CALLBACK}
                  media={
                    <div className="selling-plan-thumbnail-wrapper">
                      <Thumbnail source={imageUrl as string} alt={name} />
                    </div>
                  }
                >
                  <div>
                    <Text variant="bodyMd" as="span" fontWeight="semibold">
                      {name}
                    </Text>
                  </div>
                  <div>Shopify ID: {viewShopifyId(variant.shopifyId)}</div>
                </ResourceItem>
              );
            }}
          />
        </Modal.Section>
      </Modal>
    </LegacyCard>
  );
}
