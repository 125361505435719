import {
  Banner,
  Button,
  LegacyCard,
  LegacyStack,
  Modal,
  Pagination,
  ResourceList,
  Scrollable,
  Spinner,
  Text,
} from "@shopify/polaris";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { useVariantToPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { flatten } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { AddProductMajorSVG } from "@vendor-app/app/_sharedComponents/SVGs/AddProductMajorSVG";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  SellingPlanGroupProductsListItem,
  SellingPlanGroupVariantToPurchaseableListItem,
} from "./SellingPlanGroupProductsListItem";
import { PaginationContainer } from "../../AdminSubscriptionDetailsRoute/libs";
import { ILinkedVariantWithPurchaseableName } from "../constants";

interface Props {
  sellingPlanGroupName: string | null | undefined;
  productIds: string[];
  variantIds: string[];
  setVariantIds: (ids: string[]) => void;
  setProductIds: (ids: string[]) => void;
  reloadDeliveryProfile: () => void;
}

const CardWrapper = styled.div`
  @media screen and (min-width: 490px) {
    .scrollable-wrapper {
      border-radius: 0 0 8px 8px;
      overflow: hidden;
    }
  }
  // Updating Thumbnail here instead of creating a new and unneeded wrapper
  .Polaris-Thumbnail {
    border-radius: 0.6rem;
    height: 45px;
    width: 45px;
  }
`;

const NoProductsContainer = styled.div`
  padding: 4rem 1rem;
  text-align: center;
`;

export function SellingPlanProducts({
  productIds,
  variantIds,
  setProductIds,
  setVariantIds,
  reloadDeliveryProfile,
}: Props) {
  const {
    purchasables,
    isLoading: isPurchasablesLoading,
    isLoadingAdditionaly: isPurchasablesLoadingAdditionaly,
  } = useSmartrrVendorSelector(state => state.purchasables);
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");
  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [showRemoveProductWarningModal, setShowRemoveProductWarningModal] = useState<boolean>(false);

  const openRemoveProductWarningModal = useCallback(() => setShowRemoveProductWarningModal(true), []);
  const closeRemoveProductWarningModal = useCallback(() => setShowRemoveProductWarningModal(false), []);
  const closeProductsModal = useCallback(() => setShowProductOptionsModal(false), []);

  const linkedProducts = filterNil(
    productIds.map(productId => purchasables.find(purch => purch?.shopifyId && purch.shopifyId === productId))
  );

  const linkedVariantsToPurchasable: ILinkedVariantWithPurchaseableName[] = filterNil(
    flatten(
      purchasables.reduce<any[]>((arr, purch) => {
        if (purch.shopifyId && !productIds.includes(purch.shopifyId) && purch.vnts?.length) {
          arr.push(
            purch.vnts?.map(vnt => ({
              ...vnt,
              purchasableName: purch.purchasableName,
            }))
          );
        }
        return arr;
      }, [])
    ).filter(purch => purch?.shopifyId && variantIds.includes(purch.shopifyId))
  );

  const allProductsAndVariantIds = useMemo(() => {
    return purchasables.reduce<string[]>((acc, prod) => {
      if (prod.shopifyId && prod.isActiveInShopify && !prod.isDraftOrArchived) {
        acc.push(prod.shopifyId);
      }
      acc.push(
        ...(prod.vnts ?? [])
          .filter(vnt => vnt?.isActiveInShopify && !vnt.isDraftOrArchived && Boolean(vnt.shopifyId))
          .map(vnt => vnt.shopifyId!)
      );
      return acc;
    }, []);
  }, [purchasables]);

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables
      .filter(p => Boolean(p.shopifyId) && !p.isDraftOrArchived)
      .sort();

    if (filteredPurchasablesSearchText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filteredPurchasablesSearchText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filteredPurchasablesSearchText]);

  const totalPages = useMemo(
    () => Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE),
    [purchasableOptions.length]
  );

  const onEditProductsOrVariantsClick = useCallback(async () => {
    setShowProductOptionsModal(true);
    setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
  }, [productIds, variantIds]);

  const selectAllProducts = useCallback(() => {
    if (modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length) {
      setModalSelectedProductOrVariantIds([]);
    } else {
      setModalSelectedProductOrVariantIds(allProductsAndVariantIds);
    }
  }, [allProductsAndVariantIds, modalSelectedProductOrVariantIds.length]);

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    setProductIds(productIds.filter(id => id !== productId));
    setVariantIds(variantIds.filter(id => !vntIds.includes(id)));

    await reloadDeliveryProfile();
  };

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setProductIds(modalSelectedProductOrVariantIds.filter(p => p.includes("Product/")));
    setVariantIds(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    setShowProductOptionsModal(false);
    await reloadDeliveryProfile();
  }, [modalSelectedProductOrVariantIds]);

  return (
    <CardWrapper>
      <LegacyCard>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="equalSpacing">
            <Text id="selling-plan-group__products-list-header" variant="headingMd" as="h2">
              Products
            </Text>
            <Button onClick={onEditProductsOrVariantsClick}>Browse</Button>
          </LegacyStack>
        </LegacyCard.Section>
        <div className="scrollable-wrapper">
          <Scrollable
            vertical
            hint
            shadow
            style={{
              minHeight: "350px",
              maxHeight: "350px",
              position: "relative",
            }}
          >
            {isPurchasablesLoadingAdditionaly || isPurchasablesLoading ? (
              <LegacyStack distribution="center" alignment="center">
                <Spinner />
              </LegacyStack>
            ) : (
              <React.Fragment>
                {!linkedProducts.length && !linkedVariantsToPurchasable.length ? (
                  <NoProductsContainer>
                    <LegacyStack vertical spacing="baseTight" alignment="center" distribution="center">
                      <AddProductMajorSVG />
                      <Text variant="headingLg" as="p">
                        There are no products in this subscription program
                      </Text>
                      <Text variant="bodyMd" as="span" color="subdued">
                        Browse to add products and variants
                      </Text>
                    </LegacyStack>
                  </NoProductsContainer>
                ) : (
                  <React.Fragment>
                    <ResourceList
                      items={linkedProducts}
                      renderItem={purchasable => (
                        <SellingPlanGroupProductsListItem
                          purchasable={purchasable}
                          variantIds={variantIds}
                          variantToPurchasableMap={variantToPurchasableMap}
                          handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                        />
                      )}
                    />
                    <ResourceList
                      items={linkedVariantsToPurchasable}
                      renderItem={(variant, _, index) => (
                        <SellingPlanGroupVariantToPurchaseableListItem
                          index={index}
                          variant={variant}
                          variantToPurchasableMap={variantToPurchasableMap}
                          linkedProducts={linkedProducts}
                          handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                        />
                      )}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Scrollable>
        </div>
      </LegacyCard>
      <BrowseProductsModal
        purchasableOptions={purchasableOptions.slice(
          pageNumber * MAX_PAGE_SIZE,
          pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
        )}
        selectedProductsAndVariants={modalSelectedProductOrVariantIds}
        searchText={filteredPurchasablesSearchText}
        open={showProductOptionsModal}
        onClose={closeProductsModal}
        title={
          <LegacyStack vertical spacing="extraTight">
            <Text variant="headingLg" as="p">
              Browse products
            </Text>
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              Select one or multiple products/variants to be included as part of this subscription program.
            </p>
          </LegacyStack>
        }
        primaryAction={{
          content: "Confirm",
          onAction: onEditProductsOrVariantsCb,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeProductsModal,
          },
        ]}
        footer={
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > pageNumber + 1}
              hasPrevious={pageNumber != 0}
              label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
              onNext={() => setPageNumber(prev => prev + 1)}
              onPrevious={() => setPageNumber(prev => prev - 1)}
            />
          </PaginationContainer>
        }
        searchSuffix={
          !filteredPurchasablesSearchText && (
            <Button onClick={selectAllProducts} plain>
              {modalSelectedProductOrVariantIds.length === allProductsAndVariantIds.length
                ? "deselect all"
                : "or select all"}
            </Button>
          )
        }
        onSearchChange={text => {
          setFilteredPurchasablesSearchText(text);
          //reset page on filter change to avoid blank card
          setPageNumber(0);
        }}
        setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
      />
      <Modal
        title="Warning"
        open={showRemoveProductWarningModal}
        onClose={closeRemoveProductWarningModal}
        primaryAction={{
          content: "Go Back",
          onAction: closeRemoveProductWarningModal,
        }}
      >
        <Modal.Section>
          <Banner title="" status="critical">
            <Text variant="bodyMd" as="p">
              You currently have an advanced sequential program linked to this product. This product can&apos;t be
              removed from the subscription program until it has been removed from the advanced sequential
              settings.
            </Text>
          </Banner>
        </Modal.Section>
      </Modal>
    </CardWrapper>
  );
}
