import {
  Button,
  Icon,
  LegacyCard,
  LegacyStack,
  Scrollable,
  Spinner,
  Text,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { AlertMinor, ExternalMinor } from "@shopify/polaris-icons";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import {
  CombinedSequential,
  MisconfiguredSequentialApi,
} from "@smartrr/shared/interfaces/sequential/misconfigured";
import { SequentialsAccess } from "./SequentialsStore";
import { getDisplayVariantHelper } from "../../DisplayVariant/helper";
import { useSequentialFlowModalStore } from "../../FlowModal/useSequentialFlowModalStore";
import { SequentialGroupContext } from "../../SequentialGroupContext";

const StyledCard = styled.div`
  margin-top: 8px;

  & > .Polaris-LegacyCard {
    margin: 4px;

    & > .Polaris-LegacyStack {
      padding: 1rem;
    }
  }

  & .Polaris-Thumbnail--sizeSmall {
    height: 32px;
    width: 32px;
  }

  & img {
    border-radius: 6px;
    height: 30px;
    width: 30px;
  }
`;

const FlowNumberTextContainer = styled.div`
  > span {
    display: flex;

    .Polaris-Icon {
      margin: 0;
    }
  }

  & span.Polaris-Text--root {
    font-size: 12px;
    font-weight: 500;
  }
`;

const ProductNameTextContainer = styled.div`
  max-width: 200px;

  & p.Polaris-Text--root {
    font-weight: 500;
  }
`;

const StyledStack = styled.div`
  & > .Polaris-LegacyStack > .Polaris-LegacyStack__Item:not(:first-of-type) {
    margin-top: 20px;
    padding-top: 4px;
  }
`;

const getTextForMisconfigurationTooltip = (
  warningOrError: MisconfiguredSequentialApi.Warning.Type | MisconfiguredSequentialApi.Error.Type | null
): string | null => {
  if (!warningOrError) {
    return null;
  }

  const errorText: Record<MisconfiguredSequentialApi.Error.Type, string> = {
    INITIAL_VARIANT_DELETED:
      "Subscriptions will be paused because an item in this flow was deleted. Please edit this flow for subscriptions to resume.",
    SWAP_VARIANT_DELETED:
      "Subscriptions will be paused because an item in this flow was deleted. Please edit this flow for subscriptions to resume.",
  };

  const warningText: Record<MisconfiguredSequentialApi.Warning.Type, string> = {
    INITIAL_VARIANT_DRAFTORARCHIVED:
      "Subscriptions will be paused because an item in this flow was archived or set as a draft. Please edit this flow for subscriptions to resume.",
    INITIAL_VARIANT_OOS:
      "Subscriptions will be paused because an item in this flow is out of stock. The subscription will resume once the item is back in stock or the customer swaps in another item.",
    SWAP_VARIANT_DRAFTORARCHIVED:
      "Subscriptions will be paused because an item in this flow was archived or set as a draft. Please edit this flow for subscriptions to resume.",
    SWAP_VARIANT_OOS:
      "Subscriptions will be paused because an item in this flow is out of stock. The subscription will resume once the item is back in stock or the customer swaps in another item.",
  };

  const unknownErrorText = "Subscriptions may be paused due to an unknown error. Please edit this flow.";

  return errorText[warningOrError] ?? warningText[warningOrError] ?? unknownErrorText;
};

interface ShowSequentialProps {
  sequential: CombinedSequential;
  id: string;
  sequentialIndex: number;
}

const ShowSequential = ({ sequential, id, sequentialIndex }: ShowSequentialProps) => {
  const openModal = useSequentialFlowModalStore(state => state.openModal);
  const groupContext = useContext(SequentialGroupContext);

  const helper = sequential.initialVariant ? getDisplayVariantHelper(sequential.initialVariant) : null;

  const warning: MisconfiguredSequentialApi.Warning.Type | null =
    sequential.type === "MISCONFIGURED" && sequential.warnings.length > 0 ? sequential.warnings[0] : null;
  const error: MisconfiguredSequentialApi.Error.Type | null =
    sequential.type === "MISCONFIGURED" && sequential.errors.length > 0 ? sequential.errors[0] : null;

  const tooltipText = getTextForMisconfigurationTooltip(error ?? warning);

  return (
    <StyledCard key={id}>
      <LegacyCard key={id}>
        <LegacyStack vertical>
          <FlowNumberTextContainer>
            {sequential.type === "MISCONFIGURED" && tooltipText ? (
              <Tooltip content={<span>{tooltipText}</span>}>
                <Text variant="bodyMd" as="span" color="subdued">
                  {`Flow #${sequentialIndex + 1}`}
                </Text>
                <Icon source={AlertMinor} color={error ? "critical" : "warning"} />
              </Tooltip>
            ) : (
              <Text variant="bodyMd" as="span" color="subdued">
                {`Flow #${sequentialIndex + 1}`}
              </Text>
            )}
          </FlowNumberTextContainer>
          <LegacyStack distribution="equalSpacing">
            <LegacyStack alignment="center">
              {helper ? (
                <helper.Thumbnail />
              ) : (
                <Thumbnail size="small" source={""} alt={`Misconfigured sequential`} />
              )}
              <LegacyStack vertical spacing="none">
                <ProductNameTextContainer>
                  <Text as="p" variant="bodyMd" truncate>
                    {helper?.productName ?? "Misconfigured sequential"}
                  </Text>
                </ProductNameTextContainer>
                <Text as="p" variant="bodyMd" color="subdued">
                  {helper?.variantName ?? "Variant deleted"}
                </Text>
              </LegacyStack>
            </LegacyStack>
            <Button
              size="slim"
              icon={sequential.type === "MISCONFIGURED" ? AlertMinor : ExternalMinor}
              destructive={sequential.type === "MISCONFIGURED" && !!error}
              onClick={() =>
                openModal({
                  groupId: groupContext.numericShopifyId,
                  selectedSequential: sequential,
                })
              }
            >
              Manage flow
            </Button>
          </LegacyStack>
        </LegacyStack>
      </LegacyCard>
    </StyledCard>
  );
};

const ShowSequentialList = ({ sequentials }: { sequentials: CombinedSequential[] }) => {
  return (
    <StyledStack>
      <LegacyStack vertical spacing="extraLoose" distribution="fill">
        {sequentials.map((sequential, index) => (
          <ShowSequential key={index} sequential={sequential} id={sequential.id} sequentialIndex={index} />
        ))}
      </LegacyStack>
    </StyledStack>
  );
};

export const ShowSequentials = () => {
  const loading = SequentialsAccess.useLoading();
  const actions = SequentialsAccess.useActions();
  const sequentials = SequentialsAccess.useSequentials();
  const groupContext = useContext(SequentialGroupContext);

  const onScrollToBottom = () => {
    actions.getMore();
  };

  useEffect(() => {
    actions.get(ensureShopifyGid("SellingPlanGroup", groupContext.numericShopifyId));
  }, [groupContext]);

  if (loading && sequentials.length === 0) {
    return (
      <LegacyStack distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <Scrollable
      horizontal={false}
      shadow
      style={{
        maxHeight: "450px",
        paddingTop: "10px",
      }}
      onScrolledToBottom={onScrollToBottom}
    >
      <ShowSequentialList sequentials={sequentials} />
      {loading ? (
        <LegacyStack distribution="center">
          <Spinner />
        </LegacyStack>
      ) : undefined}
    </Scrollable>
  );
};
