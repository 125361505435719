import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import React, { useEffect } from "react";

import { SequentialFlowActions } from "./FlowActions";
import { SequentialFlowEnd } from "./FlowEnd";
import { SequentialFlowStart } from "./FlowStart";
import { SequentialFlowSubmit } from "./FlowSubmit";
import { useSequentialFlowModalStore } from "./useSequentialFlowModalStore";
import { useSequentialBrowseModalStore } from "../BrowseModal/useSequentialBrowseModalStore";
import { EditSequentialAccess } from "../SequentialStore";
import { SequentialSection } from "../utils/SequentialSection";
import { useSequentialPreviewModal } from "../PreviewModal/useSequentialPreviewModal";
import { SequentialLinearGradient } from "./SequentialLinearGradient";
import { SequentialFlowContainer } from "./SequentialFlowContainer";
import { CombinedSequential } from "@smartrr/shared/interfaces/sequential/misconfigured";

const SequentialFlow = () => {
  return (
    <React.Fragment>
      <SequentialSection>
        <SequentialFlowContainer>
          <SequentialFlowStart />
          <SequentialLinearGradient>
            <SequentialFlowActions />
          </SequentialLinearGradient>
          <SequentialFlowEnd />
        </SequentialFlowContainer>
      </SequentialSection>
      <SequentialSection border>
        <SequentialFlowSubmit />
      </SequentialSection>
    </React.Fragment>
  );
};

export interface ISequentialFlowModalProps {
  groupId: number;
  selectedSequential?: CombinedSequential;
}

export const SequentialFlowModal = () => {
  const isFlowModalOpen = useSequentialFlowModalStore(state => state.isModalOpen);
  const modalInfo = useSequentialFlowModalStore(state => state.modalPayload);
  const isBrowseModalOpen = useSequentialBrowseModalStore(state => state.isModalOpen);
  const isPreviewModalOpen = useSequentialPreviewModal(state => state.isModalOpen);

  const actions = EditSequentialAccess.useActions();

  useEffect(() => {
    if (modalInfo && isFlowModalOpen) {
      if (modalInfo.selectedSequential) {
        actions.editExistingSequential(modalInfo.selectedSequential);
      } else {
        actions.createNewSequential(ensureShopifyGid("SellingPlanGroup", modalInfo.groupId));
      }
    }
  }, [isFlowModalOpen]);

  const hideFlowModal = !isFlowModalOpen || isBrowseModalOpen || isPreviewModalOpen;

  return (
    <div
      style={{
        display: hideFlowModal ? "none" : "block",
      }}
    >
      <SequentialFlow />
    </div>
  );
};
