import { Button, Card, HorizontalStack, Text } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

const TextWrapper = styled.div`
  .Polaris-Text--headingMd {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

interface IUpgradeCard {
  upgradeToGrow?: boolean;
}

export function UpgradeCard({ upgradeToGrow }: IUpgradeCard) {
  return (
    <Card>
      <HorizontalStack align="space-between" blockAlign="end">
        <TextWrapper>
          <Text variant="headingMd" as="h2">
            {upgradeToGrow ? "Sequential Products" : "Advanced sequential Products"}
          </Text>
          <Text variant="bodyMd" as="span" color="subdued">
            {upgradeToGrow
              ? "Upgrade to 📈 Grow to access sequential settings."
              : "Upgrade to 💎 Excel to access advanced sequential settings."}
          </Text>
        </TextWrapper>
        <Button primary url="/admin/smartrr-account">
          Upgrade
        </Button>
      </HorizontalStack>
    </Card>
  );
}
