import { SettingToggle } from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { ToggleOffIcon, ToggleOnIcon } from "@vendor-app/app/_sharedComponents/Icons";

const ToggleWrapper = styled.div`
  .Polaris-LegacyCard {
    box-shadow: none;
    .Polaris-LegacyCard__Section {
      padding: 0;
      .Polaris-SettingAction {
        .Polaris-SettingAction__Setting {
          margin: 0;
        }
        ,
        .Polaris-SettingAction__Action {
          margin: 0;
        }
        ,
        .Polaris-Button {
          background: white;
          border: none;
          box-shadow: none;
          color: #8c9196;
        }
        ,
        .Polaris-Button__Content {
          align-items: flex-start;
          display: flex;
          justify-content: space-around;
          .Polaris-Button__Icon {
            padding-right: 10px;
          }
        }
      }
    }
  }
`;

export const SettingToggleSwitch: React.FC<{
  handleClick: (toggled: boolean) => void;
  toggled: boolean;
  disabled: boolean;
}> = ({ handleClick, toggled, disabled }) => {
  const [active, setActive] = useState(toggled);
  const handleToggle = useCallback(() => {
    setActive(active => !active);
    handleClick(!active);
  }, [active]);
  const contentStatus = active ? "On" : "Off";

  return (
    <ToggleWrapper>
      <SettingToggle
        action={{
          content: contentStatus,
          onAction: disabled ? NO_OP_CALLBACK : handleToggle,
          icon: active ? ToggleOnIcon : ToggleOffIcon,
        }}
        enabled={active}
      />
    </ToggleWrapper>
  );
};
