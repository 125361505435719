import { Button, LegacyStack, Text } from "@shopify/polaris";
import React from "react";

import { useSubscriptionToEvent } from "@vendor-app/utils/customEvent";

import { useSequentialBrowseModalStore } from "../../BrowseModal/useSequentialBrowseModalStore";
import { DisplayVariant } from "../../DisplayVariant";
import { EditSequentialAccess } from "../../SequentialStore";
import { UpdateSequence } from "../UpdateSequence";

export const SequentialFlowActions = () => {
  const openBrowseModal = useSequentialBrowseModalStore(state => state.openModal);
  const selectedVariant = EditSequentialAccess.useVariant();
  const groupId = EditSequentialAccess.useGroup();
  const sequence = EditSequentialAccess.useSequence();
  const actions = EditSequentialAccess.useActions();
  const sequentialId = EditSequentialAccess.useSequentialId();

  useSubscriptionToEvent(
    "smartrr.selling_plan_group.sequential.select_variant",
    ({ vnt, id: sequentialIndex }) => {
      if (sequentialIndex === "initial") {
        actions.setInitialVariant(vnt);
        if (sequence.length === 0) {
          actions.addNewSequentialAction();
        }
      }
    },
    [sequence]
  );

  return (
    <LegacyStack vertical spacing="tight">
      <LegacyStack vertical spacing="tight">
        <LegacyStack alignment="center" distribution="equalSpacing">
          <Text as="p" variant="bodyMd">
            <Text as="span" variant="bodyMd" fontWeight="bold">
              When&nbsp;
            </Text>
            <span>the following item(s) are purchased: </span>
          </Text>
          <Button
            size="slim"
            onClick={() => openBrowseModal({ groupId, selectedVariant, sequenceId: "initial", sequentialId })}
          >
            Browse
          </Button>
        </LegacyStack>
        <DisplayVariant selectedVariant={selectedVariant} />
      </LegacyStack>
      {sequence.map((sequence, index) => (
        <UpdateSequence key={index} sequence={sequence} />
      ))}
    </LegacyStack>
  );
};
