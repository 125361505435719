import {
  Checkbox,
  HorizontalStack,
  ResourceItem,
  ResourceList,
  Text,
  Thumbnail,
  VerticalStack,
} from "@shopify/polaris";
import { ELEMENT_NOT_FOUND } from "@smartrr/shared/constants";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React from "react";
import styled from "styled-components";

import { Filler } from "@vendor-app/app/_sharedComponents/Filler";

import { getDisplayVariantHelper } from "../../DisplayVariant/helper";
import { ISequentialBrowseModalProps } from "../index";
import { SequentialApi } from "@smartrr/shared/interfaces/sequential/api";
import { StatusBadge } from "@vendor-app/app/_sharedComponents/StatusBadge";

const StyledVariantContainer = styled.div`
  & .Polaris-ResourceItem .Polaris-Box {
    padding-right: 0px;
  }
`;

interface IProductDisplayProps {
  product: SequentialApi.ProductWithSequentialId.Type;
  info: ISequentialBrowseModalProps;
  variantsChosen: SequentialApi.SequentialVariantWithParent.Type[];
  onVariantChosen: (chosen: SequentialApi.SequentialVariantWithParent.Type) => void;
  onVariantUnchosen: (chosen: SequentialApi.SequentialVariantWithParent.Type) => void;
}

type SequentialVariantWithParent = SequentialApi.ProductWithSequentialId.VariantType & {
  parentProduct: SequentialApi.ProductWithSequentialId.Type;
};

export const ProductDisplay = ({
  product,
  info,
  variantsChosen,
  onVariantChosen,
  onVariantUnchosen,
}: IProductDisplayProps) => {
  const isProductSelected = (): boolean => {
    return product.variants.some(variant => {
      return variantsChosen.findIndex(v => v.id === variant.id) !== ELEMENT_NOT_FOUND;
    });
  };

  const isDisallowed = (variant: SequentialApi.ProductWithSequentialId.VariantType): boolean => {
    return (
      info.sequenceId === "initial" && variant.sequentialId !== null && variant.sequentialId !== info.sequentialId
    );
  };

  const isProductDisallowed = (product: SequentialApi.ProductWithSequentialId.Type): boolean => {
    return !product.variants.some(v => !isDisallowed(v));
  };

  const isChosen = (variant: SequentialVariantWithParent): boolean => {
    return variantsChosen.findIndex(v => v.id === variant.id) !== ELEMENT_NOT_FOUND;
  };

  const maybeChoose = (variant: SequentialVariantWithParent): boolean => {
    if (isDisallowed(variant)) {
      return false;
    }
    if (isChosen(variant)) {
      onVariantUnchosen(variant);
    } else {
      onVariantChosen(variant);
    }
    return true;
  };

  const chooseProduct = () => {
    for (const variant of product.variants) {
      if (
        maybeChoose({
          ...variant,
          parentProduct: product,
        })
      ) {
        return;
      }
    }
  };

  return (
    <VerticalStack gap="200">
      <div onClick={() => chooseProduct()}>
        <HorizontalStack blockAlign="center" gap="200">
          <Checkbox
            labelHidden
            label={"Choose Product"}
            disabled={isProductDisallowed(product)}
            checked={isProductSelected()}
          />
          <Thumbnail size="small" source={product.images[0] ?? ""} alt={`Image for ${product.name}`} />
          <div
            style={{
              maxWidth: "200px",
            }}
          >
            <Text
              as="span"
              variant="bodyMd"
              truncate
              color={isProductDisallowed(product) ? "subdued" : undefined}
            >
              {product.name}
            </Text>
          </div>
          <Filler />
          <StatusBadge obj={product} />
        </HorizontalStack>
      </div>
      <StyledVariantContainer>
        <ResourceList
          resourceName={{
            singular: "Variant",
            plural: "Variants",
          }}
          items={product.variants ?? []}
          renderItem={(onlyVariant, id, index) => {
            const variant: SequentialVariantWithParent = {
              ...onlyVariant,
              parentProduct: product,
            };
            const helper = getDisplayVariantHelper(variant);
            return (
              <ResourceItem id={id} key={index} onClick={() => maybeChoose(variant)}>
                {product.shopifyId && variant.shopifyId ? (
                  <HorizontalStack blockAlign="center" gap="200">
                    <Checkbox
                      id={`smartrr-sequential-product-display-${shopifyGidToNumber(
                        product.shopifyId
                      )}-${shopifyGidToNumber(variant.shopifyId)}`}
                      labelHidden
                      label={"Choose variant"}
                      checked={isChosen(variant)}
                      disabled={isDisallowed(variant)}
                    />
                    <helper.Thumbnail />
                    <Text as="p" variant="bodyMd" color={isDisallowed(variant) ? "subdued" : undefined}>
                      {helper.variantName}
                    </Text>
                    <Filler>
                      <HorizontalStack blockAlign="center" align="end">
                        <StatusBadge obj={variant} />
                      </HorizontalStack>
                    </Filler>
                  </HorizontalStack>
                ) : null}
              </ResourceItem>
            );
          }}
        />
      </StyledVariantContainer>
    </VerticalStack>
  );
};
