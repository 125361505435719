import { LegacyCard, LegacyStack, Text, Tooltip } from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import React from "react";
import styled from "styled-components";

import { SettingToggleSwitch } from "@vendor-app/app/_sharedComponents/SettingToggleSwitch/SettingToggleSwitch";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

const PrepaidStackWrapper = styled.div`
  .Polaris-LegacyStack {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    .Polaris-LegacyStack__Item:first-child {
      width: 81%;
    }
    .Polaris-LegacyStack__Item:last-child {
      margin-top: 10px;
      .Polaris-Button {
        margin-top: 5px;
      }
      .Polaris-Button:focus {
        color: white;
        text-decoration: none;
      }
    }
    .Polaris-LegacyStack__Item {
      .Polaris-Text--headingMd {
        color: "#8C9196";
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
`;

export const PrepaidInformation = () => {
  const { prepaidSequential } = useSmartrrFlags();

  return (
    <LegacyCard
      sectioned
      title={
        <PrepaidStackWrapper>
          <LegacyStack alignment="center" distribution="equalSpacing">
            <div>
              <Text variant="headingMd" as="h2">
                Sequential Products
              </Text>
              <Text variant="bodyMd" as="p" color="subdued">
                Determine how your products are converted throughout a subscription lifecycle by enabling a
                sequence of products after a certain number of deliveries.
              </Text>
            </div>
            <div data-testid="smartrr-sequential-prepaid-warning">
              <Tooltip
                content={
                  <React.Fragment>
                    {prepaidSequential ? (
                      <Text variant="bodyMd" as="p" color="subdued">
                        Sequential products can only be used with basic plans or a single prepaid plan.
                      </Text>
                    ) : (
                      <Text variant="bodyMd" as="p" color="subdued">
                        Sequential products can only be used with basic plans.
                      </Text>
                    )}
                  </React.Fragment>
                }
              >
                <SettingToggleSwitch toggled={false} handleClick={NO_OP_CALLBACK} disabled={true} />
              </Tooltip>
            </div>
          </LegacyStack>
        </PrepaidStackWrapper>
      }
    />
  );
};
